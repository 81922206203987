import { IconButton, Typography } from '@mui/material'
import React from 'react'
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";


const SocialMedia = () => {
  return (
    <>
     <Typography
            variant="h6"
            component="div"
            className="topbar"
            // sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}
          >
            <IconButton
              aria-label="Twitter"
              color="whitebg"
              component="a"
              href="https://www.facebook.com/BrandLiaisonIndia"
              target="_blank"
            >
              <FacebookIcon className="social_icons" />
            </IconButton>
            <IconButton
              aria-label="Twitter"
              color="whitebg"
              component="a"
              href="https://twitter.com/BrandLiaison"
              target="_blank"
            >
              <TwitterIcon className="social_icons" />
            </IconButton>
            <IconButton
              aria-label="Twitter"
              color="whitebg"
              component="a"
              href="https://in.linkedin.com/company/brand-liaison-india-pvt-ltd1"
              target="_blank"
            >
              <LinkedInIcon className="social_icons" />
            </IconButton>
            <IconButton
              aria-label="Twitter"
              color="whitebg"
              component="a"
              href="https://www.youtube.com/channel/UCJBaZLfC-tnQiQvqJEk-y7w/videos"
              target="_blank"
            >
              <YouTubeIcon className="social_icons" />
            </IconButton>
            <IconButton
              aria-label="Twitter"
              color="whitebg"
              component="a"
              href="https://twitter.com/BrandLiaison"
              target="_blank"
            >
              <ShareOutlinedIcon className="social_icons" />
            </IconButton>
          </Typography>
    </>
  )
}

export default SocialMedia

// import React from 'react';
// import { IconButton, Typography } from '@mui/material';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import YouTubeIcon from '@mui/icons-material/YouTube';
// import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
// import useFetchData from '../../api/UserFetchData';

// const iconComponents = {
//   facebook: FacebookIcon,
//   twitter: TwitterIcon,
//   linkedin: LinkedInIcon,
//   youtube: YouTubeIcon,
//   share: ShareOutlinedIcon,
// };

// const SocialMedia = () => {
//   const { data, loading, error } = useFetchData('/api/frontend/v1/footer/social-media');

//   if (loading) {
//     return <Typography>Loading...</Typography>;
//   }

//   if (error) {
//     return <Typography>Error: {error.message}</Typography>;
//   }

//   console.log(data); // Debugging: Check the structure of data

//   return (
//     <Typography
//       variant="h6"
//       component="div"
//       className="topbar"
//     >
//       {data.map((item) => {
//         const IconComponent = iconComponents[item.type];

//         if (!IconComponent) {
//           console.log(`No icon component found for type: ${item.type}`); // Debugging: Log if icon component is not found
//           return null;
//         }

//         return (
//           <IconButton
//             key={item.type}
//             aria-label={item.type}
//             color="whitebg"
//             component="a"
//             href={item.url}
//             target="_blank"
//           >
//             <IconComponent className="social_icons" />
//           </IconButton>
//         );
//       })}
//     </Typography>
//   );
// };

// export default SocialMedia;
