import { Download, Visibility } from "@mui/icons-material";
import {
  Box,
  Grid,
  List,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import CallBackRequestService from "./ServicePages/CallBackRequestService";
import BrochureService from "./ServicePages/BrochureService";
// import ServiceDetailsVerticalTab from "./ServicePages/ServiceDetailsVerticalTab";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

import "./MandatoryProductList.scss";
import "./ProductDownload.scss"
// import ServiceDetailsMobileSelect from "./ServicePages/ServiceDetailsMobileSelect";
import ProductCategory from "./ProductCategory";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ProductDownload = () => {
  const [value, setValue] = useState(0);
  const [selectedItem, setSelectedItem] = useState("option1");

  const handleChanges = (event) => {
    setSelectedItem(event.target.value);
  };

  const handletabchange = (newvalue) => {
    setValue(newvalue);
  };

  function createData(SerialNo, ProductName, RequiredInformation) {
    return { SerialNo, ProductName, RequiredInformation };
  }
  const rows = [
    createData(1, "Lorem Ipsrem"),
    createData(2, "Lorem Ipsrem"),
    createData(3, "Lorem Ipsrem"),
    createData(4, "Lorem Ipsrem"),
    createData(5, "Lorem Ipsrem"),
    createData(6, "Lorem Ipsrem"),
    createData(7, "Lorem Ipsrem"),
    createData(8, "Lorem Ipsrem"),
    createData(9, "Lorem Ipsrem"),
    createData(10, "Lorem Ipsrem"),
  ];

  const handleViewClick = (fileName) => {
    // Logic to open the file
    window.open(`/path/to/files/${fileName}`, '_blank');
  };

  const handleDownloadClick = (fileName) => {
    // Logic to download the file
    const link = document.createElement('a');
    link.href = `/path/to/files/${fileName}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const TabPanel = (props) => {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div 
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`vertical-tabpanel-${index}`}
  //       aria-labelledby={`vertical-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && <Box sx={{ paddingBlock: 3 }}>{children}</Box>}
  //     </div>
  //   );
  // };
  const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <section>
      <Box className="AboutUsPage ProductDownload">
        <Typography
          variant="h4"
          className="AboutUsPage__title"
          textAlign={"center"}
          position={"relative"}
          marginBlock={{sm:3,md:5}}
        >
          Product Download{" "}
        </Typography>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            textAlign={"left"}
            className="ServicesPage__category"
          >
            {isMobile ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                                    }}
                >
                 
                </Box>
                 {/* <ServiceDetailsMobileSelect
                    handleChanges={handleChanges}
                    onChange={setSelectedItem}
                    value={value}
                  /> */}
                  <ProductCategory/>
              </>
            ) : (
              <>
                {/* <ServiceDetailsVerticalTab
                  handletabchange={handletabchange}
                  value={value}
                  
                /> */}
                <ProductCategory/>
                <BrochureService />
                <CallBackRequestService />

              </>
            )}
          </Grid>
          <Grid item xs={12} sm={8} md={8} className="ProductDownload__table">
            <Stack>
              {" "}
              <TextField
                variant="outlined"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <SearchIcon sx={{ color: "action.active", mr: 1 }} />
                  ),
                }}
              />
            </Stack>
            {isMobile ? (
              <Box marginTop={2}>
                {selectedItem === "option1" && (
                  <Box>
                    <Typography variant="h5">Introduction</Typography>
                    <Typography>
                      Bureau of Indian Standards (BIS)serves as India's national
                      standards body. It operates under the authority of the
                      Indian government and is responsible for developing and
                      promoting technical standards across various industries.
                      In 2012, the Compulsory Registration Scheme (CRS) was
                      introduced as a significant component under BIS. CRS
                      mandates the certification of specific product categories,
                      ensuring their adherence to predefined safety and quality
                      standards. This scheme is a pivotal initiative aimed at
                      safeguarding consumers' interests by regulating products
                      that have the potential to impact safety and well-being.
                    </Typography>
                  </Box>
                )}
                {selectedItem === "option2" && (
                  <Box>
                    <Typography variant="h5">
                      Content for Favorites Tab
                    </Typography>
                    <Typography>
                      Sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </Typography>
                  </Box>
                )}
                {selectedItem === "option3" && (
                  <Box>
                    <Typography variant="h5">Mandatory Product List</Typography>
                    <Typography>
                      Sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Box sx={{marginTop:4}}>
               <TableContainer component={Paper} >
                <Table sx={{ minWidth: 300 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Serial No</StyledTableCell>
                      <StyledTableCell align="right">
                        Product Name
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        Required Information
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <StyledTableRow key={row.name}>
                        <StyledTableCell component="th" scope="row">
                          {row.SerialNo}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.ProductName}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {/* {row.RequiredInformation} */}
                          <div className="ProductDownload__table__icon">
                            <Visibility
                              sx={{ marginRight: "20px" }}
                              color="primary"
                              className="cursor-pointer"
                              onClick={() => handleViewClick(row.RequiredInformation)}

                            />
                            <Download
                              color="primary"
                              className="cursor-pointer"
                              onClick={() => handleDownloadClick(row.RequiredInformation)}

                            />
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </Box>
              
            )}

            <Box></Box>
            <Stack spacing={2} className="pagination">
             
              <Pagination className="pagination" count={10} color="primary" />
            </Stack>
            {isMobile ? (
              <>
                <BrochureService />
                <CallBackRequestService />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default ProductDownload;
