import {
  Box,
  // CircularProgress,
  Grid,
  Pagination,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import BrochureService from "./ServicePages/BrochureService";
import CallBackRequestService from "./ServicePages/CallBackRequestService";
import ServiceCategory from "./ServicePages/ServiceCategory";
import RecentBlog from "./RecentBlog";
import RecentTag from "./RecentTag";
// import image from "../Assets/Images/blog-image.png";
import "./Blog.scss";
import ServicesCards from "./ServicePages/ServicesCards";


const Blog = ({ image, title, description }) => {
  const isMobile = useMediaQuery("(max-width:767px)");
 
  return (
    <section>
      <Box className="AboutUsPage Blog">
        <Typography
          variant="h4"
          className="AboutUsPage__title Blog__title"
          textAlign={"center"}
          position={"relative"}
      marginBlock={5}
        >
          Our Blog
        </Typography>
        <Grid container >
          <Grid item xs={12} sm={4} md={4} textAlign={"left"} className="">
            {/* <TabsComponent activeTab={activeTab} handleTabChange={handleTabChange} handleTabClick={handleTabClick} /> */}

            {isMobile ? (
              <>
                <Grid container >
                  <ServiceCategory />
                </Grid>
              </>
            ) : (
              <>
                <ServiceCategory />
                <RecentBlog />
                <RecentTag />
                  <BrochureService />
                  <CallBackRequestService />
              </>
            )}
          </Grid>
          <Grid item xs={12} sm={8} md={8} className="ServicesPage__cards">
            <ServicesCards />
            <Pagination
              count={10}
              color="primary"
              sx={{
                marginBlock: 5,
                display: "flex",
                justifyContent: "flex-end",
              }}
            />

            {isMobile ? (
              <>
                <BrochureService />
                <CallBackRequestService />
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default Blog;
