// import React from 'react';
// import { Tabs, Tab, Box,  useMediaQuery} from "@mui/material";
// import "./ServiceDetailsVerticalTab.scss";
// import { TrendingFlat } from "@mui/icons-material";
// import ServiceDetailsMobileSelect from './ServiceDetailsMobileSelect';

// const VerticalTabs = ({handletabchange,value}) => {
//   const handleChange = (event, newValue) => {
//     handletabchange(newValue);
//   };
 


//   const isMobile = useMediaQuery("(max-width:767px)");

//   return (
//     <Box  className="serviceDetailstab">
     
//       {isMobile ? (
      
//     <ServiceDetailsMobileSelect/>
//         ) : (
//       <Tabs
//       orientation="vertical"
//       variant="scrollable"
//       value={value}
//       onChange={handleChange}
//     >
//       <Tab
//         label={
//           <>
//             Introduction <TrendingFlat />{" "}
//           </>
//         }
//         className="tab-label"
//       />
//       <Tab
//         label={
//           <>
//             Mandatory Product list <TrendingFlat />{" "}
//           </>
//         }
//         className="tab-label"
//       />
//       <Tab
//         label={
//           <>
//             Required Documents <TrendingFlat />{" "}
//           </>
//         }
//         className="tab-label"
//       />
//       <Tab
//         label={
//           <>
//            Registration Process <TrendingFlat />{" "}
//           </>
//         }
//         className="tab-label"
//       />
//       <Tab
//         label={
//           <>
//           Validity & Renewal <TrendingFlat />{" "}
//           </>
//         }
//         className="tab-label"
//       />
//       <Tab
//         label={
//           <>
//           Basic Guidelines <TrendingFlat />{" "}
//           </>
//         }
//         className="tab-label"
//       />
//        <Tab
//         label={
//           <>
//          Exempted Product List <TrendingFlat />{" "}
//           </>
//         }
//         className="tab-label"
//       />
     
//     </Tabs>

//     )}
       
       
//       </Box>
   
//   );
// };


// export default VerticalTabs;



// API LINK DATA


// import React from 'react';
// import { Tabs, Tab, Box, useMediaQuery } from "@mui/material";
// import "./ServiceDetailsVerticalTab.scss";
// import { TrendingFlat } from "@mui/icons-material";
// import ServiceDetailsMobileSelect from './ServiceDetailsMobileSelect';
// import useFetchData from '../../api/UserFetchData';


// const VerticalTabs = ({ handletabchange, value }) => {
//   const { data, loading, error } = useFetchData('/api/frontend/v1/service/1/get/service-section');

//   const handleChange = (event, newValue) => {
//     handletabchange(newValue);
//   };

//   const isMobile = useMediaQuery("(max-width:767px)");

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error.message}</div>;

//   return (
//     <Box className="serviceDetailstab">
//       {isMobile ? (
//         <ServiceDetailsMobileSelect />
//       ) : (
//         <Tabs
//           orientation="vertical"
//           variant="scrollable"
//           value={value}
//           onChange={handleChange}
//         >
//           {data?.map((tab, index) => (
//             <Tab
//               key={index}
//               label={
//                 <>                        

//                   {tab.service_section_name} <TrendingFlat />{" "}
//                 </>
//               }
//               className="tab-label"
//             />
//           ))}
//         </Tabs>
//       )}
//     </Box>
//   );
// };

// export default VerticalTabs;


//API LINK DATA


import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, useMediaQuery } from "@mui/material";
import "./ServiceDetailsVerticalTab.scss";
import { TrendingFlat } from "@mui/icons-material";
import ServiceDetailsMobileSelect from './ServiceDetailsMobileSelect';
import axios from 'axios';
import useFetchData from '../../api/UserFetchData';

const tabAPIs = [
  '/api/frontend/v1/service/1/service-section/introduction',
  '/api/frontend/v1/service/1/service-section/required-documents',
  '/api/frontend/v1/service/1/service-section/standard-costing-and-timeline',
  '/api/frontend/v1/service/1/service-section/registration-process',
  '/api/frontend/v1/service/1/service-section/validity-renewal',
  '/api/frontend/v1/service/2/service-section/mandatory-product-list'
];

const VerticalTabs = ({ handletabchange, value }) => {
  const { data: tabData, loading: tabLoading, error: tabError } = useFetchData('/api/frontend/v1/service/1/get/service-section');
  const [tabContent, setTabContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const isMobile = useMediaQuery("(max-width:767px)");

  useEffect(() => {
    const fetchTabContent = async () => {
      if (tabAPIs[value]) {
        setLoading(true);
        try {
          const response = await axios.get(tabAPIs[value]);
          setTabContent(response.data);
          setLoading(false);
        } catch (err) {
          setError(err);
          setLoading(false);
        }
      }
    };

    fetchTabContent();
  }, [value]);

  const handleChange = async (event, newValue) => {
    handletabchange(newValue);
    setLoading(true);
    try {
      const response = await axios.get(tabAPIs[newValue]);
      setTabContent(response.data);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  if (tabLoading) return <div>Loading...</div>;
  if (tabError) return <div>Error: {tabError.message}</div>;

  return (
    <Box className="serviceDetailstab">
      {isMobile ? (
        <ServiceDetailsMobileSelect />
      ) : (
        <>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
          >
            {tabData?.map((tab, index) => (
              <Tab
                key={index}
                label={
                  <>                        
                    {tab.service_section_name} <TrendingFlat />{" "}
                  </>
                }
                className="tab-label"
              />
            ))}
          </Tabs>
          <Box className="tab-content">
            {loading ? (
              <div>Loading content...</div>
            ) : error ? (
              <div>Error loading content: {error.message}</div>
            ) : (
              <div>{tabContent && JSON.stringify(tabContent)}</div>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default VerticalTabs;
