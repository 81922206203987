// import React from "react";
// import {
//   Typography,
//   Card,
//   CardContent,
//   CardMedia,
//   Stack,
//   CardActions,
//   Button,
//   Box,
//   useMediaQuery,
//   Grid,
// } from "@mui/material";
// import bis from "../../Assets/Images/bis-crs.webp";
// import { useNavigate } from "react-router-dom";
// import "./ServicesCards.scss"
// // import { GrassSharp } from "@mui/icons-material";


// export const data = [
//   {
//     id: 1,
//     title: "Service Title",
//     content:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
//       image: bis, 
//     btntext: "Read More",
//   },
//   {
//     id: 2,
//     title: "Service Title2",
//     content:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
//       image: bis, 
//     btntext: "Read More",
//   },
//   {
//     id: 3,
//     title: "Service Title",
//     content:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
//       image: bis, 
//     btntext: "Read More",
//   },
//   {
//     id: 4,
//     title: "Service Title",
//     content:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
//       image: bis, 
//     btntext: "Read More",
//   },
//   {
//     id: 5,
//     title: "Service Title",
//     content:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
//       image: bis,  
//       btntext: "Read More",
//   },
//   {
//     id: 6,
//     title: "Service Title",
//     content:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
//       image: bis, 
//     btntext: "Read More",
//   },
//   {
//     id: 7,
//     title: "Service Title",
//     content:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
//       image: bis, 
//     btntext: "Read More",
//   }, {
//     id: 8,
//     title: "Service Title",
//     content:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
//       image: bis, 
//     btntext: "Read More",
//   }, {
//     id: 9,
//     title: "Service Title",
//     content:
//       "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
//       image: bis, 
//     btntext: "Read More",
//   },
// ];

// const ServicesCards = ({ category }) => {
//   const navigate = useNavigate();
 
//   const handleClick = () => {
//     navigate(`/servicedetail`);
//   }
//   const isMobile = useMediaQuery("(max-width:767px)");

//   return (
//     <>
    
//       <Stack
//         className="Services"
//          margin={(isMobile ? 0 :"2")}
//         direction={{ xs: "column", sm: "row" }}
//         // spacing={{ xs: 1, sm: 1, md: 2}}
//         sx={{
//           display: "flex",
//           justifyContent: "flex-start",
//           flexWrap: "wrap",
//           alignItems: "center",
//         }}
//       >
//         {data.map((card) => (
          
//           <Grid item xs={12} sm={6} md={4}  className="ServicesPage__cards"
//           >
//           <Card
//             sx={{boxShadow: 3 }}
//             className="service-card"
//             key={card.id}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "spaceBetween",
//                 alignItems: "center",
//               }}
//             >
//               <CardMedia
//                 sx={{ height: 100, width: 100}}
//                 image={card.image}
//                 className="cardmedia_image"
//               />
//               <Typography gutterBottom variant="h6" component="div">
//                 {card.title}
//               </Typography>
//             </Box>
//             <CardContent>
//               <Typography variant="body1" color="text.secondary">
//                 {card.content}
//               </Typography>
//             </CardContent>
//             <CardActions>
//               <Button variant="outlined" onClick={handleClick}>{card.btntext}</Button>
//             </CardActions>
//           </Card>
//           </Grid>
//         ))}
//       </Stack>
//     </>
//   );
// };

// export default ServicesCards;


import React, { useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Stack,
  CardActions,
  Button,
  Box,
  useMediaQuery,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useFetchData from "../../api/UserFetchData";

const ServicesCards = ({ category }) => {
  const navigate = useNavigate();
  const { data, loading, error } = useFetchData("/api/frontend/v1/service"); // Using custom hook
  const extractBlogContent = (service_description) => {
    try {
      const parsedContent =JSON.parse(service_description);
      return parsedContent.blocks.map(block => block.data.text).join(' ');
    } catch (error) {
      console.error("Error parsing service description:", error);
      return "";
    }
  };


  const handleClick = () => {
    navigate(`/servicedetail`);
  }
  const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : error ? (
        <Typography>Error: {error}</Typography>
      ) : (
        <Stack
          className="Services"
           gap={(isMobile ? '10px' :"20px")}
          direction={{ xs: "column", sm: "row" }}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {data.map((card) => (
            <Grid item xs={12} sm={6} md={4} key={card.service_id} className="ServicesPage__cards"
            maxWidth={{xs:'100%',sm:'100%',md:'300px'}}
            // height={{xs:'100%',sm:'100%',md:'300px'}}

            >
              <Card sx={{boxShadow: 3 }} className="service-card">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "spaceBetween",
                    alignItems: "center",
                    padding:'10px'
                  }}
                >
                  <CardMedia
                    sx={{ height: 80, width: 80,backgroundSize:'contain' }}
                    image={card.service_img_url}
                    className="cardmedia_image"
                  />
                  <Typography gutterBottom variant="h6" component="div">
                    {card.service_name}
                  </Typography>
                </Box>
                <CardContent>
                  <Typography variant="body1" color="text.secondary">
                  {(extractBlogContent(card.service_description)).slice(0, 150)}            
                </Typography>
                </CardContent>
                <CardActions>
                  <Button variant="outlined" onClick={handleClick}>Read More</Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Stack>
      )}
    </>
  );
};

export default ServicesCards;
