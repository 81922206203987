// import React, { useState } from "react";
// import { Box, Grid, Stack, Typography, Tabs, Tab, Button } from "@mui/material";
// import productimg from "../Assets/Images/product-img-1.svg";
// import { Share } from "@mui/icons-material";
// import ConnectUs from "../Components/ConnectUs";
// import "./ProductList.scss";


// const ProductList = () => {
//   const TabPanel = ({ children, value, index, ...other }) => {
//     return (
//       <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`tabpanel-${index}`}
//         aria-labelledby={`tab-${index}`}
//         {...other}
//       >
//         {value === index && (
//           <Box sx={{ p: 3 }}>
//             <Typography>{children}</Typography>
//           </Box>
//         )}
//       </div>
//     );
//   };
//   const [value, setValue] = useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//     <section>
//       <Box className="AboutUsPage ProductList" padding={3}>
//         <Typography
//           variant="h4"
//           className="AboutUsPage__title ProductList__title"
//           textAlign={"center"}
//           position={"relative"}
//           m={3}
//         >
//           Product List{" "}
//         </Typography>
//         <Stack>
//           <Grid container spacing={3} paddingBlock={3}>
//             <Grid
//               item
//               xs={12}
//               sm={4}
//               md={4}
//               textAlign={"left"}
//               className="ProductList_image"
//             >
//               <img
//                 src={productimg}
//                 alt=""
//                 className="Product Image"
//                 loading="lazy"
//               />
//             </Grid>
//             <Grid item xs={12} sm={8} md={8} className="ProductList_details">
//               <Stack direction="row" mb={2}>
//                 <Typography variant="h6" component="span" marginRight={5}>
//                   Product Name:
//                 </Typography>
//                 <Typography variant="heading" component="span">
//                   Laptop / Notebook / Tablets
//                 </Typography>
//               </Stack>
//               <Stack direction="row" mb={2}>
//                 <Typography variant="h6" component="span" marginRight={5}>
//                   Technical Name :
//                 </Typography>
//                 <Typography variant="heading" component="span">
//                   BIS / CRS Registration
//                 </Typography>
//               </Stack>
//               <Stack direction="row" mb={2}>
//                 <Typography variant="h6" component="span" marginRight={5}>
//                   Product Name :
//                 </Typography>
//                 <Typography variant="heading" component="span">
//                   Laptop / Notebook / Tablets
//                 </Typography>
//               </Stack>
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               sm={12}
//               md={12}
//               className="ProductList_description"
//             >
//               <Typography>
//                 Laptops, notebooks, and tablets are versatile and portable
//                 computing devices that have transformed the way we work, learn,
//                 and stay connected in the digital age. Laptops and notebooks,
//                 often used interchangeably, are compact personal computers with
//                 built-in keyboards and screens, offering a full range of
//                 software applications and capabilities for tasks like web
//                 browsing, word processing, and multimedia. Tablets, on the other
//                 hand, are touchscreen-based devices that are more compact and
//                 lightweight, providing a mobile and intuitive interface for
//                 various apps and entertainment. These devices have
//                 revolutionized the workplace, education, and entertainment
//                 industries, enabling people to work remotely, access information
//                 on the go, and enjoy multimedia content with ease. Laptops and
//                 notebooks offer the familiarity of a traditional computer, while
//                 tablets emphasize mobility and touch-centric interactions. All
//                 three have their unique strengths and are integral to our
//                 connected lives, serving different needs and preferences.
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               sm={12}
//               md={12}
//               className="ProductList_description"
//             >
//               <Typography>
//                 Laptops, notebooks, and tablets are versatile and portable
//                 computing devices that have transformed the way we work, learn,
//                 and stay connected in the digital age. Laptops and notebooks,
//                 often used interchangeably, are compact personal computers with
//                 built-in keyboards and screens, offering a full range of
//                 software applications and capabilities for tasks like web
//                 browsing, word processing, and multimedia. Tablets, on the other
//                 hand, are touchscreen-based devices that are more compact and
//                 lightweight, providing a mobile and intuitive interface for
//                 various apps and entertainment. These devices have
//                 revolutionized the workplace, education, and entertainment
//                 industries, enabling people to work remotely, access information
//                 on the go, and enjoy multimedia content with ease. Laptops and
//                 notebooks offer the familiarity of a traditional computer, while
//                 tablets emphasize mobility and touch-centric interactions. All
//                 three have their unique strengths and are integral to our
//                 connected lives, serving different needs and preferences.
//               </Typography>
//             </Grid>
//             <Grid
//               item
//               xs={12}
//               sm={12}
//               md={12}
//               className="ProductList__tab-data"
//             >
//               <div>
//                 <Tabs value={value} onChange={handleChange}>
//                   <Tab
//                     label=" BIS / CRS Registration * "
//                     className="tab-label"
//                   />
//                   <Tab label="Services Category" />
//                   <Tab label="Services Category" />
//                   <Tab label="Services Category" />
//                   <Tab label="Services Category" />
//                   <Tab label="Services Category * " />
//                   <Tab label="Services Category" />
//                 </Tabs>
//                 <TabPanel value={value} index={0} className="tab-panel">
//                   <Typography variant="subtitle1" component="span">
//                     Indian Standard :
//                     <Typography variant="subtitle1" component="span">
//                       IS 13252(Part 1):2010
//                     </Typography>
//                   </Typography>
//                   <Typography>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit duis
//                     enim justo in mauris posuere dolor dolor sapien sit egestas.
//                     Ut venenatis faucibus non sed faucibus mauris ultricies Cras
//                     varius proin amet vehicula magna maecenas gravida vel
//                     volutpat sed. Lorem ipsum dolor sit amet, consectetur
//                     adipiscing elit duis enim justo in mauris posuere dolor
//                     dolor sapien sit egestas. Ut venenatis faucibus non sed
//                     faucibus mauris ultricies Cras varius proin amet vehicula
//                     magna maecenas gravida vel volutpat sed..Lorem ipsum dolor
//                     sit amet, consectetur adipiscing elit duis enim justo in
//                     mauris posuere dolor dolor sapien sit egestas. Ut venenatis
//                     faucibus non sed faucibus mauris ultricies Cras varius proin
//                     amet vehicula magna maecenas gravida vel volutpat sed.
//                   </Typography>
//                   <Box
//                     direction="row"
//                     sx={{
//                       display: "flex",
//                       justifyContent: "center",
//                       margin: "20px",
//                     }}
//                   >
//                     <Button variant="outlined" sx={{ marginRight: "4rem" }}>
//                       Learn More
//                     </Button>
//                     <Button variant="contained">Latest Notification</Button>
//                   </Box>
//                 </TabPanel>
//                 <TabPanel value={value} index={1}>
//                   <Typography variant="subtitle1" component="span">
//                     Indian Standard :
//                     <Typography variant="subtitle1" component="span">
//                       IS 13252(Part 1):2010
//                     </Typography>
//                   </Typography>
//                   <Typography>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit duis
//                     enim justo in mauris posuere dolor dolor sapien sit egestas.
//                     Ut venenatis faucibus non sed faucibus mauris ultricies Cras
//                     varius proin amet vehicula magna maecenas gravida vel
//                     volutpat sed.
//                   </Typography>
//                   <Box
//                     direction="row"
//                     sx={{
//                       display: "flex",
//                       justifyContent: "center",
//                       margin: "20px",
//                     }}
//                   >
//                     <Button variant="outlined" sx={{ marginRight: "4rem" }}>
//                       Learn More
//                     </Button>
//                     <Button variant="contained">Latest Notification</Button>
//                   </Box>
//                 </TabPanel>
//                 <TabPanel value={value} index={2}>
//                   <Typography variant="subtitle1" component="span">
//                     Indian Standard :
//                     <Typography variant="subtitle1" component="span">
//                       IS 13252(Part 1):2010
//                     </Typography>
//                   </Typography>
//                   <Typography>
//                     Lorem ipsum dolor sit amet, consectetur adipiscing elit duis
//                     enim justo in mauris posuere dolor dolor sapien sit egestas.
//                     Ut venenatis faucibus non sed faucibus mauris ultricies Cras
//                     varius proin amet vehicula magna maecenas gravida vel
//                     volutpat sed. Lorem ipsum dolor sit amet, consectetur
//                     adipiscing elit duis enim justo in mauris posuere dolor
//                     dolor sapien sit egestas.
//                   </Typography>
//                   <Box
//                     direction="row"
//                     sx={{
//                       display: "flex",
//                       justifyContent: "center",
//                       margin: "20px",
//                     }}
//                   >
//                     <Button variant="outlined" sx={{ marginRight: "4rem" }}>
//                       Learn More
//                     </Button>
//                     <Button variant="contained">Latest Notification</Button>
//                   </Box>
//                 </TabPanel>
//               </div>
//             </Grid>
//           </Grid>
//         </Stack>
//         <Box
//           direction="row"
//           sx={{ display: "flex", justifyContent: "center", margin: "20px" }}
//         >
//           <Button variant="contained">
//             Share Services <Share sx={{ paddingLeft: "10px" }} />
//           </Button>
//         </Box>
//       </Box>
//       <ConnectUs />
//     </section>
//   );
// };

// export default ProductList;


import React, { useState } from "react";
import { Box, Grid, Stack, Typography, Tabs, Tab, Button } from "@mui/material";
import productimg from "../Assets/Images/product-img-1.svg";
import { Share } from "@mui/icons-material";
import ConnectUs from "../Components/ConnectUs";
import "./ProductList.scss";
import useFetchData from "../api/UserFetchData";

const ProductList = () => {
  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other }
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fetch product list data
  const { data: productList, loading: productLoading, error: productError } = useFetchData('/api/frontend/v1/product/1');
  
  // Fetch tab panel details data
  const { data: tabDetails, loading: tabLoading, error: tabError } = useFetchData('/api/frontend/v1/product/1/services');

  if (productLoading || tabLoading) return <div>Loading...</div>;
  if (productError || tabError) return <div>Error loading data.</div>;
console.log("productList",productList);
  return (
    <section>
      <Box className="AboutUsPage ProductList" padding={3}>
        <Typography
          variant="h4"
          className="AboutUsPage__title ProductList__title"
          textAlign={"center"}
          position={"relative"}
          m={3}
        >
          Product List{" "}
        </Typography>
        <Stack>
          <Grid container spacing={3} paddingBlock={3}>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              textAlign={"left"}
              className="ProductList_image"
            >
              <img
                src={productList.product_img_url}
                alt=""
                className="product_image"
                loading="lazy"
              />
            </Grid>
            <Grid item xs={12} sm={8} md={8} className="ProductList_details">
              <Stack direction="row" mb={2}>
                <Typography variant="h6" component="span" marginRight={5}>
                Product Name
                </Typography>
                <Typography variant="heading" component="span">
                {productList.product_name}
                </Typography>
              </Stack>
              <Stack direction="row" mb={2}>
                <Typography variant="h6" component="span" marginRight={5}>
                Technical Name
                </Typography>
                <Typography variant="heading" component="span">
                {productList.product_technical_name}
                </Typography>
              </Stack>
              <Stack direction="row" mb={2}>
                <Typography variant="h6" component="span" marginRight={5}>
                Product Category
                </Typography>
                <Typography variant="heading" component="span">
                {productList.product_categories[0].product_category_name}
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="ProductList_description"
            >
              <Typography>
              {productList.product_content}
              </Typography>
            </Grid>
          
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className="ProductList__tab-data"
            >
              <div>
                {console.log("tabDetails",tabDetails)}
                <Tabs value={value} onChange={handleChange}>
                  {tabDetails.map((tab, index) => (
                    <Tab label={tab.service_name} key={index} />
                  ))}
                </Tabs>
                {tabDetails.map((tab, index) => (
                  <TabPanel value={value} index={index} key={index} className="tab-panel">
                    <Typography variant="subtitle1" component="span">
                      {tab.service_description}
                    </Typography>
                    <Box
                    direction="row"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px",
                    }}
                  >
                    <Button variant="outlined" sx={{ marginRight: "4rem" }}>
                      Learn More
                    </Button>
                    <Button variant="contained">Latest Notification</Button>
                  </Box>
                  </TabPanel>
                ))}
                
              </div>
            </Grid>
          </Grid>
        </Stack>
        <Box
          direction="row"
          sx={{ display: "flex", justifyContent: "center", margin: "20px" }}
        >
          <Button variant="contained">
            Share Services <Share sx={{ paddingLeft: "10px" }} />
          </Button>
        </Box>
      </Box>
      <ConnectUs />
    </section>
  );
};

export default ProductList;

