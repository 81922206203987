// import { React, useState, useEffect, useContext } from "react";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import logo from "../../Assets/Images/logo.png";
// import Typography from "@mui/material/Typography";
// import exporticon from "../../Assets/Images/icons/export-icon.svg";
// import notificationicon from "../../Assets/Images/icons/notification-icon.svg";
// import search from "../../Assets/Images/icons/search.svg";
// import login from "../../Assets/Images/icons/login.svg";
// import Sidebar from "../Sidebar/Sidebar";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
// import SearchIcon from "@mui/icons-material/Search";

// import {
//   Divider,
//   Drawer,
//   IconButton,
//   MenuItem,
//   TextField,
//   useMediaQuery,
// } from "@mui/material";
// import "./Header.scss";
// import { Link } from "react-router-dom";
// import SidebarContext from "../SidebarContext";
// // import { Link, useNavigate } from "react-router-dom";

// const Header = () => {
//   // const navigate = useNavigate();

//   // const handleClick = () => {
//   //   navigate(`/Services`);
//   // }
//   const { setActive } = useContext(SidebarContext);
//   const serviceslist = [
//     {
//         ServiceName: "Services 1",
//         ServiceData: '["Services 1",isi]',
//       },
//       {
//         ServiceName: "Services 2",
//         ServiceData: '["Services 2",isi2]',
//       },  {
//         ServiceName: "Services 3",
//         ServiceData: '["Services 3",isi3]',
//       },  {
//         ServiceName: "Services 4",
//         ServiceData: '["Services 4",isi]',
//       },  {
//         ServiceName: "Services 5",
//         ServiceData: '["Services 5",isi]',
//       },

// ];

//   const options = [
//     { value: "Services 1", label: "Services  1" },
//     { value: "Services 2", label: "Services  2" },
//     { value: "Services 3", label: "Services  3" },
//   ];
//   const [selectedOption, setSelectedOption] = useState();

//   const handleChange = (event) => {
//     setSelectedOption(event.target.value);
//   };

//   const isMobile = useMediaQuery("(max-width:992px)");
//   /* Search Topbar */
//   const [openDrawer, setOpenDrawer] = useState(false);

//   const handleDrawerOpen = () => {
//     setOpenDrawer(true);
//   };

//   const handleDrawerClose = () => {
//     setOpenDrawer(false);
//   };
//   /* Search Topbar */

//   /* Sidebar */
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [showBox, setShowBox] = useState(true);

//   const toggleBox = () => {
//     setShowBox(!showBox);
//   };

//   const toggleSidebar = () => {
//     setActive("Login");
//     setIsSidebarOpen(!isSidebarOpen);
//   };
//   /* Sidebar */

//   const [isSticky, setIsSticky] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollPosition = window.scrollY;
//       if (scrollPosition > 100) {
//         setIsSticky(true);
//       } else {
//         setIsSticky(false);
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <>
//       <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
//          </Sidebar>

//       <Box
//         sx={{ flexGrow: 1, boxShadow: 3, backgroundColor: "white" }}
//         className="header-box"
//         position={isSticky && !isMobile ? "fixed" : "static"}
//         style={{
//           top: isSticky ? "0" : "auto",
//           backgroundColor: isSticky ? "#C3E7FF" : "#fff",
//         }}
//       >
//         {isMobile ? (
//           <>
//             <Grid
//               container
//               sx={{
//                 paddingBlock: "1rem",
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//               }}
//             >
//               <Grid
//                 item
//                 xs={6}
//                 className="company-logo"
//                 sx={{ paddingTop: "0" }}
//               >
//                 <a href="https://www.bl-india.com/">
//                   <img
//                     src={logo}
//                     alt="Company-logo"
//                     loading="lazy"
//                     width={"150px"}
//                   />
//                 </a>
//               </Grid>
//               <Grid item xs={6} sx={{ flex: 0, display: "flex" }}>
//                 <img
//                   onClick={handleDrawerOpen}
//                   src={search}
//                   alt="Search"
//                   className="header__right__licon"
//                 />
//                 <Drawer
//                   // className={drawer}
//                   variant="temporary"
//                   anchor="top"
//                   open={openDrawer}
//                   onClose={handleDrawerClose}

//                   // classes={{
//                   //   paper:
//                   //   drawerPaper,
//                   // }}
//                 >
//                   <Box padding={1}>
//                     <Box
//                       paddingBlock={1}
//                       sx={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "center",
//                       }}
//                     >
//                       <Typography variant="h6">Search Here</Typography>
//                       {/* <CancelOutlinedIcon   onClick={handleClose}  className='close-icon' color="primary" fontSize="medium" /> */}
//                     </Box>
//                     <Divider sx={{ marginBottom: 1 }} />
//                     <Box paddingBlock={1}>
//                       <Grid 
//                         container
//                         sx={{
//                           display: "flex",
//                           justifyContent: "center",
//                           alignItems: "center",
//                         }}
//                         className="select_search_box"
//                       >
//                         <Grid item xs={3}>
//                           <TextField
//                             id="outlined-select-currency"
//                             select
//                             label="Services"
//                             variant="outlined"
//                             value={selectedOption}
//                             onChange={handleChange}
//                             fullWidth
//                           >
//                             {serviceslist.map((option) => (
//                               <MenuItem key={serviceslist.value} value={serviceslist.value}>
//                                 {serviceslist.label}
//                               </MenuItem>
//                             ))}
//                           </TextField>
//                         </Grid>
//                         <Grid item xs={9}>
//                           <TextField
//                             id="outlined-textbox"
//                             label="Search Here for Approvals"
//                             variant="outlined"
//                             fullWidth
//                             InputProps={{
//                               endAdornment: (
//                                 <IconButton aria-label="search">
//                                   <SearchIcon variant="contained" />
//                                 </IconButton>
//                               ),
//                             }}
//                           />
//                         </Grid>
//                       </Grid>
//                     </Box>
//                   </Box>
//                 </Drawer>
//                 <img
//                   onClick={toggleSidebar}
//                   src={login}
//                   alt="Login"
//                   className="header__right__licon"
//                 />

//                 {/* <SearchOutlinedIcon />
//               <LockOutlinedIcon /> */}
//               </Grid>
//             </Grid>
//             <Grid
//               container
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "flex-start",
//               }}
//             >
//               <Grid
//                 item
//                 xs={6}
//                 sx={{
//                   paddingTop: "0",
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <Link
//                   to="./services"
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     src={exporticon}
//                     alt="export icon"
//                     className="export-icon cursor-pointer"
//                     style={{ paddingRight: 10 }}
//                   />
//                   <Typography
//                     variant="h6"
//                     sx={{ fontSize: 14 }}
//                     className="cursor-pointer"
//                   >
//                     Services
//                     <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
//                       Provided by Bl-India
//                     </Typography>
//                   </Typography>
//                 </Link>
//               </Grid>
//               <Grid
//                 item
//                 xs={6}
//                 sx={{
//                   paddingTop: "0",
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 <Link
//                   to="./notification"
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     alignItems: "center",
//                   }}
//                 >
//                   <img
//                     src={notificationicon}
//                     alt="Notification"
//                     className="export-icon cursor-pointer"
//                     style={{ paddingRight: 10 }}
//                   />

//                   <Typography variant="h6" sx={{ fontSize: 14 }}>
//                     Notification
//                     <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
//                       Provided by Bl-India
//                     </Typography>
//                   </Typography>
//                 </Link>
//               </Grid>
//             </Grid>
//           </>
//         ) : (
//           <Grid
//             container
//             spacing={2}
//             sx={{
//               paddingBlock: "1rem",
//             }}
//           >
//             <Grid item xs={6} pb={2} pt={0}>
//               <a href="https://www.bl-india.com/">
//                 {" "}
//                 <img
//                   src={logo}
//                   alt="Company-logo"
//                   loading="lazy"
//                   className="company-logo"
//                 />
//               </a>
//             </Grid>
//             <Grid
//               item
//               xs={6}
//               pb={2}
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//                 paddingTop: "0",
//               }}
//             >
//               <div className="header__right">
//                 <Link to="./services">
//                   <div className="header__right__icon">
//                     <img
//                       src={exporticon}
//                       alt="export icon"
//                       className="export-icon"
//                     />
//                     <Typography variant="h5" sx={{ mx: 2 }}>
//                       Services
//                       <Typography variant="subtitle1">
//                         Provided by Bl-India
//                       </Typography>
//                     </Typography>
//                   </div>
//                 </Link>
//                 <Link to="./notification">
//                   <div className="header__right__icon">
//                     <img
//                       src={notificationicon}
//                       alt="Notification"
//                       className="export-icon"
//                     />
//                     <Typography variant="h5" sx={{ mx: 2 }}>
//                       Notification
//                       <Typography variant="subtitle1">
//                         Provided by Bl-India
//                       </Typography>
//                     </Typography>
//                   </div>
//                 </Link>
//               </div>

//               <div className="header__right">
//                 <img
//                   onClick={handleDrawerOpen}
//                   src={search}
//                   alt="Search"
//                   className="header__right__licon"
//                 />
//                    {showBox && (
//                 <Drawer
//                   // className={drawer}
//                   variant="temporary"
//                   anchor="top"
//                   open={openDrawer}
//                   onClose={handleDrawerClose}

//                   // classes={{
//                   //   paper:
//                   //   drawerPaper,
//                   // }}
//                 >
               
//                     <Box padding={2}>
//                       <Box
//                         paddingBlock={2}
//                         sx={{
//                           display: "flex",
//                           justifyContent: "space-between",
//                           alignItems: "center",
//                         }}
//                       >
//                         <Typography variant="h4">Search Here</Typography>
//                         <CancelOutlinedIcon
//                           className="close-icon"
//                           color="primary"
//                           fontSize="medium"
//                           onClick={toggleBox}
//                         />
//                       </Box>
//                       <Divider sx={{ marginBottom: 1 }} />
//                       <Box paddingBlock={2}>
//                         <Grid
//                           container
//                           sx={{
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                           }}
//                           className="select_search_box"

//                         >
//                           <Grid item xs={1}>
//                             <TextField
//                               id="outlined-select-currency"
//                               select
//                               label="Services"
//                               variant="outlined"
//                               value={selectedOption}
//                               onChange={handleChange}
//                               fullWidth
//                             >
//                               {options.map((option) => (
//                                 <MenuItem
//                                   key={option.value}
//                                   value={option.value}
//                                 >
//                                   {option.label}
//                                 </MenuItem>
//                               ))}
//                             </TextField>
//                           </Grid>
//                           <Grid item xs={6}>
//                             <TextField
//                               id="outlined-textbox"
//                               label="Search Here for Approvals"
//                               variant="outlined"
//                               fullWidth
//                               InputProps={{
//                                 endAdornment: (
//                                   <IconButton aria-label="search">
//                                     <SearchIcon variant="contained" />
//                                   </IconButton>
//                                 ),
//                               }}
//                             />
//                           </Grid>
//                         </Grid>
//                       </Box>
//                     </Box>
                 
//                 </Drawer>
//                  )}
//                 <img
//                   onClick={toggleSidebar}
//                   src={login}
//                   alt="Login"
//                   className="header__right__licon"
//                 />
//               </div>
//             </Grid>
//           </Grid>
//         )}
//       </Box>
//     </>
//   );
// };

// export default Header;


import { React, useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import logo from "../../Assets/Images/logo.png";
import Typography from "@mui/material/Typography";
import exporticon from "../../Assets/Images/icons/export-icon.svg";
import notificationicon from "../../Assets/Images/icons/notification-icon.svg";
import search from "../../Assets/Images/icons/search.svg";
import login from "../../Assets/Images/icons/login.svg";
import Sidebar from "../Sidebar/Sidebar";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import "./Header.scss";
import { Link } from "react-router-dom";
import SidebarContext from "../SidebarContext";

const Header = () => {
  const { setActive } = useContext(SidebarContext);
  const [servicesList, setServicesList] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showBox, setShowBox] = useState(true);
  const [isSticky, setIsSticky] = useState(false);
  const isMobile = useMediaQuery("(max-width:992px)");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const toggleBox = () => {
    setShowBox(!showBox);
  };

  const toggleSidebar = () => {
    setActive("Login");
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch("/api/frontend/v1/home/section/services");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setServicesList(data);
      } catch (error) {
        console.error("Failed to fetch services:", error);
      }
    };

    fetchServices();
  }, []);

  return (
    <>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

      <Box
        sx={{ flexGrow: 1, boxShadow: 3, backgroundColor: "white" }}
        className="header-box"
        position={isSticky && !isMobile ? "fixed" : "static"}
        style={{
          top: isSticky ? "0" : "auto",
          backgroundColor: isSticky ? "#C3E7FF" : "#fff",
        }}
      >
        {isMobile ? (
          <>
            <Grid
              container
              sx={{
                paddingBlock: "1rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={6}
                className="company-logo"
                sx={{ paddingTop: "0" }}
              >
                <a href="https://www.bl-india.com/">
                  <img
                    src={logo}
                    alt="Company-logo"
                    loading="lazy"
                    width={"150px"}
                  />
                </a>
              </Grid>
              <Grid item xs={6} sx={{ flex: 0, display: "flex" }}>
                <img
                  onClick={handleDrawerOpen}
                  src={search}
                  alt="Search"
                  className="header__right__licon"
                />
                <Drawer
                  variant="temporary"
                  anchor="top"
                  open={openDrawer}
                  onClose={handleDrawerClose}
                >
                  <Box padding={1}>
                    <Box
                      paddingBlock={1}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6">Search Here</Typography>
                    </Box>
                    <Divider sx={{ marginBottom: 1 }} />
                    <Box paddingBlock={1}>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="select_search_box"
                      >
                        <Grid item xs={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Services"
                            variant="outlined"
                            value={selectedOption}
                            onChange={handleChange}
                            fullWidth
                          >
                            {servicesList.map((option) => (
                              <MenuItem key={option.ServiceName} value={option.ServiceName}>
                                {option.ServiceName}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={9}>
                          <TextField
                            id="outlined-textbox"
                            label="Search Here for Approvals"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <IconButton aria-label="search">
                                  <SearchIcon variant="contained" />
                                </IconButton>
                              ),
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Drawer>
                <img
                  onClick={toggleSidebar}
                  src={login}
                  alt="Login"
                  className="header__right__licon"
                />
              </Grid>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{
                  paddingTop: "0",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Link
                  to="./services"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={exporticon}
                    alt="export icon"
                    className="export-icon cursor-pointer"
                    style={{ paddingRight: 10 }}
                  />
                  <Typography
                    variant="h6"
                    sx={{ fontSize: 14 }}
                    className="cursor-pointer"
                  >
                    Services
                    <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                      Provided by Bl-India
                    </Typography>
                  </Typography>
                </Link>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  paddingTop: "0",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Link
                  to="./notification"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={notificationicon}
                    alt="Notification"
                    className="export-icon cursor-pointer"
                    style={{ paddingRight: 10 }}
                  />

                  <Typography variant="h6" sx={{ fontSize: 14 }}>
                    Notification
                    <Typography variant="subtitle1" sx={{ fontSize: 12 }}>
                      Provided by Bl-India
                    </Typography>
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid
            container
            spacing={2}
            sx={{
              paddingBlock: "1rem",
            }}
          >
            <Grid item xs={6} pb={2} pt={0}>
              <a href="https://www.bl-india.com/">
                {" "}
                <img
                  src={logo}
                  alt="Company-logo"
                  loading="lazy"
                  className="company-logo"
                />
              </a>
            </Grid>
            <Grid
              item
              xs={6}
              pb={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "0",
              }}
            >
              <div className="header__right">
                <Link to="./services">
                  <div className="header__right__icon">
                    <img
                      src={exporticon}
                      alt="export icon"
                      className="export-icon"
                    />
                    <Typography variant="h5" sx={{ mx: 2 }}>
                      Services
                      <Typography variant="subtitle1">
                        Provided by Bl-India
                      </Typography>
                    </Typography>
                  </div>
                </Link>
                <Link to="./notification">
                  <div className="header__right__icon">
                    <img
                      src={notificationicon}
                      alt="Notification"
                      className="export-icon"
                    />
                    <Typography variant="h5" sx={{ mx: 2 }}>
                      Notification
                      <Typography variant="subtitle1">
                        Provided by Bl-India
                      </Typography>
                    </Typography>
                  </div>
                </Link>
              </div>

              <div className="header__right">
                <img
                  onClick={handleDrawerOpen}
                  src={search}
                  alt="Search"
                  className="header__right__licon"
                />
                {showBox && (
                  <Drawer
                    variant="temporary"
                    anchor="top"
                    open={openDrawer}
                    onClose={handleDrawerClose}
                  >
                    <Box padding={2}>
                      <Box
                        paddingBlock={2}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h4">Search Here</Typography>
                        <CancelOutlinedIcon
                          className="close-icon"
                          color="primary"
                          fontSize="medium"
                          onClick={toggleBox}
                        />
                      </Box>
                      <Divider sx={{ marginBottom: 1 }} />
                      <Box paddingBlock={2}>
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="select_search_box"
                        >
                          <Grid item xs={1}>
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Services"
                              variant="outlined"
                              value={selectedOption}
                              onChange={handleChange}
                              fullWidth
                            >
                              {servicesList.map((option) => (
                                <MenuItem
                                  key={option.service_name}
                                  value={option.service_name}
                                >
                                  {option.service_name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id="outlined-textbox"
                              label="Search Here for Approvals"
                              variant="outlined"
                              fullWidth
                              InputProps={{
                                endAdornment: (
                                  <IconButton aria-label="search">
                                    <SearchIcon variant="contained" />
                                  </IconButton>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Drawer>
                )}
                <img
                  onClick={toggleSidebar}
                  src={login}
                  alt="Login"
                  className="header__right__licon"
                />
              </div>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Header;
