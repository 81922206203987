import { React } from 'react';
import { Box, Typography, List, ListItemIcon, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Call, Email, Place, Reviews } from '@mui/icons-material';
// import GoogleMapComponent from './GoogleMapComponent';
import "./ContactUsPage.scss"
import useFetchData from '../api/UserFetchData';
import ContactUsPageForm from './ContactUsPageForm';


const ContactUSpageTextData = () => {
  

      const { data, loading, error } = useFetchData('/api/frontend/v1/contact-us');
  if (loading) {
    return <CircularProgress/>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  return (
<>

<Grid container spacing={5} className='ContactUsPage_getinTouch' sx={{
        paddingBlock: '2rem'
        }}>
          <Grid item xs={12} md={6} textAlign={'left'}>
            <Typography variant="h6" className='ContactUsPage__title '>{data[0].page_tag_line}
              <Typography variant="subtitle1" className='ContactUsPage__title '>(Brand Liaison India Pvt. Ltd.)</Typography>
            </Typography>
            <Typography variant="subtitle2" component='p' sx={{ width: '90%' }}>{data[0].page_description}</Typography>
            <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              <List component="ul" aria-label="main mailbox folders">
                <li className='ContactUsPage__contact_list'>
                  <ListItemIcon className='ContactUsPage--contact-icon'>
                    <Place />
                  </ListItemIcon>
                  <Typography variant='body2' component='p'>
                    Address
                    <Typography variant='body1'>{data[0].company_address}
                    </Typography>
                  </Typography>
                </li>
                <li className='ContactUsPage__contact_list'>
                  <ListItemIcon className='ContactUsPage--contact-icon'>
                    <Email />
                  </ListItemIcon>
                  <Typography variant='body2' component='p'>
                    Email Address
                    <Typography variant='body1'>{data[0].company_email}
                    </Typography>
                  </Typography>
                </li>
                <li className='ContactUsPage__contact_list'>
                  <ListItemIcon className='ContactUsPage--contact-icon'>
                    <Call />
                  </ListItemIcon>
                  <Typography variant='body2' component='p'>
                    Mobile number/Office Number
                    
                    <Typography variant='body1'>{data[0].mobile_number}
                    </Typography>
                    <Typography variant='body1'>{data[0].office_number}
                    </Typography>
                  </Typography>
                </li>
                <li className='ContactUsPage__contact_list'>
                  <ListItemIcon className='ContactUsPage--contact-icon'>
                    <Reviews />
                  </ListItemIcon>
                  <Typography variant='body2' component='p'>
                    Feedback / Grievance
                    <Typography variant='body1'>{data[0].feedback_person}
                    </Typography>
                    <Typography variant='body1'>Email :rk@bl-india.com
                    </Typography>
                  </Typography>
                </li>

              </List>

            </Box>

          </Grid>
         <ContactUsPageForm/>
        </Grid>
</>  )
}

export default ContactUSpageTextData