// import React, { useContext, useState } from "react";
// import Box from "@mui/material/Box";
// import Grid from "@mui/material/Grid";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
// import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
// import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
// import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
// import Divider from "@mui/material/Divider";
// import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
// import logowhite from "../../Assets/Images/logo-white.svg";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import YouTubeIcon from "@mui/icons-material/YouTube";
// import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
// import bisisi from "../../Assets/Images/bis-isi.webp";
// import "./Footer.scss";
// import { Link } from "react-router-dom";
// import SidebarContext from "../SidebarContext";
// import Sidebar from "../Sidebar/Sidebar";
// import SocialMedia from "../SocialMedia/SocialMedia";

// const Footer = () => {
//   const isMobile = useMediaQuery("(max-width:767px)");
//   const {setActive} = useContext(SidebarContext);
// const [isSidebarOpen, setIsSidebarOpen] = useState(false);

// const toggleSidebar = () => {
//   setActive("Partner");
//   setIsSidebarOpen(!isSidebarOpen);
// };

//   return (
//     <>
//      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
//       </Sidebar>
//       <Box variant="section" className="footer section-inline" sx={{ flexGrow: 1 }}>
//         {isMobile ? (
//            <>
//               <Accordion defaultExpanded>
//                 <AccordionSummary
//                   expandIcon={<ExpandMoreIcon />}
//                   aria-controls="panel1-content"
//                   id="panel1-header"
//                   color="secondary"
//                 >
//                <Typography className="" variant="body2">
//                   Information
//                 </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                 <ul className="footer__description">
//                   <li>
//                     <LocationOnOutlinedIcon
//                       sx={{ pe: 2 }}
//                       className="footer__description--icon"
//                     />
//                     <Typography>
//                       Brand Liaison India Pvt. Ltd.
//                       <br />
//                       110, Sharma Complex A-2, <br />
//                       Guru Nanak Pura, Laxmi Nagar
//                       <br />
//                       Delhi - 110092, India
//                     </Typography>
//                   </li>
//                   <li>
//                     <CallOutlinedIcon
//                       sx={{ pe: 2 }}
//                       className="footer__description--icon"
//                     />
//                     <Typography>+91-9810363988</Typography>
//                   </li>
//                   <li>
//                     <EmailOutlinedIcon
//                       sx={{ pe: 2 }}
//                       className="footer__description--icon"
//                     />
//                     <Typography>info@exportapproval.com</Typography>
//                   </li>
//                   <li>
//                     <AccessTimeOutlinedIcon
//                       sx={{ pe: 2 }}
//                       className="footer__description--icon"
//                     />
//                     <Typography>info@exportapproval.com</Typography>
//                   </li>
//                 </ul>
//                 </AccordionDetails>
//               </Accordion>
//               <Accordion>
//                 <AccordionSummary
//                   expandIcon={<ExpandMoreIcon />}
//                   aria-controls="panel2-content"
//                   id="panel2-header"
//                 >
//                <Typography className="" variant="body2">
//                   Services
//                 </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                 <ul className="footer__description">
//                   <li>BIS/CRS Registration</li>
//                   <li>BIS/CRS Registration</li>
//                   <li>BIS/CRS Registration</li>
//                   <li>BIS/CRS Registration</li>
//                   <li>BIS/CRS Registration</li>
//                   <li>BIS/CRS Registration</li>
//                 </ul>
//                 </AccordionDetails>
//               </Accordion>
//               <Accordion>
//                 <AccordionSummary
//                   expandIcon={<ExpandMoreIcon />}
//                   aria-controls="panel3-content"
//                   id="panel3-header"
//                 >
//                 <Typography className="" variant="body2">
//                   Join Us
//                 </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                 <ul className="footer__description">
//                   <li>Business Associate</li>
//                   <li>Business Associate</li>
//                   <li>Business Associate</li>
//                   <li>Business Associate</li>
//                   <li>Business Associate</li>
//                   <li>Business Associate</li>
//                 </ul>
//                 </AccordionDetails>
//                </Accordion>
//                <Accordion>
//                 <AccordionSummary
//                   expandIcon={<ExpandMoreIcon />}
//                   aria-controls="panel2-content"
//                   id="panel2-header"
//                 >
//                <Typography className="" variant="body2">
//                Quick Links
//                 </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                 <ul className="footer__description">
//                       <li>
//                         <Link to="/home">Home</Link>
//                       </li>
//                       <li>
//                         <Link to="/about">About Us</Link>
//                       </li>
//                       <li>
//                         <Link to="/download">Download</Link>
//                       </li>
//                       <li>
//                         <Link to="/blog">Blog</Link>
//                       </li>
//                       <li>
//                         <Link to="/media">Media Coverage</Link>
//                       </li>
//                       <li>
//                         <Link to="/Notification">Notification</Link>
//                       </li>
//                     </ul>

//                 </AccordionDetails>
//                 <AccordionDetails>
//                 <ul className="footer__description">
//                       <li>
//                         <Link to="/calendar">Calendar</Link>
//                       </li>
//                       <li>
//                         <Link to="/knowledge">Help Center</Link>
//                       </li>
//                       <li>
//                         <Link to="/Contact"> Contact Us</Link>
//                       </li>
//                       <li>
//                         <Link to="/Careers"> Careers</Link>
//                       </li>
//                       <li>
//                         <Link to="/Services"> Services</Link>
//                       </li>
//                     </ul>
//                 </AccordionDetails>
//               </Accordion>
//               <Divider sx={{ backgroundColor: "white", marginBlock: 3 }} />
//               <Grid
//           container
//           spacing={2}
//           sx={{
//             display: "flex",
//             justifyContent: "space-between",
//             alignItems: "center",
//           }}
//         >

//         </Grid>
//         <Grid
//           container
//           spacing={2}
//           sx={{
//             display: "flex",
//             alignItems: "center",
//           }}
//         >
//           <Grid
//             item
//             className="footer__right"
//             xs={12}
//             md={6}
//             sx={{ display: "flex", justifyContent: "center" }}
//           >
//             <img
//               src={bisisi}
//               className="footer__right--certificate-icon"
//               alt="Linkend"
//               loading="lazy"
//             />
//             <img
//               src={bisisi}
//               className="footer__right--certificate-icon"
//               alt="Linkend"
//               loading="lazy"
//             />
//             <img
//               src={bisisi}
//               className="footer__right--certificate-icon"
//               alt="Logo-white"
//               loading="lazy"
//             />
//             <img
//               src={bisisi}
//               className="footer__right--certificate-icon"
//               alt="Linkend"
//               loading="lazy"
//             />
//           </Grid>

//           <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center" ,marginBlock:3}}>
//             <img src={logowhite} alt="Logo-white" loading="lazy" />
//           </Grid>
//         </Grid>
//         <Grid
//             item
//             className="footer__right"
//             xs={12}
//             md={6}
//             sx={{ display: "flex", justifyContent: "center" }}
//           >
//            <Typography
//             variant="h6"
//             component="div"
//             className="topbar"
//             sx={{ flexGrow: 1, display: "flex", alignItems: "center",justifyContent: "center"  }}
//           >
//             <IconButton
//               aria-label="Twitter"
//               color="whitebg"
//               component="a"
//               href="https://www.facebook.com/BrandLiaisonIndia"
//               target="_blank"
//             >
//               <FacebookIcon className="social_icons" />
//             </IconButton>
//             <IconButton
//               aria-label="Twitter"
//               color="whitebg"
//               component="a"
//               href="https://twitter.com/BrandLiaison"
//               target="_blank"
//             >
//               <TwitterIcon className="social_icons" />
//             </IconButton>
//             <IconButton
//               aria-label="Twitter"
//               color="whitebg"
//               component="a"
//               href="https://in.linkedin.com/company/brand-liaison-india-pvt-ltd1"
//               target="_blank"
//             >
//               <LinkedInIcon className="social_icons" />
//             </IconButton>
//             <IconButton
//               aria-label="Twitter"
//               color="whitebg"
//               component="a"
//               href="https://www.youtube.com/channel/UCJBaZLfC-tnQiQvqJEk-y7w/videos"
//               target="_blank"
//             >
//               <YouTubeIcon className="social_icons" />
//             </IconButton>
//             <IconButton
//               aria-label="Twitter"
//               color="whitebg"
//               component="a"
//               href="https://twitter.com/BrandLiaison"
//               target="_blank"
//             >
//             <ShareOutlinedIcon className="social_icons" />
//             </IconButton>
//           </Typography>
//           </Grid>
//         <Stack>
//           <Typography
//             variant="caption"
//             sx={{ textAlign: "center", paddingTop: 2 }}
//           >
//             Copyright © 2023 Brand Liaison India Pvt Ltd.
//           </Typography>
//         </Stack>
//             </>

//         ) : (
//           <>
//             <Grid container spacing={1}>
//               <Grid item xs={12} md={3}>
//                 <Typography className="footer__title" variant="h5">
//                   Information
//                 </Typography>
//                 <ul className="footer__description">
//                   <li>
//                     <LocationOnOutlinedIcon
//                       sx={{ pe: 2 }}
//                       className="footer__description--icon"
//                     />
//                     <Typography>
//                       Brand Liaison India Pvt. Ltd.
//                       <br />
//                       110, Sharma Complex A-2, <br />
//                       Guru Nanak Pura, Laxmi Nagar
//                       <br />
//                       Delhi - 110092, India
//                     </Typography>
//                   </li>
//                   <li>
//                     <CallOutlinedIcon
//                       sx={{ pe: 2 }}
//                       className="footer__description--icon"
//                     />
//                     <Typography>+91-9810363988</Typography>
//                   </li>
//                   <li>
//                     <EmailOutlinedIcon
//                       sx={{ pe: 2 }}
//                       className="footer__description--icon"
//                     />
//                     <Typography>info@exportapproval.com</Typography>
//                   </li>
//                   <li>
//                     <AccessTimeOutlinedIcon
//                       sx={{ pe: 2 }}
//                       className="footer__description--icon"
//                     />
//                     <Typography>info@exportapproval.com</Typography>
//                   </li>
//                 </ul>
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <Typography className="footer__title" variant="h5">
//                   Services
//                 </Typography>
//                 <ul className="footer__description">
//                   <li>BIS/CRS Registration</li>
//                   <li>BIS/CRS Registration</li>
//                   <li>BIS/CRS Registration</li>
//                   <li>BIS/CRS Registration</li>
//                   <li>BIS/CRS Registration</li>
//                   <li>BIS/CRS Registration</li>
//                 </ul>
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <Typography className="footer__title" variant="h5">
//                   Join Us
//                 </Typography>
//                 <ul className="footer__description">
//                   <li onClick={toggleSidebar}>Business Associate</li>
//                   <li onClick={toggleSidebar}>Business Partner</li>

//                 </ul>
//               </Grid>
//               <Grid item xs={12} md={3}>
//                 <Typography className="footer__title" variant="h5">
//                   Quick Links
//                 </Typography>
//                 <Grid container spacing={0}>
//                   <Grid item xs={6} md={6}>
//                     <ul className="footer__description">
//                       <li>
//                         <Link to="/home">Home</Link>
//                       </li>
//                       <li>
//                         <Link to="/about">About Us</Link>
//                       </li>
//                       <li>
//                         <Link to="/download">Download</Link>
//                       </li>
//                       <li>
//                         <Link to="/blog">Blog</Link>
//                       </li>
//                       <li>
//                         <Link to="/gallery">Gallery</Link>
//                       </li>
//                       <li>
//                         <Link to="/notification">Notification</Link>
//                       </li>
//                     </ul>
//                   </Grid>
//                   <Grid item xs={6} md={6}>
//                     <ul className="footer__description">
//                       <li>
//                         <Link to="/calendar">Calendar</Link>
//                       </li>
//                       <li>
//                         <Link to="/knowledgebase">KnowledgeBase</Link>
//                       </li>
//                       <li>
//                         <Link to="/contact"> Contact Us</Link>
//                       </li>
//                       <li>
//                         <Link to="/careers"> Careers</Link>
//                       </li>
//                       <li>
//                         <Link to="/services"> Services</Link>
//                       </li>
//                     </ul>
//                   </Grid>
//                 </Grid>
//               </Grid>
//             </Grid>
//             <Divider sx={{ backgroundColor: "white", marginBlock: 3 }} />
//             <Grid
//               container
//               spacing={2}
//               sx={{
//                 display: "flex",
//                 justifyContent:"space-between",
//                 alignItems: "center",
//               }}
//             >
//               <Grid item xs={12} md={6}>
//                 <img src={logowhite} alt="Logo-white" loading="lazy" />
//               </Grid>
//               <Grid
//               sx={{ display: "flex", justifyContent: "flex-end" }}
//             item
//             className="footer__right"
//             xs={12}
//             md={6}
//           >
//          <SocialMedia/>
//           </Grid>
//             </Grid>
//             <Grid
//               container
//               spacing={2}
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//               }}
//             >
//               <Grid item className="footer__right" xs={12} md={6}>
//                 <img
//                   src={bisisi}
//                   className="footer__right--certificate-icon"
//                   alt="Linkend"
//                   loading="lazy"
//                 />
//                 <img
//                   src={bisisi}
//                   className="footer__right--certificate-icon"
//                   alt="Linkend"
//                   loading="lazy"
//                 />
//                 <img
//                   src={bisisi}
//                   className="footer__right--certificate-icon"
//                   alt="Logo-white"
//                   loading="lazy"
//                 />
//                 <img
//                   src={bisisi}
//                   className="footer__right--certificate-icon"
//                   alt="Linkend"
//                   loading="lazy"
//                 />
//               </Grid>
//               <Grid
//                 item
//                 className="footer__right"
//                 xs={12}
//                 md={6}
//                 sx={{ display: "flex", justifyContent: "flex-end" }}
//               >
//                 <img
//                   src={bisisi}
//                   className="footer__right--certificate-icon"
//                   alt="Linkend"
//                   loading="lazy"
//                 />
//                 <img
//                   src={bisisi}
//                   className="footer__right--certificate-icon"
//                   alt="Linkend"
//                   loading="lazy"
//                 />
//                 <img
//                   src={bisisi}
//                   className="footer__right--certificate-icon"
//                   alt="Logo-white"
//                   loading="lazy"
//                 />
//                 <img
//                   src={bisisi}
//                   className="footer__right--certificate-icon"
//                   alt="Linkend"
//                   loading="lazy"
//                 />
//               </Grid>
//             </Grid>
//             <Stack>
//               <Typography
//                 variant="caption"
//                 sx={{ textAlign: "center", paddingTop: 2 }}
//               >
//                 Copyright © 2023 Brand Liaison India Pvt Ltd.
//               </Typography>
//             </Stack>
//           </>
//         )}
//       </Box>
//     </>
//   );
// };

// export default Footer;

import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import { IconButton, Stack, Typography, useMediaQuery } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import SidebarContext from "../SidebarContext";
import Sidebar from "../Sidebar/Sidebar";
import SocialMedia from "../SocialMedia/SocialMedia";
import logowhite from "../../Assets/Images/logo-white.svg";
import bisisi from "../../Assets/Images/bis-isi.webp";
import { Link } from "react-router-dom";
import "./Footer.scss";
import useFetchData from "../../api/UserFetchData";

const Footer = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const { setActive } = useContext(SidebarContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // const {
  //   data: socialMediaData,
  //   loading: socialMediaLoading,
  //   error: socialMediaError,
  // } = useFetchData("/api/frontend/v1/footer/social-media");
  const {
    data: associatesData,
    loading: associatesLoading,
    error: associatesError,
  } = useFetchData("/api/frontend/v1/footer/associate");
  const {
    data: companyInfoData,
    loading: companyInfoLoading,
    error: companyInfoError,
  } = useFetchData("/api/frontend/v1/footer/company-information");
  const {
    data: servicesData,
    loading: servicesLoading,
    error: servicesError,
  } = useFetchData("/api/frontend/v1/footer/service");
  const {
    data: certificatesData,
    loading: certificatesLoading,
    error: certificatesError,
  } = useFetchData("/api/frontend/v1/footer/certificates");

  const toggleSidebar = () => {
    setActive("Partner");
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (
    // socialMediaLoading ||
    associatesLoading ||
    companyInfoLoading ||
    servicesLoading ||
    certificatesLoading
  ) {
    return <div>Loading...</div>;
  }

  if (
    // socialMediaError ||
    associatesError ||
    companyInfoError ||
    servicesError ||
    certificatesError
  ) {
    return <div>Error loading data</div>;
  }

  return (
    <>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <Box
        variant="section"
        className="footer section-inline"
        sx={{ flexGrow: 1 }}
      >
        {isMobile ? (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                color="secondary"
              >
                <Typography variant="body2">Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="footer__description">
                  <li>
                    <LocationOnOutlinedIcon
                      sx={{ pe: 2 }}
                      className="footer__description--icon"
                    />
                    <Typography>
                      Brand Liaison India Pvt. Ltd.
                      <br />
                      110, Sharma Complex A-2,
                      <br />
                      Guru Nanak Pura, Laxmi Nagar
                      <br />
                      Delhi - 110092, India
                    </Typography>
                  </li>
                  <li>
                    <CallOutlinedIcon
                      sx={{ pe: 2 }}
                      className="footer__description--icon"
                    />
                    <Typography>+91-9810363988</Typography>
                  </li>
                  <li>
                    <EmailOutlinedIcon
                      sx={{ pe: 2 }}
                      className="footer__description--icon"
                    />
                    <Typography>info@exportapproval.com</Typography>
                  </li>
                  <li>
                    <AccessTimeOutlinedIcon
                      sx={{ pe: 2 }}
                      className="footer__description--icon"
                    />
                    <Typography>info@exportapproval.com</Typography>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography variant="body2">Services</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="footer__description">
                  {servicesData.map((service, index) => (
                    <li key={index}>{service.service_name}</li>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography variant="body2">Join Us</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="footer__description">
                  {associatesData.map((associate, index) => (
                    <li key={index}>{associate.name}</li>
                  ))}
                </ul>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography variant="body2">Quick Links</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul className="footer__description">
                  <li>
                    <Link to="/home">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li>
                    <Link to="/download">Download</Link>
                  </li>
                  <li>
                    <Link to="/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/media">Media Coverage</Link>
                  </li>
                  <li>
                    <Link to="/Notification">Notification</Link>
                  </li>
                </ul>
              </AccordionDetails>
              <AccordionDetails>
                <ul className="footer__description">
                  <li>
                    <Link to="/calendar">Calendar</Link>
                  </li>
                  <li>
                    <Link to="/knowledge">Help Center</Link>
                  </li>
                  <li>
                    <Link to="/Contact"> Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/Careers"> Careers</Link>
                  </li>
                  <li>
                    <Link to="/Services"> Services</Link>
                  </li>
                </ul>
              </AccordionDetails>
            </Accordion>
            <Divider sx={{ backgroundColor: "white", marginBlock: 3 }} />
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid
                item
                className="footer__right"
                xs={12}
                md={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {certificatesData.map((certificate, index) => (
                  <img
                    key={index}
                    src={certificate.image}
                    className="footer__right--certificate-icon"
                    alt={certificate.name}
                    loading="lazy"
                  />
                ))}
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBlock: 3,
                }}
              >
                <img src={logowhite} alt="Logo-white" loading="lazy" />
              </Grid>
            </Grid>
            <Grid
              item
              className="footer__right"
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                variant="h6"
                component="div"
                className="topbar"
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="social-media"
                  onClick={toggleSidebar}
                >
                  <ShareOutlinedIcon />
                </IconButton>
                  <SocialMedia  />
              
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "center", marginBlock: 3 }}
            >
              {certificatesData.map((certificate, index) => (
                  <img
                    key={index}
                    src={certificate.certificates_img_url}
                    className="footer__right--certificate-icon"
                    alt={certificate.certificates_name}
                    loading="lazy"
                  />
                ))}
            </Grid>
            <Grid
              item
              className="footer__right"
              xs={12}
              md={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                variant="h6"
                component="div"
                className="topbar"
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <IconButton color="primary" aria-label="facebook">
                  <FacebookIcon />
                </IconButton>
                <IconButton color="primary" aria-label="twitter">
                  <TwitterIcon />
                </IconButton>
                <IconButton color="primary" aria-label="linkedin">
                  <LinkedInIcon />
                </IconButton>
                <IconButton color="primary" aria-label="youtube">
                  <YouTubeIcon />
                </IconButton>
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={3}>
              <Typography className="footer__title" variant="h5">
              {companyInfoData.page_tag_line}
                </Typography>
              <ul className="footer__description">
               <li>
                  <LocationOnOutlinedIcon sx={{ pe: 2 }} className="footer__description--icon" />
                  <Typography>{companyInfoData.company_address}</Typography>
                </li>
                <li>
                  <CallOutlinedIcon sx={{ pe: 2 }} className="footer__description--icon" />
                  <Typography>{companyInfoData.mobile_number}</Typography>
                  
                </li>
                <li>
                  <EmailOutlinedIcon sx={{ pe: 2 }} className="footer__description--icon" />
                  <Typography>{companyInfoData.company_email}</Typography>
                </li>
                <li>
                  <AccessTimeOutlinedIcon sx={{ pe: 2 }} className="footer__description--icon" />
                  <Typography>{companyInfoData.company_email}</Typography>
                </li>
              </ul>
              </Grid>
              <Grid item xs={3}>
              <Typography className="footer__title" variant="h5">
              Services
                </Typography>
                <ul className="footer__description">
                  {servicesData.slice(0, 6).map((service, index) => (
                    <li key={index}>{service.service_name}</li>
                  ))}
                </ul>
                {/* <ul className="footer__description">
                  {servicesData.map((service, index) => (
                    <li key={index}>{service.service_name}</li>
                  ))}
                </ul> */}
              </Grid>
              <Grid item xs={3}>
                <Typography className="footer__title" variant="h5">
                  Join Us
                </Typography>
                <ul className="footer__description">
                  <li onClick={toggleSidebar}>Business Associate</li>
                  <li onClick={toggleSidebar}>Business Partner</li>
                </ul>
              </Grid>
                           <Grid item xs={3}>
                <Typography className="footer__title" variant="h5">
                  Quick Links
                </Typography>
                <Grid container spacing={0}>
                  <Grid item xs={6} md={6}>
                    <ul className="footer__description">
                      <li>
                        <Link to="/home">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/download">Download</Link>
                      </li>
                      <li>
                        <Link to="/blog">Blog</Link>
                      </li>
                      <li>
                        <Link to="/media">Media Coverage</Link>
                      </li>
                      <li>
                        <Link to="/Notification">Notification</Link>
                      </li>
                    </ul>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <ul className="footer__description">
                      <li>
                        <Link to="/calendar">Calendar</Link>
                      </li>
                      <li>
                        <Link to="/knowledge">Help Center</Link>
                      </li>
                      <li>
                        <Link to="/Contact"> Contact Us</Link>
                      </li>
                      <li>
                        <Link to="/Careers"> Careers</Link>
                      </li>
                      <li>
                        <Link to="/Services"> Services</Link>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider sx={{ backgroundColor: "white", marginBlock: 3 }} />
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  marginBlock: 3,
                }}
              >
                <img src={logowhite} alt="Logo-white" loading="lazy" />
              </Grid>
              <Grid
                item
                className="footer__right"
                xs={12}
                md={6}
                sx={{ display: "flex", }}
              >
                <Typography
                  variant="h6"
                  component="div"
                  className="topbar"
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 2,
                  }}
                >
                   <SocialMedia  />
                </Typography>
              </Grid>
              
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid
                item
                className="footer__right"
                xs={12}
                md={6}
                sx={{ display: "flex"}}
              >
                {certificatesData.map((certificate, index) => (
                  <img
                    key={index}
                    src={certificate.certificates_img_url}
                    className="footer__right--certificate-icon"
                    alt={certificate.certificates_name}
                    loading="lazy"
                  />
                ))}
              </Grid>
             
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",

                  // marginBlock: 3,
                }}
              >
                 {associatesData.map((associate, index) => (
                  <img
                    key={index}
                    src={associate.associate_img_url}
                    className="footer__right--certificate-icon"
                    alt={associate.associate_name}
                    loading="lazy"
                  />
                ))}
               
              </Grid>
              {/* <Grid item className="footer__right" xs={12} md={6} sx={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h6" component="div" className="topbar" sx={{ flexGrow: 1, display: "flex", alignItems: "center", gap: 2 }}>
                <IconButton color="primary" aria-label="facebook">
                  <FacebookIcon />
                </IconButton>
                <IconButton color="primary" aria-label="twitter">
                  <TwitterIcon />
                </IconButton>
                <IconButton color="primary" aria-label="linkedin">
                  <LinkedInIcon />
                </IconButton>
                <IconButton color="primary" aria-label="youtube">
                  <YouTubeIcon />
                </IconButton>
              </Typography>
            </Grid> */}
            </Grid>
         
          </>
        )}
           <Stack>
            <Typography
                 variant="caption"
                 sx={{ textAlign: "center", paddingTop: 2 ,color:"white"}}
                 className="cursor-pointer"
               >
                <a style={{color:"white"}}  href="/privacypolicy">Copyright © 2023 Brand Liaison India Pvt Ltd.</a> 
              </Typography>
             </Stack>
      </Box>
    </>
  );
};

export default Footer;
