import {React, useState} from 'react';
// import Grid from "@mui/material/Grid";
import { Box, CircularProgress, Typography} from '@mui/material';
import AboutUs from '../Components/AboutUs';
// import vision from "../Assets/Images/vision.jpg"
import "./AboutUsPage.scss"
import Testimonials from '../Components/Testimonials/Testimonials';
import ConnectUs from '../Components/ConnectUs';
import OurAssociates from '../Components/OurAssociates/OurAssociates';
import AboutUsVision from './AboutUsVision';
import AboutUsTeam from './AboutUsTeam';
import useFetchData from '../api/UserFetchData';
// import UserFetchData from "../api/UserFetchData";


const AboutUsPage = () => {
  const [selectedValue, setSelectedValue] = useState('');
  const handleChange = (event) => {
      setSelectedValue(event.target.value);
  };
  const { data, loading, error } = useFetchData('/api/frontend/v1/about');
  if (loading) {
    return <CircularProgress/>;
  }
  if (error) {
    return <div>Error:{error}</div>;
  }

  return (
    <Box className='AboutUsPage'>
      <Typography variant="h4" className='AboutUsPage__title' textAlign={'center'} position={'relative'} m={3}>About Us 
      {data.title}
      </Typography>
      <AboutUs backgroundColor="none"/>
     <AboutUsVision/>
     <AboutUsTeam/>
        <Testimonials title="Founder’s  Voice" showSection={false} url='/api/frontend/v1/home/section/testimonials'/>
<ConnectUs/>
<OurAssociates title="Our Clients" backgroundColor="none"/>
    </Box>
  )
}

export default AboutUsPage