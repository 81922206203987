// import { React, useState } from 'react';
// import { Divider, Typography, Box, Button, Grid } from '@mui/material';
// import TextField from '@mui/material/TextField';
// import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

// const DownloadBrochure = () => {
//     const [selectedValue, setSelectedValue] = useState('');
//     const handleChange = (event) => {
//         setSelectedValue(event.target.value);
//     };
//     return (
//         <>
//             <Typography variant="h4" component="h4" pt={2} sx={{ color: '#0D629A' }}>
//                 Download Brochure
//             </Typography>
//             <Typography variant="caption" >Enter the user detail here</Typography>
//             <Divider className='divider-component' sx={{ borderBottomWidth: 'medium', backgroundColor: '#1C7CBC', my: 1 }} />
//             <Box
//                 sx={{
//                     width: 500,
//                     maxWidth: '100%',
//                     pt: 2
//                 }}

//             >
//                 <Typography variant="subtitle1" pb={1}>User Name</Typography>
//                 <TextField fullWidth label="Enter the user name" id="fullWidth" />
//                 <Typography variant="subtitle1" pb={1}>Organization Name</Typography>
//                 <TextField fullWidth label="Enter the Organization Name" id="fullWidth" />
//                 <Typography variant="subtitle1" pb={1}>Email-id</Typography>
//                 <TextField fullWidth label="name@company.com" type="email" id="fullWidth" />
//                 <Grid item xs={12} >
//                 <Typography variant="subtitle1" pb={1}>Mobile No</Typography>
//                 <Grid container spacing={1}>
//               <Grid item xs={12} md={3}>
//                 <FormControl fullWidth>
//                   <InputLabel id="country-code-label">+91</InputLabel>
//                   <Select
//                     labelId="country-code-label"
//                     label="+91"
//                     name="countryCode"
//                     value="countryCode"
//                     onChange={handleChange}
//                   >
//                     <MenuItem value="+91">+91</MenuItem>
//                     <MenuItem value="+001">+001</MenuItem>
//                     <MenuItem value="+002">+002</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} md={9}>
//                 <TextField
//                   fullWidth
//                   label="Mobile Number"
//                   // name="mobileNumber"
//                   // value="mobileNumber"
//                   onChange={handleChange}
//                 />
//               </Grid>
//             </Grid>

//                              </Grid>
//                 <Typography variant="subtitle1" pb={1}>Services</Typography>
//                 <FormControl fullWidth>
//                     <InputLabel id="demo-simple-select-label">Select Services</InputLabel>
//                     <Select
//                         labelId="demo-simple-select-label"
//                         id="demo-simple-select"
//                         value={selectedValue}
//                         onChange={handleChange}
//                     >
//                         <MenuItem value={'option1'}>Select Services 1</MenuItem>
//                         <MenuItem value={'option2'}>Select Services 2</MenuItem>
//                         <MenuItem value={'option3'}>Select Services 3</MenuItem>
//                     </Select>
//                 </FormControl>
//                 <Typography variant="subtitle1" pb={1}>Find Us</Typography>
//                 <FormControl fullWidth>
//                     <InputLabel id="demo-simple-select-label">How did you find us</InputLabel>
//                     <Select
//                         labelId="demo-simple-select-label"
//                         id="demo-simple-select"
//                         value={selectedValue}
//                         onChange={handleChange}
//                     >
//                         <MenuItem value={'option1'}>Select Services 1</MenuItem>
//                         <MenuItem value={'option2'}>Select Services 2</MenuItem>
//                         <MenuItem value={'option3'}>Select Services 3</MenuItem>
//                     </Select>
//                 </FormControl>
//                 <Typography variant="subtitle1" pb={1}>Your Message</Typography>
//                 <TextField
//                     id="outlined-textarea"
//                     label="Multiline Placeholder"
//                     placeholder="Placeholder"
//                     multiline
//                     fullWidth
//                 />
//                 <Box sx={{ pt: 3 }}>
//                     <Button variant="contained" size="large" color='primary' fullWidth>Download</Button>
//                 </Box>

//             </Box>
//         </>
//     )
// }

// export default DownloadBrochure

import React, { useState, useEffect } from "react";
import { Divider, Typography, Box, Button, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const DownloadBrochure = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [services, setServices] = useState([]);
  const [sources, setSources] = useState([]);
  const [formData, setFormData] = useState({
    userName: "",
    organizationName: "",
    email: "",
    countryCode: "",
    mobileNumber: "",
    service: "",
    source: "",
    message: "",
  });

  useEffect(() => {
    const fetchCountryCodes = async () => {
      const response = await fetch("/api/frontend/v1/brochures/country");
      const data = await response.json();
      setCountryCodes(data);
    };

    const fetchServices = async () => {
      const response = await fetch("/api/frontend/v1/brochures/service");
      const data = await response.json();
      setServices(data);
    };

    const fetchSources = async () => {
      const response = await fetch("/api/frontend/v1/brochures/sources");
      const data = await response.json();
      setSources(data);
    };

    fetchCountryCodes();
    fetchServices();
    fetchSources();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const response = await fetch("/api/frontend/v1/brochures/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const result = await response.json();
    console.log(result);
  };

  return (
    <>
      <Typography variant="h4" component="h4" pt={2} sx={{ color: "#0D629A" }}>
        Download Brochure
      </Typography>
      <Typography variant="caption">Enter the user detail here</Typography>
      <Divider
        className="divider-component"
        sx={{ borderBottomWidth: "medium", backgroundColor: "#1C7CBC", my: 1 }}
      />
      <Box
        sx={{
          width: 500,
          maxWidth: "100%",
          pt: 2,
        }}
      >
        <Typography variant="subtitle1" pb={1}>
          User Name
        </Typography>
        <TextField
          fullWidth
          label="Enter the user name"
          name="userName"
          value={formData.userName}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}

        />

        <Typography variant="subtitle1" pb={1}>
          Organization Name
        </Typography>
        <TextField
          fullWidth
          label="Enter the Organization Name"
          name="organizationName"
          value={formData.organizationName}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}

        />
        <Typography variant="subtitle1" pb={1}>
          Email-id
        </Typography>
        <TextField
          fullWidth
          label="name@company.com"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}

        />
        <Grid item xs={12}>
          <Typography variant="subtitle1" pb={1}>
            Mobile No
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id="country-code-label">Country Code</InputLabel>
                <Select
                  labelId="country-code-label"
                  label="Country Code"
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={handleChange}
                  sx={{ marginBottom: 2 }}

                >
                  {countryCodes.map((code) => (
                    <MenuItem key={code} value={code}>
                      {code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={9}>
              <TextField
                fullWidth
                label="Mobile Number"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                sx={{ marginBottom: 2 }}

              />
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" pb={1}>
          Services
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="service-select-label">Select Services</InputLabel>
          <Select
            labelId="service-select-label"
            name="service"
            value={formData.service}
            onChange={handleChange}
            sx={{ marginBottom: 2 }}

          >
            {services.map((service) => (
              <MenuItem key={service} value={service}>
                {service}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="subtitle1" pb={1}>
          Find Us
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="source-select-label">How did you find us</InputLabel>
          <Select
            labelId="source-select-label"
            name="source"
            value={formData.source}
            onChange={handleChange}
            sx={{ marginBottom: 2 }}

          >
            {sources.map((source) => (
              <MenuItem key={source} value={source}>
                {source}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography variant="subtitle1" pb={1}>
          Your Message
        </Typography>
        <TextField
          id="outlined-textarea"
          label="Multiline Placeholder"
          placeholder="Placeholder"
          multiline
          fullWidth
          name="message"
          value={formData.message}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}

        />
        <Box sx={{ pt: 3 }}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            sx={{ marginBottom: 2 }}

          >
            Download
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DownloadBrochure;
