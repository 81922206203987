import React, { useContext, useState } from 'react';
import SidebarContext from './SidebarContext';
import { Stack, Typography, Button, Grid, IconButton, Box, CircularProgress } from "@mui/material";
import { PermPhoneMsg } from '@mui/icons-material';
import "./ConnectUs.scss";
import Sidebar from './Sidebar/Sidebar';
import UserFetchData from "../api/UserFetchData";


const ConnectUs = () => {
  const { setActive } = useContext(SidebarContext); 
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setActive("Callback");

  };
  const { data, loading, error } = UserFetchData('api/frontend/v1/contact-us');
  if (loading) {
    return <CircularProgress/>;
  }
  if (error) {
    return <div>Error:{error}</div>;
  }
  else{
    console.log('Response Data:', data);
  }
  return (
    <> 
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
       </Sidebar> 
      <Stack className="section-inline section-bg connectuspage" 
      >
        <Grid container sx={{alignItems:'center'}} textAlign={{xs:'center',sm:'center',md:'left'}} >
      
        <Grid item xs={12} sm={12} md={4} display={'flex'}alignItems={'center'} p={0} >
        <IconButton><PermPhoneMsg sx={{fontSize:'80px'}} color="primary" /></IconButton>
        <Box className="connectuspage_details">
        <Typography variant='caption' >
        {data.callmsg}Call for More Info </Typography>
        <Typography variant='h4' color='dark'>
        {data.phoneno} +91-8130615678 </Typography>
        <Typography variant='h4' color='dark' >
       {data.phoneno} +91-8130615678 </Typography></Box>     
          </Grid>
          <Grid item xs={12} sm={12} md={4} p={0} >
            <Typography variant='h4' textAlign={{xs:'center',sm:'center',md:'left'}}  >
            {data.desc}  Let’s Request a Schedule For Free Consultation  </Typography>
          </Grid>          
          <Grid item xs={12} sm={12} md={4} p={0} sx={{display:'flex',alignItems:'center',justifyContent:'center'}} >
            <Button variant='contained'
            onClick={toggleSidebar} 
            >Connect With Us</Button>
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}

export default ConnectUs