import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import "./Calendar.scss";
// const holidays = {
//   '2024-06': [
//     { date: '2024-06-04', name: 'Holiday 1' },
//     { date: '2024-06-14', name: 'Holiday 2' }
//   ],
//   '2024-07': [
//     { date: '2024-07-04', name: 'Independence Day' }
//   ]
//   // Add more holidays as needed
// };

export default function Calendar() {
  // Initialize date values for current and previous months
  const currentMonth = dayjs();
  const previousMonth = dayjs().subtract(1, 'month');

  // States for the selected dates
  const [currentMonthValue, setCurrentMonthValue] = React.useState(currentMonth);
  const [previousMonthValue, setPreviousMonthValue] = React.useState(previousMonth);
  // const [selectedDate, setSelectedDate] = React.useState(new Date());
  // const [holidayList, setHolidayList] = React.useState([]);
  

  return (
    <Box className="AboutUsPage CareersPage">
      <Grid container  className="CareersPage__body">
        <Grid item xs={12} sm={12} md={4}>
          <Paper
            sx={{
              height: "74vh",
              width: "80%",
              backgroundColor: "lightskyblue",
              padding: 3,
              gap: 2,
              position:'relative'
            }}
            className="CareersPage__left">
            <Typography variant="h4" marginBottom={2}>
              Holiday List of Month{" "}
            </Typography>
            <Typography variant="h6" marginBottom={2}>
              Holiday - 1
            </Typography>
            <Typography variant="h6" marginBottom={2}>
              Holiday - 2
            </Typography>
            <Typography variant="h6" marginBottom={2}>
              Holiday - 3
            </Typography>
            <Typography variant="h6">Holiday - 4</Typography>
            <Button variant="contained" color="Greybg" sx={{position:"absolute",bottom:150 }}>
              Download Holiday List of Year
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Typography
            variant="h4"
            className="AboutUsPage__title CareersPage__title"
            textAlign={"center"}
            position={"relative"}
           >
            Calendar 2024{" "}
          </Typography>
          <Stack direction={{md:"row",sm:'column'}}marginTop={5}> 
            
            <Box sx={{width:'100%'}} marginBlock={{xs:1,sm:2,md:4,lg:5}} >
            {/* <List>
              {holidayList.length === 0 ? (
                <ListItem>
                  <ListItemText primary="No holidays this month" />
                </ListItem>
              ) : (
                holidayList.map((holiday, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={holiday.name} secondary={holiday.date} />
                  </ListItem>
                ))
              )}
            </List> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={previousMonthValue}
                  onChange={(newValue) => setPreviousMonthValue(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{width:'100%'}} marginBlock={{xs:1,sm:2,md:4,lg:5}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={currentMonthValue}
                  onChange={(newValue) => setCurrentMonthValue(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
