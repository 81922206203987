import { Box, Typography } from "@mui/material";
import React from "react";

const TermsPolicy = () => {
  return (
    <section>
      <Box textAlign={"center"}>
        <Typography
          variant="h4"
          className="AboutUsPage__title"
          textAlign={"center"}
          position={"relative"}
          m={3}
        >
          Terms & Conditions
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="h6" color="primary.main">
          Registration
        </Typography>
        <Typography variant="p">
          Brand Liaison India Pvt. Ltd. is a well established firm and is the
          leading compliance and approval services providing company in India.
          This document covers the terms and conditions under which these
          services are provided.
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="p">
          Your use of any of the features and services on Brand Liaison as a
          visitor constitutes automatic acceptance of these terms and
          conditions.
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="p">
          While using the web site and engaged in any form of communication on
          any of the forums, you agree not to:
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="p">
          Post, publish or transmit any messages that is false, misleading,
          defamatory, harmful, threatening, abusive, harassing, defamatory,
          invades another's privacy, offensive, promotes racism, hatred or harm
          against any individual or group or religion or caste, infringes
          another's rights including any intellectual property rights or
          copyright or trademark, violates or encourages any conduct that would
          violate any applicable law or regulation or would give rise to civil
          liability. Access or search the Sites Content or Services with any
          engine, software or tool. Send unsolicited email, junk mail, spam, or
          chain letters, or promotions or advertisements for products or
          services. Reformat or frame any portion of the web pages that are part
          of Brand Liaison without a written agreement.
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="p">
          Brand Liaison reserves the right at any time and from time to time to
          modify or discontinue, temporarily or permanently, the Services with
          or without notice.
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="h6" color="primary.main">
          Ownership
        </Typography>
        <Typography variant="p">
          Except for the Content submitted by users, Brand Liaison Service and
          all aspects thereof, including all copyrights, trademarks, and other
          intellectual property or proprietary rights therein, is owned by Brand
          Liaison.
        </Typography>
      </Box>
    </section>
  );
};

export default TermsPolicy;




