// import { React, useState } from 'react';
// import { Box, Typography, List, ListItemIcon, Button, CircularProgress } from "@mui/material";
// import Grid from "@mui/material/Grid";
// import TextField from '@mui/material/TextField';
// import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// const ContactUsPageForm = () => {
//     const [selectedValue, setSelectedValue] = useState('');
//     const handleChange = (event) => {
//       setSelectedValue(event.target.value);
//     };
//   return (
// <>
// <Grid item xs={12} md={6}>
//             <Typography variant="h6" className='ContactUsPage__title '>Feel free to messege </Typography>
//             <Grid container spacing={3} mb={2}>
//               <Grid item xs={12} md={6}>
//                 <Typography variant="subtitle1" pb={1}>User Name</Typography>
//                 <TextField fullWidth label="Enter the user name" id="fullWidth" />
//               </Grid>

//               <Grid item xs={12} md={6}>
//                 <Typography variant="subtitle1" pb={1}>Organization Name</Typography>
//                 <TextField fullWidth label="Enter the Organization Name" id="fullWidth" />
//               </Grid>
//             </Grid>
//             <Grid container spacing={3} mb={2}>
//               <Grid item xs={12} md={6}>
//                 <Typography variant="subtitle1" pb={1}>Email-id</Typography>
//                 <TextField fullWidth label="name@company.com" type="email" id="fullWidth" />
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <Typography variant="subtitle1" pb={1}>Mobile No</Typography>
//                 <Grid container spacing={1}>
//               <Grid item xs={12} md={3}>
//                 <FormControl fullWidth>
//                   <InputLabel id="country-code-label">+91</InputLabel>
//                   <Select
//                     labelId="country-code-label"
//                     label="+91"
//                     name="countryCode"
//                     value="countryCode"
//                     onChange={handleChange}
//                   >
//                     <MenuItem value="+91">+91</MenuItem>
//                     <MenuItem value="+001">+001</MenuItem>
//                     <MenuItem value="+002">+002</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} md={9}>
//                 <TextField
//                   fullWidth
//                   label="Mobile Number"
//                   // name="mobileNumber"
//                   // value="mobileNumber"
//                   onChange={handleChange}
//                 />
//               </Grid>
//             </Grid>
                
//                              </Grid>
//             </Grid>
//             <Grid container spacing={3} mb={2}>
//               <Grid item xs={12} md={6}>
//                 <Typography variant="subtitle1" pb={1}>Services</Typography>
//                 <FormControl fullWidth>
//                   <InputLabel id="demo-simple-select-label">Select Services</InputLabel>
//                   <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     value={selectedValue}
//                     onChange={handleChange}
//                   >
//                     <MenuItem value={'option1'}>Select Services 1</MenuItem>
//                     <MenuItem value={'option2'}>Select Services 2</MenuItem>
//                     <MenuItem value={'option3'}>Select Services 3</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <Typography variant="subtitle1" pb={1}>Find Us</Typography>
//                 <FormControl fullWidth>
//                   <InputLabel id="demo-simple-select-label">How did you find us</InputLabel>
//                   <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     value={selectedValue}
//                     onChange={handleChange}
//                   >
//                     <MenuItem value={'option1'}>Select Services 1</MenuItem>
//                     <MenuItem value={'option2'}>Select Services 2</MenuItem>
//                     <MenuItem value={'option3'}>Select Services 3</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} md={12}>
//                 <Typography variant="subtitle1" pb={1}>Your Message</Typography>
//                 <TextField
//                   id="outlined-textarea"
//                   label="Multiline Placeholder"
//                   placeholder="Placeholder"
//                   multiline
//                   fullWidth
//                 />
//               </Grid>
//                  <Grid item xs={12} md={4}>
//                 <Button variant="contained" size="large" color='primary' fullWidth >Send Message</Button>
//                 </Grid>

//             </Grid>
//           </Grid>
// </>  )
// }

// export default ContactUsPageForm

import React, { useState } from 'react';
import { Box, Typography, List, ListItemIcon, Button, CircularProgress, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import useFetchData from '../api/UserFetchData';

const ContactUsPageForm = () => {
  const [formData, setFormData] = useState({
    userName: '',
    organizationName: '',
    email: '',
    mobileNumber: '',
    countryCode: '+91',
    service: '',
    findUs: '',
    message: ''
  });

  const { data, error, loading, fetchData } = useFetchData('/api/frontend/v1/contact-us/submit', {});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    fetchData(formData);
  };

  return (
    <Grid item xs={12} md={6}>
      <Typography variant="h6" className='ContactUsPage__title'>Feel free to message</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" pb={1}>User Name</Typography>
            <TextField
              fullWidth
              label="Enter the user name"
              name="userName"
              value={formData.userName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" pb={1}>Organization Name</Typography>
            <TextField
              fullWidth
              label="Enter the Organization Name"
              name="organizationName"
              value={formData.organizationName}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" pb={1}>Email-id</Typography>
            <TextField
              fullWidth
              label="name@company.com"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" pb={1}>Mobile No</Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="country-code-label">+91</InputLabel>
                  <Select
                    labelId="country-code-label"
                    label="+91"
                    name="countryCode"
                    value={formData.countryCode}
                    onChange={handleChange}
                  >
                    <MenuItem value="+91">+91</MenuItem>
                    <MenuItem value="+001">+001</MenuItem>
                    <MenuItem value="+002">+002</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" pb={1}>Services</Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select Services</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="service"
                value={formData.service}
                onChange={handleChange}
              >
                <MenuItem value={'option1'}>Select Services 1</MenuItem>
                <MenuItem value={'option2'}>Select Services 2</MenuItem>
                <MenuItem value={'option3'}>Select Services 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" pb={1}>Find Us</Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">How did you find us</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="findUs"
                value={formData.findUs}
                onChange={handleChange}
              >
                <MenuItem value={'option1'}>Select Services 1</MenuItem>
                <MenuItem value={'option2'}>Select Services 2</MenuItem>
                <MenuItem value={'option3'}>Select Services 3</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="subtitle1" pb={1}>Your Message</Typography>
            <TextField
              id="outlined-textarea"
              label="Multiline Placeholder"
              placeholder="Placeholder"
              multiline
              fullWidth
              name="message"
              value={formData.message}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button variant="contained" size="large" color='primary' fullWidth type="submit" disabled={loading}>
              {loading ? <CircularProgress size={24} /> : 'Send Message'}
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* {error && <Typography color="error">{error}</Typography>} */}
      {data && <Typography color="primary">Message sent successfully!</Typography>}
    </Grid>
  );
};

export default ContactUsPageForm;
