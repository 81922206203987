// import {React} from 'react';
// import Grid from "@mui/material/Grid";
// import { Typography ,Card ,CardContent ,CardMedia ,CardActionArea, CircularProgress} from '@mui/material';
// import vision from "../Assets/Images/vision.jpg"
//  import UserFetchData from "../api/UserFetchData";
// import "./AboutUsPage.scss"

// const AboutUsTeam = () => {
//       const { data, loading, error } = UserFetchData('api/frontend/v1/about/team-section');
//   if (loading) {
//     return <CircularProgress/>;
//   }
//   if (error) {
//     return <div>Error:{error}</div>;
//   }
//   return (
//     <>

//     <Typography variant="h4" className='AboutUsPage__title' textAlign={'center'} position={'relative'} m={3}> Dedicated Team {data.designation}</Typography>

//     <Grid item xs={12} sm={6} md={4}  lg={3} className='AboutUsPage__team' sx={{
//       marginInline:6
//     }}>
//   <Card sx={{marginRight:'1'}}>
//     <CardActionArea>
//       <CardMedia
//         component="img"
//         height="365"
//         image={vision}{...data.vision}
//         alt="card Image"
//         className='card-img'
//       />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//         Team Member-1 {data.subtitle1}
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//         Designation {data.subtitle2}
//         </Typography>
//       </CardContent>
//     </CardActionArea>
//   </Card>
//   <Card sx={{marginRight:'1'}}>
//     <CardActionArea>
//       <CardMedia
//         component="img"
//         height="365"
//         image={vision}
//         alt="card Image"
//         className='card-img'
//       />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//         Team Member-1
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//         Designation
//         </Typography>
//       </CardContent>
//     </CardActionArea>
//   </Card>
//   <Card sx={{marginRight:'1'}}>
//     <CardActionArea>
//       <CardMedia
//         component="img"
//         height="365"
//         image={vision}
//         alt="card Image"
//         className='card-img'
//       />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//         Team Member-1
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//         Designation
//         </Typography>
//       </CardContent>
//     </CardActionArea>
//   </Card>
//   <Card sx={{marginRight:'1'}}>
//     <CardActionArea>
//       <CardMedia
//         component="img"
//         height="365"
//         image={vision}
//         alt="card Image"
//         className='card-img'
//       />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//         Team Member-1
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//         Designation
//         </Typography>
//       </CardContent>
//     </CardActionArea>
//   </Card>
//   <Card sx={{marginRight:'1'}}>
//     <CardActionArea>
//       <CardMedia
//         component="img"
//         height="365"
//         image={vision}
//         alt="card Image"
//         className='card-img'
//       />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//         Team Member-1
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//         Designation
//         </Typography>
//       </CardContent>
//     </CardActionArea>
//   </Card>
//   <Card sx={{marginRight:'1'}}>
//     <CardActionArea>
//       <CardMedia
//         component="img"
//         height="365"
//         image={vision}
//         alt="card Image"
//         className='card-img'
//       />
//       <CardContent>
//         <Typography gutterBottom variant="h5" component="div">
//         Team Member-1
//         </Typography>
//         <Typography variant="body2" color="text.secondary">
//         Designation
//         </Typography>
//       </CardContent>
//     </CardActionArea>
//   </Card>         
//       </Grid>
//       </>
//   )
// }

// export default AboutUsTeam

import React from 'react';
import Grid from "@mui/material/Grid";
import { Typography ,Card ,CardContent ,CardMedia ,CardActionArea, CircularProgress} from '@mui/material';
import vision from "../Assets/Images/vision.jpg"
import useFetchData from "../api/UserFetchData";
import "./AboutUsPage.scss"

const AboutUsTeam = () => {
  const { data, loading, error } = useFetchData('/api/frontend/v1/about/team-section');
  
  if (loading) {
    return <CircularProgress/>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  
  return (
    <section>
      <Typography variant="h4" className='AboutUsPage__title' textAlign={'center'} position={'relative'} m={3}>Dedicated Team</Typography>

      <Grid container spacing={2} className='AboutUsPage__team'>
        {data.map((member, index) => (
          <Grid key={index} item xs={12} sm={6} md={2}  className='AboutUsPage__team__details'>
            <Card sx={{maxWidth:"250px"}}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="365"
                  image={member.profile_url} // You may want to use member.image here if each member has its own image
                  alt="card Image"
                  className='card-img'
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {member.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {member.designation}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </section>
  )
}

export default AboutUsTeam;
