import { React, useState } from 'react';
import { Box, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
// import TextField from '@mui/material/TextField';
// import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
// import { Call, Email, Place, Reviews } from '@mui/icons-material';
import GoogleMapComponent from './GoogleMapComponent';
import "./ContactUsPage.scss"
// import useFetchData from '../api/UserFetchData';
import ContactUSpageTextData from './ContactUSpageTextData';

const ContactUsPage = () => {
  const [selectedValue, setSelectedValue] = useState('');
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  // const { data, loading, error } = useFetchData('/api/frontend/v1/home/section/services');
  // if (loading) {
  //   return <CircularProgress/>;
  // }
  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  return (
    <>
      <Box className='AboutUsPage ContactUsPage section-inline' sx={{ flexGrow: 1}}>
        <Typography variant="h4" className='AboutUsPage__title' textAlign={'center'} position={'relative'} m={3}>Contact Us</Typography>
       <ContactUSpageTextData/>
             </Box>
      <Grid container spacing={5} className='ContactUsPage_getinTouch' sx={{
        marginTop: '2rem'
      }}>
        <GoogleMapComponent />
      </Grid>
    </>
  )
}

export default ContactUsPage