import { KeyboardDoubleArrowUp } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React, { useState } from 'react';

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisibility);

  return (
    <Box className="backtotop">
    <Button
      onClick={scrollToTop}
      style={{ display: isVisible ? 'block' : 'none' }}
      color="primary"
      aria-label="scroll to top">     
      <KeyboardDoubleArrowUp />
    </Button>
    </Box>
  );
};

export default BackToTopButton;
