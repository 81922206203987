// import React, { useEffect, useState } from "react";
// import Grid from "@mui/material/Grid";
// import img from "../Assets/Images/about.jpg";
// import since2014 from "../Assets/Images/since2014.png"
// import { Stack, Typography, Box, Chip, CircularProgress } from "@mui/material";
// import Button from "@mui/material/Button";
// import { useNavigate } from "react-router-dom";
// import UserFetchData from "../api/UserFetchData";
// import "./AboutUs.scss";

// const AboutUs = ({ backgroundColor }) => {
//   const navigate = useNavigate();

//   const handleClick = () => {
//     navigate(`/about`);
//   };
//   const {  data: aboutData, loading:aboutLoading, error:aboutError } = UserFetchData(
//     "api/frontend/v1/home/sections/home-about-section"
//   );
//   const {
//     data: headingData,
//     loading: headingLoading,
//     error: headingError,
//   } = UserFetchData("/api/frontend/v1/home/sections/home-about-section");
//   const [heading, setHeading] = useState({
//     tagline: "",
//     description: "",
//     content:""
//   });
//   useEffect(() => {
//     if (headingData) {
//       const tagline = JSON.parse(headingData.section_tagline);
//       const description = JSON.parse(headingData.section_description); 
//       const content = JSON.parse(headingData.section_content);    
   

//       setHeading({
//               tagline: tagline.blocks[0].data.text,
//               description: description.blocks[0].data.text,
//               content: description.blocks[0].data.text,
              
//             });
//             console.log(tagline,"Aboutus tagline");

//     }
//   }, [headingData]);

//   if (aboutLoading || headingLoading) {
//     return <CircularProgress />;
//   }

//   if (aboutError || headingError) {
//     return <div>Error: {aboutError || headingError}</div>;
//   }
  
//   const styles = {
//     backgroundColor: backgroundColor === "none" ? "transparent" : "#C3E7FF",
//   };

//   return (
//     <>
//       <Box style={styles} className="AboutUs section-full">
//         <Grid container>
//           <Grid item xs={12} md={5} >
//             <Box position={"relative"}>
//             <img
//               className="AboutUs__image"
//               src={headingData.section_img_url}
//               alt={headingData.section_img_alt}
//               loading="lazy"
//             />
//             {/* <img
//               className="AboutUs__image"
//               src={img}
//               alt="about"
//               loading="lazy"
//             /> */}

//              <img
//               className="AboutUs__image_since2014"
//               src={since2014}
//               alt="since 2016"
//               loading="lazy"
//               position="absolute"
//             />
//             </Box>
//           </Grid>
//           <Grid item xs={12} md={7}>
//             <Stack
//               marginTop={{ xs: 1, sm: 2, md: 3 }}
//               sx={{ textAlign: "left" }}
//             >
//               <Chip
//                 label={heading.tagline} 
//                 variant="span"
//                 color="primary"
//                 sx={{ width: "35%" }} />
           
//               <Typography marginBottom={{ xs: 1, sm: 2, md: 3 }} variant="h3"
//               width={{ xs: "70%", sm: "60%", md: "50%" }}
//               >
//               {heading.description} 
//                 <br />
//               </Typography>

//               <Typography variant="caption">
//                 {heading.content}
                
//               </Typography>
//               <Typography variant="subtitle2" mt={1}>
//                 {heading.content} 
//               </Typography>
//             </Stack>
//             <Stack
//               spacing={2}
//               mt={1}
//               direction={{ xs: " column", sm: " column", md: "row" }}
//             >
//               <item>
//                 <Typography variant="h6">
//                   {" "}
//                   Get Cost Effective Solution
//                 </Typography>
//                 <Typography variant="caption">
//                   Tempus nec vitae orci sodales metus velit nec at diam in sed.
//                   Massa dui ipsum ornare sagittis dolor fusce.
//                 </Typography>
//               </item>
//               <item>
//                 <Typography variant="h6">
//                   {" "}
//                   Get Cost Effective Solution
//                 </Typography>
//                 <Typography variant="caption">
//                   Tempus nec vitae orci sodales metus velit nec at diam in sed.
//                   Massa dui ipsum ornare sagittis dolor fusce.
//                 </Typography>
//               </item>
//             </Stack>
//             <Stack
//               spacing={2}
//               mt={1}
//               direction={{ xs: " column", sm: " column", md: "row" }}
//             >
//               <item>
//                 <Typography variant="h6">
//                   {" "}
//                   Get Cost Effective Solution
//                 </Typography>
//                 <Typography variant="caption">
//                   Tempus nec vitae orci sodales metus velit nec at diam in sed.
//                   Massa dui ipsum ornare sagittis dolor fusce.
//                 </Typography>
//               </item>
//               <item>
//                 <Typography variant="h6">
//                   {" "}
//                   Get Cost Effective Solution
//                 </Typography>
//                 <Typography variant="caption">
//                   Tempus nec vitae orci sodales metus velit nec at diam in sed.
//                   Massa dui ipsum ornare sagittis dolor fusce.
//                 </Typography>
//               </item>
//             </Stack>
//             <Stack direction="row" mt={3}>
//               <Button variant="contained" onClick={handleClick}>
//                 Learn More
//               </Button>
//             </Stack>
//           </Grid>
//         </Grid>
//       </Box>
//     </>
//   );
// };

// export default AboutUs;


import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Stack, Typography, Box, Chip, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import UserFetchData from "../api/UserFetchData";
import since2014 from "../Assets/Images/since2014.png";
import "./AboutUs.scss";

const AboutUs = ({ backgroundColor }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/about`);
  };

  const { data: aboutData, loading: aboutLoading, error: aboutError } = UserFetchData(
    "api/frontend/v1/home/sections/home-about-section"
  );

  const { data: headingData, loading: headingLoading, error: headingError } = UserFetchData(
    "/api/frontend/v1/home/sections/home-about-section"
  );

  const [heading, setHeading] = useState({
    tagline: "",
    description: "",
    content: ""
  });

  useEffect(() => {
    if (headingData) {
      const tagline = JSON.parse(headingData.section_tagline);
      const description = JSON.parse(headingData.section_description);
      const content = JSON.parse(headingData.section_content);

      setHeading({
        tagline: tagline.blocks[0].data.text,
        description: description.blocks[0].data.text,
        content: content.blocks[0].data.text
      });
    }
  }, [headingData]);

  if (aboutLoading || headingLoading) {
    return <CircularProgress />;
  }

  if (aboutError || headingError) {
    return <div>Error: {aboutError || headingError}</div>;
  }

  const styles = {
    backgroundColor: backgroundColor === "none" ? "transparent" : "#C3E7FF",
  };

  return (
    <Box style={styles} className="AboutUs section-full">
      <Grid container>
        <Grid item xs={12} md={5}>
          <Box position="relative">
            <img
              className="AboutUs__image"
              src={headingData.section_img_url}
              alt={headingData.section_img_alt}
              loading="lazy"
            />
            <img
              className="AboutUs__image_since2014"
              src={since2014}
              alt="since 2016"
              loading="lazy"
              style={{ position: "absolute" }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack marginTop={{ xs: 1, sm: 2, md: 3 }} sx={{ textAlign: "left" }}>
            <Chip label={heading.tagline} variant="outlined" color="primary" sx={{ width: "35%" }} />
            <Typography marginBottom={{ xs: 1, sm: 2, md: 3 }} variant="h3" width={{ xs: "100%", sm: "100%", md: "75%" }}>
              <span dangerouslySetInnerHTML={{ __html: heading.description }} />
            </Typography>
            <Typography variant="caption">
              <span dangerouslySetInnerHTML={{ __html: heading.content }} />
            </Typography>
            <Typography variant="subtitle2" mt={1}>
              <span dangerouslySetInnerHTML={{ __html: heading.content }} />
            </Typography>
          </Stack>
          <Stack spacing={2} mt={1} direction={{ xs: "column", sm: "column", md: "row" }}>
            <Box>
              <Typography variant="h6">Get Cost Effective Solution</Typography>
              <Typography variant="caption">
                Tempus nec vitae orci sodales metus velit nec at diam in sed. Massa dui ipsum ornare sagittis dolor fusce.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">Get Cost Effective Solution</Typography>
              <Typography variant="caption">
                Tempus nec vitae orci sodales metus velit nec at diam in sed. Massa dui ipsum ornare sagittis dolor fusce.
              </Typography>
            </Box>
          </Stack>
          <Stack spacing={2} mt={1} direction={{ xs: "column", sm: "column", md: "row" }}>
            <Box>
              <Typography variant="h6">Get Cost Effective Solution</Typography>
              <Typography variant="caption">
                Tempus nec vitae orci sodales metus velit nec at diam in sed. Massa dui ipsum ornare sagittis dolor fusce.
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6">Get Cost Effective Solution</Typography>
              <Typography variant="caption">
                Tempus nec vitae orci sodales metus velit nec at diam in sed. Massa dui ipsum ornare sagittis dolor fusce.
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" mt={3}>
            <Button variant="contained" onClick={handleClick}>
              Learn More
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;


