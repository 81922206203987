/************************Integrated API CODE**************************** */

// import { ArrowDropDown } from '@mui/icons-material';
// import { CircularProgress, ListItemButton, Menu, MenuItem } from '@mui/material';
// import React, { useState } from 'react';
// import useFetchData from '../../api/UserFetchData';

// const LanguageList = () => {
//   const [anchorEl, setAnchorEl] = useState(null);
//   const open = Boolean(anchorEl);

//   const { data: languages, loading, error } = useFetchData('/api/frontend/v1/language');

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   if (loading) {
//         return <CircularProgress />;
//       }
//       if (error) {
//         return <div>Error:{error}</div>;
//       }

//   return (
//     <ListItemButton
//       id="basic-button"
//       aria-controls={open ? 'basic-menu' : undefined}
//       aria-haspopup="true"
//       aria-expanded={open ? 'true' : undefined}
//       onClick={handleClick}
//       className="menu-items"
//       sx={{ textAlign: 'left', backgroundColor: 'primary', textDecoration: 'none', color: 'white', fontWeight: '600' }}
//     >
//       Language <ArrowDropDown />
//       <Menu
//         id="basic-menu"
//         anchorEl={anchorEl}
//         open={open}
//         onClose={handleClose}
//         MenuListProps={{
//           'aria-labelledby': 'basic-button',
//         }}
//       >
//         {languages.map((lang) => (
//           <MenuItem key={lang.id} onClick={handleClose}>
//             {lang.language}
//           </MenuItem>
//         ))}
//       </Menu>
//     </ListItemButton>
//   );
// };

// export default LanguageList;

/************************Integrated API CODE**************************** */


import { ArrowDropDown } from '@mui/icons-material';
import { ListItemButton, Menu, MenuItem, ClickAwayListener } from '@mui/material';
import React, { useState } from 'react';

// Language data array
export const language = [
  { id: 1, language: 'hindi' },
  { id: 2, language: 'English' },
  { id: 3, language: 'Marathi' },
  { id: 4, language: 'Bangali' },
  { id: 5, language: 'Telegu' },
  { id: 6, language: 'Tamil' },
  { id: 7, language: 'Kanada' },
  { id: 8, language: 'Malayalam' },
  { id: 9, language: 'Gujarati' },
  { id: 10, language: 'Punjabi' },
];
export const glanguage = [
  { id: 1, language: 'English' },
  { id: 2, language: 'French' },
  { id: 3, language: 'Spanish' },
  { id: 4, language: 'Italian' },
  { id: 5, language: 'Chinese' },
  { id: 6, language: 'Deutsch' },
  { id: 7, language: 'Arabic' },
  { id: 8, language: 'Japanese' },
  { id: 9, language: 'Korean' },
  { id: 10, language: 'Russian' },
  { id: 11, language: 'Malay' },
  { id: 12, language: 'Portuguese' },
  { id: 13, language: 'Vietnamese' },
  { id: 14, language: 'Thai' },
  { id: 15, language: 'Bahasha' },
  { id: 16, language: 'German' },
  { id: 17, language: 'Polish' },
];
const LanguageList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <ListItemButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          className="menu-items"
          sx={{ textAlign: 'left', backgroundColor: 'primary', textDecoration: 'none', color: 'white', fontWeight: '600' }}
        >
          Language <ArrowDropDown />
        </ListItemButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {language.map((lang) => (
            <MenuItem key={lang.id} onClick={handleClose}>
              {lang.language}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </ClickAwayListener>
  );
};

export default LanguageList;

