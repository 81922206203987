// import { React } from 'react';
// import { Divider, Typography, Box, Button, Grid, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
// import TextField from '@mui/material/TextField';
// import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// const Callback = () => {
//     // const [selectedDate, handleDateChange] = useState(new Date());

//     return (

//         <>
//             <Typography variant="h4" component="h4" pt={2} sx={{ color: '#0D629A' }}>
//                 Welcome To Brand Liaison
//             </Typography>
//             <Typography variant="caption" >Enter the user detail her</Typography>
//             <Divider className='divider-component' sx={{ borderBottomWidth: 'medium', backgroundColor: '#1C7CBC', my: 1 }} />
//             <Box
//                 sx={{
//                     width: 500,
//                     maxWidth: '100%',
//                     pt: 2
//                 }}
//             >
//                 <Box sx={{ display: 'flex' }}><WifiCalling3Icon sx={{ marginRight: 1 }} color="primary" /><Typography variant="subtitle1" pb={1}>+91-9810363988 </Typography></Box>
//                 <Box sx={{ display: 'flex' }}><WifiCalling3Icon sx={{ marginRight: 1 }} color="primary" /><Typography variant="subtitle1" pb={1}>+91-9810363988 </Typography></Box>

//                 <Box variant='div' mb={2}>
//                     <Typography variant="subtitle1" pb={1}>Name</Typography>
//                     <TextField fullWidth label="name@company.com" type="text" id="fullWidth" />
//                 </Box>
//                 <Box variant='div' mb={2}>
//                 <Grid item xs={12} >
//                 <Typography variant="subtitle1" pb={1}>Mobile No</Typography>
//                 <Grid container spacing={1}>
//               <Grid item xs={12} md={3}>
//                 <FormControl fullWidth>
//                   <InputLabel id="country-code-label" label="+91"></InputLabel>
//                   <Select
//                     labelId="country-code-label"
//                     label="+91"
//                     name="countryCode"
//                     value="countryCode"
//                     // onChange={handleChange}
//                   >
//                     <MenuItem value="+91">+91</MenuItem>
//                     <MenuItem value="+001">+001</MenuItem>
//                     <MenuItem value="+002">+002</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} md={9}>
//                 <TextField
//                   fullWidth
//                   label="Mobile Number"
//                   // name="mobileNumber"
//                   // value="mobileNumber"
//                 //   onChange={handleChange}
//                 />
//               </Grid>
//             </Grid>
                
//                              </Grid>
//                 </Box>
//                 <Box variant='div' mb={2}>
//                     <Typography variant="subtitle1" pb={1}>Schedule</Typography>
//                     <LocalizationProvider dateAdapter={AdapterDayjs}>
//                         <DemoContainer components={['DateTimePicker']}>
//                             <DateTimePicker label="Basic date time picker" />
//                         </DemoContainer>
//                     </LocalizationProvider>
//                 </Box>
//                 <Box sx={{ pt: 3 }}>

//                     <Button variant="contained" size="large" color='primary' fullWidth>Connect Now</Button>

//                 </Box>


//             </Box>
//         </>

//     )
// }

// export default Callback


import React, { useState, useEffect } from 'react';
import { Divider, Typography, Box, Button, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@mui/material';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const Callback = () => {
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        countryCode: '',
        mobileNumber: '',
        schedule: null
    });

    useEffect(() => {
        // Fetch countries
        const fetchCountries = async () => {
            try {
                const response = await fetch('/api/frontend/v1/brochures/country');
                const data = await response.json();
                setCountries(data);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleDateChange = (newDate) => {
        setFormData((prevData) => ({
            ...prevData,
            schedule: newDate
        }));
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch('/api/frontend/v1/contact-us/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            console.log('Form submitted successfully:', result);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <>
            <Typography variant="h4" component="h4" pt={2} sx={{ color: '#0D629A' }}>
                Welcome To Brand Liaison
            </Typography>
            <Typography variant="caption">Enter the user detail here</Typography>
            <Divider className='divider-component' sx={{ borderBottomWidth: 'medium', backgroundColor: '#1C7CBC', my: 1 }} />
            <Box sx={{ width: 500, maxWidth: '100%', pt: 2 }}>
                <Box sx={{ display: 'flex' }}>
                    <WifiCalling3Icon sx={{ marginRight: 1 }} color="primary" />
                    <Typography variant="subtitle1" pb={1}>+91-9810363988</Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <WifiCalling3Icon sx={{ marginRight: 1 }} color="primary" />
                    <Typography variant="subtitle1" pb={1}>+91-9810363988</Typography>
                </Box>
                <Box variant='div' mb={2}>
                    <Typography variant="subtitle1" pb={1}>Name</Typography>
                    <TextField 
                        fullWidth 
                        label="user name" 
                        type="text" 
                        id="fullWidth" 
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </Box>
                <Box variant='div' mb={2}>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" pb={1}>Mobile No</Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id="country-code-label">+91</InputLabel>
                                    <Select
                                        labelId="country-code-label"
                                        name="countryCode"
                                        value={formData.countryCode}
                                        onChange={handleChange}
                                    >
                                        {countries.map((country) => (
                                            <MenuItem key={country.code} value={country.code}>
                                                {country.code}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <TextField
                                    fullWidth
                                    label="Mobile Number"
                                    name="mobileNumber"
                                    value={formData.mobileNumber}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <Box variant='div' mb={2}>
                    <Typography variant="subtitle1" pb={1}>Schedule</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label="Select date and time"
                            value={formData.schedule}
                            onChange={handleDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            fullWidth
                        />
                    </LocalizationProvider>
                </Box>
                <Box sx={{ pt: 3 }}>
                    <Button 
                        variant="contained" 
                        size="large" 
                        color="primary" 
                        fullWidth
                        onClick={handleSubmit}
                    >
                        Connect Now
                    </Button>
                </Box>
            </Box>
        </>
    );
};

export default Callback;
