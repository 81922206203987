import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Chip, Typography, Box, CircularProgress } from "@mui/material";
import cii from "../../Assets/Images/cii.svg";
import mems from "../../Assets/Images/msme.svg";
import qci from "../../Assets/Images/qci_logo_web.svg";
import spec from "../../Assets/Images/sepc.svg";
import { Autoplay, Navigation } from "swiper/modules";
import UserFetchData from "../../api/UserFetchData"


import "swiper/css";
// import "swiper/css/pagination";
import "./OurAssociates.scss";


const OurAssociates = ({ title, backgroundColor }) => {

     // Fetch services data
     const {
      data: associateData,
      loading:associateLoading,
      error: associateError,
    } = UserFetchData("api/frontend/v1/home/section/associate");

     // Fetch heading data
    const {
      data: headingData,
      loading: headingLoading,
      error: headingError,
    } = UserFetchData("/api/frontend/v1/home/sections/home-associates-section");
    const [heading, setHeading] = useState({
      tagline: "",
      description: "",
    });
    useEffect(() => {
      if (headingData) {
        const tagline = JSON.parse(headingData.section_tagline);
        const description = JSON.parse(headingData.section_description);  
        console.log(headingData,"headingData");  
        setHeading({
                tagline: tagline.blocks[0].data.text,
                description: description.blocks[0].data.text,
              });
      }
    }, [headingData]);
  
    if (associateLoading || headingLoading) {
      return <CircularProgress />;
    }
  
    if (associateError || headingError) {
      return <div>Error: {associateError || headingError}</div>;
    }
 
  const styles = {
    backgroundColor: backgroundColor === "none" ? "transparent" : "#C3E7FF",
        };
  
  return (
    <Box style={styles}>
      <Box className="section-full">
        <Chip label={heading.tagline} variant="span" color="primary" />
        <Typography marginBottom={{ xs: 1, sm: 2, md: 3 }} variant="h3">
          {heading.description}
        </Typography>

        <Swiper
        loop={true}
          spaceBetween={15}
          centeredSlides={false}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: false,

          }}
          breakpoints={{
            360: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            540: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          className="mySwiper"
          modules={[Autoplay, Navigation]}
        >
          {associateData.map((associate, index) => (
            <SwiperSlide key={index}>
              <img
                src={associate.associate_img_url}
                alt={associate.associate_name}
                loading="lazy"
              />
            </SwiperSlide>
          ))}
          {/* {associateData.map((associate) => (
  
          <SwiperSlide>
            <img src={associate.associate_img_url}
            // {...associateData.spec} 
            alt={associate.associate_name
            } loading="lazy" />
          </SwiperSlide>
          ))} */}
          {/* <SwiperSlide>
            <img src={associateData[1].associate_img_url} alt="Company-logo" loading="lazy" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={associateData[2].associate_img_url} alt="Company-logo" loading="lazy" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={associateData.associate_img_url} alt="Company-logo" loading="lazy" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={associateData.associate_img_url} alt="Company-logo" loading="lazy" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={associateData.associate_img_url} alt="Company-logo" loading="lazy" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={associateData.associate_img_url} alt="Company-logo" loading="lazy" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={associateData.associate_img_url} alt="Company-logo" loading="lazy" />
          </SwiperSlide> */}
        </Swiper>
      </Box>
    </Box>
  );
};

export default OurAssociates;
