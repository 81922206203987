// import { React, useState } from "react";
// import {
//   Divider,
//   Typography,
//   Box,
//   Button,
//   TextField,
//   Input,
// } from "@mui/material";
// import {
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   FormGroup,
// } from "@mui/material";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";
// import Autocomplete from "@mui/material/Autocomplete";
// import ReCAPTCHA from "react-google-recaptcha";
// import "./JobApplication.scss";
// import useFetchData from "../../api/UserFetchData";

// const JobApplication = () => {
//   const options = ["Option 1", "Option 2", "Option 3"];

//   const [selectedValue, setSelectedValue] = useState("");
//   const handleChange = (event) => {
//     setSelectedValue(event.target.value);
//   };
//   /********* File Upload ******* */
//   const [selectedFile, setSelectedFile] = useState(null);
//   const handleFileChange = (event) => {
//     setSelectedFile(event.target.files[0]);
//   };
//   /********* File Upload ******* */
//   const [captchaValue, setCaptchaValue] = useState(null);

//   const handleCaptchaChange = (value) => {
//     setCaptchaValue(value);
//   };
//   if (captchaValue) {
//     // Send the CAPTCHA value to your server for verification
//     console.log("CAPTCHA Value:", captchaValue);
//     // Perform your submit action
//   } else {
//     // Show error message or handle invalid CAPTCHA
//     console.error("CAPTCHA validation failed");
//   }
//   const [user_name, setUsername] = useState("");
//   const [applied_for_post, setPost] = useState("");
//   const [org_name, setOrganizationName] = useState("");
//   const [email, setEmail] = useState("");
//   const [country_code, setCountrtycode] = useState("");
//   const [phone_number, setPhone_number] = useState("");
//   const [ready_to_relocate, setreLocation] = useState("");  
//   const [location, setLocation] = useState("");
//   const [findUs, setFindUs] = useState("");
//   const [user_message, setMessage] = useState("");
//   const [upload_resume, setResume] = useState(null);
//   //   const [setCaptchaValue, setCaptchaValue] = useState(null);

//   const { data, loading, error, fetchData } = useFetchData(
//     "/frontend/v1/contact-us/submit",
//     "post"
//   );

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const body = {
//       user_name,
//       applied_for_post,
//       org_name,
//       email,
//       country_code,
//       phone_number,
//       ready_to_relocate,
//       location,
//       findUs,
//       user_message,
//       upload_resume,
//       captchaValue,
//     };
//     fetchData("/frontend/v1/contact-us/submit", "post", body);
//   };

//   return (
//     <>
//       <Typography variant="h4" component="h4" pt={2} sx={{ color: "#0D629A" }}>
//         Application Form{" "}
//       </Typography>
//       <Typography variant="caption">Enter the user detail her</Typography>
//       <Divider
//         className="divider-component"
//         sx={{ borderBottomWidth: "medium", backgroundColor: "#1C7CBC", my: 1 }}
//       />
//       <Box
//         sx={{
//           width: 500,
//           maxWidth: "100%",
//           pt: 2,
//         }}
//       >
//         {/* <Box variant='div' mb={2} sx={{position:'relative'}}>
//                     <Box variant='div'>
//                     <Typography variant="subtitle1" pb={1}>User Name</Typography>
//                     <TextField fullWidth label="Enter the user Name" type="text" id="fullWidth" />
//                     </Box>
//                     <Button
//                     sx={{position:'absolute'}}
//                     type={File}
//                         component="label"
//                         role={undefined}
//                         variant="contained"
//                         tabIndex={-1}
//                         startIcon={<CloudUploadIcon />}
//                     >
//                         Upload file
//                     </Button>
//                 </Box> */}
//         <Box variant="div" mb={2} position="relative">
//           <Typography variant="subtitle1" pb={1}>
//             Upload Your CV
//           </Typography>

//           <TextField
//             value={selectedFile ? selectedFile.name : ""}
//             label="File Name"
//             variant="outlined"
//             fullWidth
//             disabled
//             style={{ marginTop: "10px" }}
//           />
//           <Input
//             type="file"
//             onChange={handleFileChange}
//             style={{ display: "none" }}
//             id="file-upload"
//           />
//           <label htmlFor="file-upload">
//             <Button
//               variant="contained"
//               component="span"
//               className="upload-button"
//               sx={{ position: "absolute" }}
//             >
//               Upload
//             </Button>
//           </label>
//         </Box>
//         <Box variant="div" mb={2}>
//           <Typography variant="subtitle1" pb={1}>
//             User Name
//           </Typography>
//           <TextField
//             fullWidth
//             label="Enter the user Name"
//             type="text"
//             id="fullWidth"
//           />
//         </Box>
//         <Box variant="div" mb={2}>
//           <Typography variant="subtitle1" pb={1}>
//             Organization Name
//           </Typography>
//           <TextField
//             fullWidth
//             label="Enter the Organization Name"
//             type="text"
//             id="fullWidth"
//           />
//         </Box>
//         <Box variant="div" mb={2}>
//           <Typography variant="subtitle1" pb={1}>
//             Email-id
//           </Typography>
//           <TextField
//             fullWidth
//             label="name@company.com"
//             type="email"
//             id="fullWidth"
//           />
//         </Box>
//         <Box variant="div" mb={2}>
//           <Typography variant="subtitle1" pb={1}>
//             Mobile Number
//           </Typography>
//           <Box sx={{ display: "flex" }}>
//             <Autocomplete
//               options={options}
//               renderInput={(params) => (
//                 <TextField
//                   {...params}
//                   label="Select"
//                   variant="outlined"
//                   fullWidth
//                   // Add any other props you need for the autocomplete input
//                 />
//               )}
//             />
//             <TextField
//               variant="outlined"
//               fullWidth
//               label="Enter the mobile no"
//               type="number"
//               autoComplete="mobile Number"
//               id="fullWidth"
//             />
//           </Box>
//         </Box>
//         <Typography variant="subtitle1" pb={1}>
//           Location
//         </Typography>
//         <FormControl fullWidth>
//           <InputLabel id="demo-simple-select-label">Select Services</InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={selectedValue}
//             onChange={handleChange}
//           >
//             <MenuItem value={"option1"}>Select Services 1</MenuItem>
//             <MenuItem value={"option2"}>Select Services 2</MenuItem>
//             <MenuItem value={"option3"}>Select Services 3</MenuItem>
//           </Select>
//         </FormControl>
//         <FormGroup pb={1}>
//           <FormControlLabel
//             control={<Checkbox defaultChecked />}
//             label="Are you ready for realocate"
//           />
//         </FormGroup>

//         <Typography variant="subtitle1" pb={1}>
//           Find Us
//         </Typography>
//         <FormControl fullWidth>
//           <InputLabel id="demo-simple-select-label">
//             How did you find us
//           </InputLabel>
//           <Select
//             labelId="demo-simple-select-label"
//             id="demo-simple-select"
//             value={selectedValue}
//             onChange={handleChange}
//           >
//             <MenuItem value={"option1"}>Select Services 1</MenuItem>
//             <MenuItem value={"option2"}>Select Services 2</MenuItem>
//             <MenuItem value={"option3"}>Select Services 3</MenuItem>
//           </Select>
//         </FormControl>
//         <Typography variant="subtitle1" pb={1}>
//           Your Message
//         </Typography>
//         <TextField
//           id="outlined-textarea"
//           label="Multiline Placeholder"
//           placeholder="Placeholder"
//           multiline
//           fullWidth
//         />
//         <Box sx={{ pt: 3, width: 100 }}>
//           <ReCAPTCHA
//             sitekey="YOUR_RECAPTCHA_SITE_KEY"
//             onChange={handleCaptchaChange}
//           />
//         </Box>
//         <Box sx={{ pt: 3 }}>
//           <Button
//             variant="contained"
//             size="large"
//             color="primary"
//             type="submit"
//             fullWidth
//           >
//             Submit
//           </Button>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default JobApplication;

import { React, useState } from "react";
import {
  Divider,
  Typography,
  Box,
  Button,
  TextField,
  Input,
} from "@mui/material";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import ReCAPTCHA from "react-google-recaptcha";
import "./JobApplication.scss";

const JobApplication = () => {
  const options = ["Option 1", "Option 2", "Option 3"];

  const [selectedValue, setSelectedValue] = useState("");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const [captchaValue, setCaptchaValue] = useState(null);
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const [user_name, setUsername] = useState("");
  const [applied_for_post, setPost] = useState("");
  const [org_name, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [country_code, setCountrtycode] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [ready_to_relocate, setreLocation] = useState(false);
  const [location, setLocation] = useState("");
  const [findUs, setFindUs] = useState("");
  const [user_message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!captchaValue) {
      console.error("CAPTCHA validation failed");
      return;
    }

    const formData = new FormData();
    formData.append("user_name", user_name);
    formData.append("applied_for_post", applied_for_post);
    formData.append("org_name", org_name);
    formData.append("email", email);
    formData.append("country_code", country_code);
    formData.append("phone_number", phone_number);
    formData.append("ready_to_relocate", ready_to_relocate);
    formData.append("location", location);
    formData.append("findUs", findUs);
    formData.append("user_message", user_message);
    formData.append("upload_resume", selectedFile);
    formData.append("captchaValue", captchaValue);

    try {
      const response = await fetch("/frontend/v1/application-form", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      console.log("Form submitted successfully:", result);
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography variant="h4" component="h4" pt={2} sx={{ color: "#0D629A" }}>
        Application Form
      </Typography>
      <Typography variant="caption">Enter the user detail here</Typography>
      <Divider
        className="divider-component"
        sx={{ borderBottomWidth: "medium", backgroundColor: "#1C7CBC", my: 1 }}
      />
      <Box
        sx={{
          width: 500,
          maxWidth: "100%",
          pt: 2,
        }}
      >
        <Box variant="div" mb={2} position="relative">
          <Typography variant="subtitle1" pb={1}>
            Upload Your CV
          </Typography>
          <TextField
            value={selectedFile ? selectedFile.name : ""}
            label="File Name"
            variant="outlined"
            fullWidth
            disabled
            style={{ marginTop: "10px" }}
          />
          <Input
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button
              variant="contained"
              component="span"
              className="upload-button"
              sx={{ position: "absolute" }}
            >
              Upload
            </Button>
          </label>
        </Box>
        <Box variant="div" mb={2}>
          <Typography variant="subtitle1" pb={1}>
            User Name
          </Typography>
          <TextField
            fullWidth
            label="Enter the user Name"
            type="text"
            id="fullWidth"
            value={user_name}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Box>
        <Box variant="div" mb={2}>
          <Typography variant="subtitle1" pb={1}>
            Organization Name
          </Typography>
          <TextField
            fullWidth
            label="Enter the Organization Name"
            type="text"
            id="fullWidth"
            value={org_name}
            onChange={(e) => setOrganizationName(e.target.value)}
          />
        </Box>
        <Box variant="div" mb={2}>
          <Typography variant="subtitle1" pb={1}>
            Email-id
          </Typography>
          <TextField
            fullWidth
            label="name@company.com"
            type="email"
            id="fullWidth"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box variant="div" mb={2}>
          <Typography variant="subtitle1" pb={1}>
            Mobile Number
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Autocomplete
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select"
                  variant="outlined"
                  fullWidth
                />
              )}
              value={country_code}
              onChange={(event, newValue) => setCountrtycode(newValue)}
            />
            <TextField
              variant="outlined"
              fullWidth
              label="Enter the mobile no"
              type="number"
              autoComplete="mobile Number"
              id="fullWidth"
              value={phone_number}
              onChange={(e) => setPhone_number(e.target.value)}
            />
          </Box>
        </Box>
        <Box variant="div" mb={2}>
          <Typography variant="subtitle1" pb={1}>
            Location
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="location-label">Select Location</InputLabel>
            <Select
              labelId="location-label"
              id="location-select"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              <MenuItem value={"location1"}>Location 1</MenuItem>
              <MenuItem value={"location2"}>Location 2</MenuItem>
              <MenuItem value={"location3"}>Location 3</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <FormGroup pb={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={ready_to_relocate}
                onChange={(e) => setreLocation(e.target.checked)}
              />
            }
            label="Are you ready to relocate?"
          />
        </FormGroup>
        <Box variant="div" mb={2}>
          <Typography variant="subtitle1" pb={1}>
            Find Us
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="find-us-label">How did you find us</InputLabel>
            <Select
              labelId="find-us-label"
              id="find-us-select"
              value={findUs}
              onChange={(e) => setFindUs(e.target.value)}
            >
              <MenuItem value={"option1"}>Option 1</MenuItem>
              <MenuItem value={"option2"}>Option 2</MenuItem>
              <MenuItem value={"option3"}>Option 3</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box variant="div" mb={2}>
          <Typography variant="subtitle1" pb={1}>
            Your Message
          </Typography>
          <TextField
            id="outlined-textarea"
            label="Multiline Placeholder"
            placeholder="Placeholder"
            multiline
            fullWidth
            value={user_message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Box>
        <Box sx={{ pt: 3, width: 100 }}>
          <ReCAPTCHA
            sitekey="YOUR_RECAPTCHA_SITE_KEY"
            onChange={handleCaptchaChange}
          />
        </Box>
        <Box sx={{ pt: 3 }}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
            fullWidth
          >
            Submit
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default JobApplication;

