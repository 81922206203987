import React from 'react';
import Grid from "@mui/material/Grid";
import { Box, Typography, Chip, CircularProgress } from '@mui/material';
import vision from "../Assets/Images/vision.jpg";
import "./AboutUsPage.scss";
import getFetchData from "../api/UserFetchData";

const AboutUsVision = () => {
  const { data, loading, error } = getFetchData('/api/frontend/v1/about/section/about-vision-section');
  
  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }
  
  return (
    <>
      <Grid container spacing={3} className='AboutUsPage__vision' px={5} py={3}>
        <Grid item xs={12} sm={12} md={6} textAlign={'left'}>
          <Box>
            <Chip label="WHAT WE ARE DOING" variant="span" color='primary' sx={{ width: '35%' }} />
            <Typography variant='h3'>
              Our Vision: {data.title1}
            </Typography>
            <Typography variant='caption' mt={1}>
              {data.desc}
              Brand Liaison was founded in 2014 and is registered with the ROC, Ministry of Corporate Affairs, Government of India. The company provides a one-stop solution for all kinds of compliance management services for the Indian market. We provide compliance services to Indian and foreign manufacturers such as BIS Registration, ISI Certification, BEE Registration, EPR Authorization (for E-waste and P-waste Products), WPC Approval, TEC Certification, etc., which are necessary for the notified products before importing or selling in India.
            </Typography>
          </Box>
          <Box pt={3}>
            <Chip label="WHAT WE ARE DOING" variant="span" color='primary' sx={{ width: '35%' }} />
            <Typography variant='h3'>
              Our Mission
            </Typography>
            <Typography variant='caption' mt={1}>
              Brand Liaison was founded in 2014 and is registered with the ROC, Ministry of Corporate Affairs, Government of India. The company provides a one-stop solution for all kinds of compliance management services for the Indian market. We provide compliance services to Indian and foreign manufacturers such as BIS Registration, ISI Certification, BEE Registration, EPR Authorization (for E-waste and P-waste Products), WPC Approval, TEC Certification, etc., which are necessary for the notified products before importing or selling in India.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <img className='vision__img' src={vision} alt="Vision" loading="lazy" />
        </Grid>
      </Grid>
    </>
  );
}

export default AboutUsVision;
