// import React from 'react'
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
// import { Box } from '@mui/material';

// function srcset(image, size, rows = 4, cols = 6) {
//   return {
//     src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
//     srcSet: `${image}?w=${size * cols}&h=${
//       size * rows
//     }&fit=crop&auto=format&dpr=2 2x`,
//   };
// }

// export default function Media() {
//   return (
//     <Box sx={{marginBlock:1}}>
//     <ImageList
//       sx={{ width:'100%'}}
//       variant="quilted"
//       cols={6}
//       rowHeight={150}
//       className='Media-list'
//     >
//       {itemData.map((item) => (
//         <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
//           <img
//             {...srcset(item.img, 150, item.rows, item.cols)}
//             alt={item.title}
//             loading="lazy"
//           />
//         </ImageListItem>
//       ))}
//     </ImageList>
//     </Box>
//   );
// }

// const itemData = [
//   {
//     img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
//     title: 'Breakfast',
//     rows: 2,
//     cols: 2,
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
//     title: 'Burger',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
//     title: 'Camera',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
//     title: 'Coffee',
//     cols: 2,
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
//     title: 'Hats',
//     cols: 2,
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
//     title: 'Honey',
//     author: '@arwinneil',
//     rows: 2,
//     cols: 2,
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
//     title: 'Basketball',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
//     title: 'Fern',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
//     title: 'Mushrooms',
//     rows: 2,
//     cols: 2,
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
//     title: 'Tomato basil',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
//     title: 'Sea star',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
//     title: 'Bike',
//     cols: 2,
//   },
// ];





// import React, { useState, useCallback } from "react";
// import Gallery from "react-photo-gallery";
// import Carousel, { Modal, ModalGateway } from "react-images";
// // import { photos } from "./photos";
// import { Box, CircularProgress, Typography } from "@mui/material";
// import useFetchData from "../api/UserFetchData";

// const Media = () => {
//   const [currentImage, setCurrentImage] = useState(0);
//   const [viewerIsOpen, setViewerIsOpen] = useState(false);

//   const openLightbox = useCallback((event, { photo, index }) => {
//     setCurrentImage(index);
//     setViewerIsOpen(true);
//   }, []);

//   const closeLightbox = () => {
//     setCurrentImage(0);
//     setViewerIsOpen(false);
//   };
//   const { data, loading, error } = useFetchData('/api/frontend/v1/gallery');
//   if (loading) {
//     return <CircularProgress/>;
//   }
//   if (error) {
//     return <div>Error:{error}</div>;
//   }

//   return (
//     <section>
//       <Box textAlign={"center"}>
//       <Typography variant="h4" className='AboutUsPage__title' textAlign={'center'} position={'relative'} m={3}  >
//          Gallery
//         </Typography>
//         </Box>
//       {/* <Gallery photos={data.photos} onClick={openLightbox} /> */}
//       <Gallery photos={data.media_Url} onClick={openLightbox} />
//       <ModalGateway>
//         {viewerIsOpen ? (
//           <Modal onClose={closeLightbox}>
//             <Carousel
//               currentIndex={currentImage}
//               // views={data.photos.map((x) => ({

//               views={data.media_Url.map((x) => ({
//                 ...x,
//                 srcset: x.srcSet,
//                 caption: x.title,
//                 title: x.title,
//               }))}
//             />
//           </Modal>
//         ) : null}
//       </ModalGateway>
//     </section>
//   );
// };

// export default Media;


import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Box, CircularProgress, Typography } from "@mui/material";
import useFetchData from "../api/UserFetchData";

const Media = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const { data, loading, error } = useFetchData('/api/frontend/v1/gallery');

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Log the entire fetched data to understand its structure
  console.log('Fetched data:', data);

  // Handle various possible data structures
  if (!data || !Array.isArray(data)) {
    console.error("Invalid data structure:", data);
    return <div>No media available</div>;
  }

  // // Log each item in data.media_Url
  // data.media_Url.forEach((item, index) => {
  //   console.log(`Media item ${index}:`, item);
  // });

  const photos = data.map((item, index) => ({
    src: item.media_Url,
    width: item.width || 4,
    height: item.height || 3,
    title: item.gallery_image_title,
  }));

  return (
    <section>
      <Box textAlign="center">
        <Typography variant="h4" className="AboutUsPage__title" textAlign="center" position="relative" m={3}>
          Gallery
        </Typography>
      </Box>
      <Gallery photos={photos} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
                title: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </section>
  );
};

export default Media;




