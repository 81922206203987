
import { useEffect, useState } from 'react';
import axios from 'axios';

const useFetchData = (url, method = 'get', body = null, initialValue = null) => {
  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fetchData = async (url, method, body) => {
    try {
      let config = {
        method: method,
        url: url,
        baseURL: 'https://api.bl-india.com',
        headers: {
          'Content-Type': 'application/json',
        },
        data: body
      };
      const response = await axios(config);
      setData(response.data.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {  
      fetchData(url, method, body);
    
  }, [url, method, body]);

  return { data, loading, error, fetchData };
};

export default useFetchData;
