import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
  Button,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { Link } from "react-router-dom";
import Menu from "@mui/material/Menu";
import "./Topbar.scss";
import { ArrowDropDown } from "@mui/icons-material";
import LanguageList from "./LanguageList";
import SocialMedia from "../SocialMedia/SocialMedia";

const drawerWidth = 240;
const navItems = ["home", "about", "contact", "careers", "gallery", "download"];

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  /********************* */

  /********************* */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  console.log(mobileOpen);
  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "left" }}
      className="sticky topbar"
    >
      <Typography variant="h6" sx={{ my: 2 }}></Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              className="menu-items"
              sx={{ textAlign: "left", backgroundColor: "primary.dark" }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "white",
                  textTransform: "lowercase",
                }}
                to={`/${item}`}
              >
                {item}
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
        {/* <ListItemButton
          color="whitebg"
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
           className="menu-items"
          sx={{ textAlign: "left", backgroundColor: "primary.dark",textDecoration: "none", color: "white" }}>
          Language <ArrowDropDown />
          {mobileOpen && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}
              >English</MenuItem>
              <MenuItem onClick={handleClose}>Hindi</MenuItem>
              <MenuItem onClick={handleClose}>Urdu</MenuItem>
            </Menu>
          )}
        </ListItemButton> */}
        <LanguageList />
        <div></div>
      </List>
      <List></List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  // const languagelist = [
  //   { label: 'English'},
  //   { label: 'Hindi' },
  //   ];
  return (
    <Box className="topbar-section">
      <AppBar component="nav" position="static">
        <Toolbar className="nav_toolbar" color="secondary" sx={{justifyContent:'space-between'}}>
         <SocialMedia/>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" }, color: "inherit" }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex", justifyContent: "flex-end" },
            }}
          >
            {navItems.map((item) => (
              <Button
                key={item}
                sx={{ color: "#fff", textTransform: "capitalize" }}
              >
                <Link
                  style={{ textDecoration: "none", color: "white" }}
                  to={`/${item}`}
                >
                  {" "}
                  {item}
                </Link>
              </Button>
            ))}

            <LanguageList />
            {/* <div>
              <Button
                color="whitebg"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                En <ArrowDropDown />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>English</MenuItem>
                <MenuItem onClick={handleClose}>Hindi</MenuItem>
                <MenuItem onClick={handleClose}>Urdu</MenuItem>
              </Menu>
            </div> */}
            {/* <Box sx={{display:'flex' ,justifyContent:"space-between",alignItems:'center'}}>
      <InputLabel id="demo-simple-select-label"  color="whitebg">En</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value="20"
        label="En"
        color="whitebg"
      >
        <MenuItem value="English">English</MenuItem>
        <MenuItem value="Hindi">Hindi</MenuItem>
      </Select>
    </Box> */}

            {/* <Autocomplete
            displayEmpty
              disablePortal
              id="combo-box-demo"
              options={languagelist}
              sx={{ width: 90 ,color:'#fff' }}
              renderInput={(params) => <TextField {...params} label="en" />}
            />
             */}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "primary.dark",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
