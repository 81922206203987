import { MenuItem, Select, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react'

const ServiceDetailsMobileSelect = () => {
    const [selectedItem, setSelectedItem] = useState('option1');

    const handleChanges = (event) => {
      setSelectedItem(event.target.value);
    };
  
  
    const isMobile = useMediaQuery("(max-width:767px)");
  return (
    <>
    {isMobile ? (
  
        <>
         <Box sx={{display:'flex',flex:1,marginBlock:4}}>
          <Select value={selectedItem} onChange={handleChanges} fullWidth>
            <MenuItem value="option1"> Introduction</MenuItem>
            <MenuItem value="option2"> Mandatory Product list</MenuItem>
            <MenuItem value="option3"> Required Documents </MenuItem>
            <MenuItem value="option4"> Registration Process</MenuItem>
            <MenuItem value="option5">  Validity & Renewal</MenuItem>
            <MenuItem value="option6"> Basic Guidelines </MenuItem>
            <MenuItem value="option7">  Exempted Product List </MenuItem>
          </Select>         
        </Box>
        </>
    
        ) :
        (<></>)}
        </>
  )
}

export default ServiceDetailsMobileSelect