import { Box, Typography } from "@mui/material";
import React from "react";

const PrivacyPolicy = () => {
  return (
    <section>
      <Box textAlign={"center"}>
        <Typography
          variant="h4"
          className="AboutUsPage__title"
          textAlign={"center"}
          position={"relative"}
          m={3}
        >
          Privacy Policy
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="h6" color="primary.main">
          User Consent
        </Typography>
        <Typography variant="p">
          Accessing our website www.bl-india.com and using it, you expressly
          consent and confirm to Brand Liaison India Pvt. Ltd. using,
          collecting, processing, maintaining and disclosing your personal and
          other information in accordance with this Privacy Policy.
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="h6" color="primary.main">
          Our Commitment
        </Typography>
        <Typography variant="p">
          Brand Liaison intends to protect the privacy of its users. The privacy
          of our website users, whether you are our former or existing user or
          merely a visitor to our website, is important to us and we are
          strongly committed to your right to privacy and to keeping your
          personal and other information secure. We encourage you to read our
          privacy statement to understand what types of personally identifiable
          information we collect and how we use this information. For the
          purpose of providing you with quality services, our website collects
          preliminary personal information that allows us to identify you as a
          user and know more about your requirements. This Privacy Policy only
          deals with your information shared with us and we shall in no manner
          be liable/bound for protection of information (if any), which may be
          shared by you on web domains. We encourage you to very carefully
          review the privacy policy of any third-party website you visit before
          using it and to be very cautious before you disclose your personal
          information on such websites.
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="h6" color="primary.main">
          Personal Information
        </Typography>
        <Typography variant="p">
          If you are a business owner or an individual looking to opt a solution
          from Brand Liaison, our website collects your name, email ID, personal
          phone number, details of personal interest and location. Such
          information is stored in Brand Liaison database and is used for its
          own reference as well as for placements in other organizations. The
          information is kept strictly confidential. Brand Liaison also collects
          your other personal details including but not limited to your
          feedback, etc. that you may provide on visit of our website. All other
          information exchanged by you with Brand Liaison in the form of written
          communication, responses to emails, etc. required from you,
          participation in discussions, etc. shall be deemed to be confidential
          and shall be owned by Brand Liaison and shall be available for the
          exclusive use of Brand Liaison. Our website does not collect
          information about you unless you specifically and knowingly provide
          it. Brand Liaison DOES NOT trade or sell your information in any
          manner, except as specified herein or if express consent is sought
          from you.
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="h6" color="primary.main">
          Non-Personal Information
        </Typography>
        <Typography variant="p">
          Analytics also collects certain other information from you on your
          visit to our website such as your information about your operating
          system, browser type, the URL of the previous web site you visited,
          your Internet service provider and your IP Address (“Non-personal
          Information”). This information cannot be easily used to personally
          identify you. We use Non-personal information for the purposes
          including but not limited to troubleshoot connection problems,
          administer the website, analyze trends, gather demographic
          information, to ascertain how our visitors use our website, including
          information relating to the frequency of visits to our web site,
          average length of visits, pages viewed during a visit, ensuring
          compliance with the applicable law, co-operating with law enforcement
          activities, etc. This information is used to improve the site content
          and performance. You also agree that Brand Liaison may gather usage
          statistics and usage data from your use of our website to evaluate
          your use of our services, to improve our services, website content and
          to ensure that you are complying with the terms of the applicable
          agreements between you and Brand Liaison. The statistics and data
          collected may or may not be aggregated. These statistics contain no
          information that can distinctly identify you. We may in future also
          share this information with third party service providers or third
          party advertisers to measure the overall effectiveness of our online
          advertising, content, programming and for other bonafide purpose as we
          may desire. By usage of our website you expressly permit Brand Liaison
          to access such information for one or more purposes deemed fit by
          Brand Liaison.
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="h6" color="primary.main">
          Cookies
        </Typography>
        <Typography variant="p">
          Similar to other commercial web sites, our website utilizes standard
          technology called "Cookies" and Web server logs to collect information
          about how our website is used. Cookies are small pieces of information
          that are stored by your browser on your computer's hard drive. Cookies
          are only read by the server that places them, and are unable to inter
          alia run programs on your computer, plant viruses or harvest your
          personal information. Cookies allow Web servers to recognize your
          computer each time you return to our website including the date and
          time of visits, the pages viewed, time spent at our website, and the
          websites visited just before and just after our website. This
          information is collected on an aggregate basis. None of this
          information is associated with you as an individual. Most web browsers
          automatically accept cookies. By changing the options on your web
          browser or using certain software programs, you can control how and
          whether cookies will be accepted by your browser. Brand Liaison
          understands and supports your right to block any unwanted Internet
          activity, especially that of unscrupulous websites. However, blocking
          Brand Liaison cookies may disable certain features on our website and
          may make it impossible for you to use certain services available on
          our website.
        </Typography>
      </Box>
      <Box marginBottom={1}>
        <Typography variant="h6" color="primary.main">
          Session Data
        </Typography>
        <Typography variant="p">
          We automatically log generic information about your computer's
          connection to the Internet, which we call "session data", that is
          anonymous and not linked to any personal information. Session data
          consists of information such as the IP address, operating system, type
          of browser software being used by you and the activities conducted by
          you on our website. An IP address is a number that lets computers
          attached to the Internet, such as our web servers, know where to send
          data back to the user, such as the pages of the site the user wishes
          to view. We collect session data because it helps us analyze things
          such as the items visitors are likely to click on most, the manner in
          which visitors click preferences on our website, number of visitors
          surfing to various pages on the site, time spent by the visitors on
          our website and frequency of their visit. It also helps us diagnose
          problems with our servers and lets us administer our systems better.
          Although such information does not identify any visitor personally, it
          is possible to determine from an IP address a visitor's Internet
          Service Provider (ISP), and the approximate geographic location of his
          or her point of connectivity.
        </Typography>
      </Box>
    </section>
  );
};

export default PrivacyPolicy;
