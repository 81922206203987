import React from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 28.63713,
  lng: 77.28476
};

function GoogleMapComponent() {
  return (
    <LoadScript googleMapsApiKey="AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
      >
        {/* Child components, markers, etc. */}
      </GoogleMap>
    </LoadScript>
  );
}

export default GoogleMapComponent;


// import React, { useEffect, useState } from 'react';
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
// import axios from 'axios';

// const containerStyle = {
//   width: '100%',
//   height: '400px'
// };

// const center = {
//   lat: 28.63713,
//   lng: 77.28476
// };

// const GoogleMapComponent = () => {
//   const [locations, setLocations] = useState([]);

//   useEffect(() => {
//     // Fetch data from the API
//     const fetchData = async () => {
//       try {
//         const response = await axios.get('https://api.example.com/locations');
//         setLocations(response.data);
//       } catch (error) {
//         console.error('Error fetching data: ', error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <LoadScript googleMapsApiKey="AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
//       <GoogleMap
//         mapContainerStyle={containerStyle}
//         center={center}
//         zoom={10}
//       >
//         {locations.map((location, index) => (
//           <Marker 
//             key={index}
//             position={{ lat: location.latitude, lng: location.longitude }}
//           />
//         ))}
//       </GoogleMap>
//     </LoadScript>
//   );
// };

// export default GoogleMapComponent;
