import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Stack,
  CardActions,
  Button,
  Box,
} from "@mui/material";
import bis from "../../Assets/Images/bis-crs.webp";
import { useNavigate } from "react-router-dom";

import "./RelatedServiceCards.scss";

export const data = [
  {
    id: 1,
    title: "Service Title",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
      image: bis, 
    btntext: "Read More",
  },
  {
    id: 2,
    title: "Service Title2",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
      image: bis, 
    btntext: "Read More",
  },
  {
    id: 3,
    title: "Service Title",
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem .",
      image: bis, 
    btntext: "Read More",
  },

];
const RelatedServiceCards = () => {
  const navigate = useNavigate();
 
  const handleClick = () => {
    navigate(`/servicedetail`);
  }
  return (
   
    <Stack
    className="RelatedServices"
    margin={{ xs: 1, sm: 1, md: 2 }}
    direction={{ xs: "column", sm: "column",md:'row',lg:'row'}}
    spacing={{ xs: 1, sm: 1, md: 2 }}
    sx={{
      display: "flex",
      justifyContent: "flex-start",
      flexWrap: "nowrap",
      alignItems: "center",
    }}
  >
    {data.map((card) => (
      <Card
        sx={{ maxWidth: 345, boxShadow: 3 ,width:'100%'}}
        className="related-service-card"
        key={card.id}
        style={{ margin: "6px", maxWidth: "300px" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "spaceBetween",
            alignItems: "center",
          }}
        >
          <CardMedia
            sx={{ height: 80, width: 80}}
            image={card.image}
            className="cardmedia_image"
          />
          <Typography gutterBottom variant="h4" component="div">
            {card.title}
          </Typography>
        </Box>
        <CardContent>
          <Typography variant="body1" color="text.secondary">
            {card.content}
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="outlined" onClick={handleClick}>{card.btntext}</Button>
        </CardActions>
      </Card>
    ))}
  </Stack>
  )
}

export default RelatedServiceCards