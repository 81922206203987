import React from "react";
import { Box, Typography, Stack, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import avtar from "../Assets/Images/avtar.png";

import "./RecentBlog.scss";
import { CalendarMonth } from "@mui/icons-material";

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "80%",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "left",
  border: "1px solid #0D629A",
  borderRadius: "25px",
}));

const RecentBlog = () => {
  return (
    <>
      <Stack
        className="ServicesPage__category"
        marginBottom={{ xs: 1, sm: 1, md: 2 }}
        direction={{ xs: "column", sm: "row" }}
        // spacing={{ xs: 1, sm: 1, md: 2 }}
      >
        <DemoPaper square={true}>
          <Typography variant="h5" className="" textAlign="left">
            Recent Posts{" "}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBlock: "20px",
              }}
            >
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt="Recent Avtar"
                  src={avtar}
                  variant="square"
                  sx={{ width: 56, height: 56 }}
                />
                <CalendarMonth />
                <Typography variant="captionbold">
                  22 Jan, 2023
                  <Typography variant="caption" component="p">
                    Lorem Ipsum is simply dummy
                  </Typography>
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBlock: "10px",
              }}
            >
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt="Recent Avtar"
                  src={avtar}
                  variant="square"
                  sx={{ width: 56, height: 56 }}
                />
                <CalendarMonth />
                <Typography variant="captionbold">
                  22 Jan, 2023
                  <Typography variant="caption" component="p">
                    Lorem Ipsum is simply dummy
                  </Typography>
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBlock: "10px",
              }}
            >
              <Stack direction="row" spacing={2}>
                <Avatar
                  alt="Recent Avtar"
                  src={avtar}
                  variant="square"
                  sx={{ width: 56, height: 56 }}
                />
                <CalendarMonth />
                <Typography variant="captionbold">
                  22 Jan, 2023
                  <Typography variant="caption" component="p">
                    Lorem Ipsum is simply dummy
                  </Typography>
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent:"center",
                paddingBlock: "10px",
              }}
            >
             
            </Box>
          </Typography>
        </DemoPaper>
      </Stack>
    </>
  );
};

export default RecentBlog;
