// import {React,useState} from 'react';
// import { Divider, Typography, Box, Button } from '@mui/material';
// import TextField from '@mui/material/TextField';
// import { FormControl, InputLabel, Select, MenuItem,FormGroup } from '@mui/material';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

// const Partner = () => {
   
//     const [selectedValue, setSelectedValue] = useState('');
//     const handleChange = (event) => {
//         setSelectedValue(event.target.value);
//     };
//   return (
//     <>
//     <Typography variant="h4" component="h4" pt={2} sx={{ color: '#0D629A' }}>Partner Form </Typography>
//     <Typography variant="caption" >Enter the user detail her</Typography>
//     <Divider className='divider-component' sx={{ borderBottomWidth: 'medium', backgroundColor: '#1C7CBC', my: 1 }} />
//     <Box sx={{
//         width: 500,
//         maxWidth: '100%',
//         pt: 2
//     }}
//     >
//         <Box variant='div' mb={2}>
//             <Typography variant="subtitle1" pb={1}>User Name</Typography>
//             <TextField fullWidth label="Enter the user Name" type="text" id="fullWidth" />
//         </Box>
//         <Box variant='div' mb={2}>
//             <Typography variant="subtitle1" pb={1}>Organization Name</Typography>
//             <TextField fullWidth label="Enter the Organization Name" type="text" id="fullWidth" />
//         </Box>
//         <Box variant='div' mb={2}>
//             <Typography variant="subtitle1" pb={1}>Designation Name</Typography>
//             <TextField fullWidth label="Enter the Organization Name" type="text" id="fullWidth" />
//         </Box>
//         <Box variant='div' mb={2}>
//         <Typography variant="subtitle1" pb={1}>Email-id</Typography>
//         <TextField fullWidth label="name@company.com" type="email" id="fullWidth" />
//     </Box>
//     <Box variant='div' mb={2}>
//         <Typography variant="subtitle1" pb={1}>Mobile Number</Typography>
//         <TextField fullWidth label="Enter the mobile no" type="number" autoComplete="mobile Number" id="fullWidth" />
//     </Box>
//     <Typography variant="subtitle1" pb={1}>Location</Typography>
//         <FormControl fullWidth>
//             <InputLabel id="demo-simple-select-label">Select Services</InputLabel>
//             <Select
//                 labelId="demo-simple-select-label"
//                 id="demo-simple-select"
//                 value={selectedValue}
//                 onChange={handleChange}
//             >
//                 <MenuItem value={'option1'}>Select Services 1</MenuItem>
//                 <MenuItem value={'option2'}>Select Services 2</MenuItem>
//                 <MenuItem value={'option3'}>Select Services 3</MenuItem>
//             </Select>
//         </FormControl>
//         <FormGroup pb={1} >
//                 <FormControlLabel control={<Checkbox defaultChecked />} label="Are you ready for realocate" />
//         </FormGroup>

//         <Box variant='div' mb={2}>
//             <Typography variant="subtitle1" pb={1}>Website</Typography>
//             <TextField fullWidth label="Enter the website Name" type="text" id="fullWidth" />
//         </Box>
//         <Typography variant="subtitle1" pb={1}>Your Message</Typography>
//         <TextField
//             id="outlined-textarea"
//             label="Multiline Placeholder"
//             placeholder="Placeholder"
//             multiline
//             fullWidth
//         />
//     <Box sx={{ pt: 3 }}>
//         <Button variant="contained" size="large" color='primary' type='submit' fullWidth>Submit</Button>
//     </Box>
//     </Box>
// </>
//   )
// }

// export default Partner

import { React, useState } from 'react';
import { Divider, Typography, Box, Button, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, Select, MenuItem, FormGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import useFetchData from '../../api/UserFetchData'; // Make sure to import your custom hook

const Partner = () => {
    const [formData, setFormData] = useState({
        userName: '',
        organizationName: '',
        designation: '',
        email: '',
        mobile: '',
        location: '',
        services: '',
        readyToRelocate: false,
        website: '',
        message: ''
    });

    const { fetchData } = useFetchData(); // Custom hook to handle API requests

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetchData('/api/frontend/v1/partner-form', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });
            console.log('Response:', response);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <Typography variant="h4" component="h4" pt={2} sx={{ color: '#0D629A' }}>Partner Form</Typography>
            <Typography variant="caption">Enter the user detail here</Typography>
            <Divider className='divider-component' sx={{ borderBottomWidth: 'medium', backgroundColor: '#1C7CBC', my: 1 }} />
            <Box
                sx={{
                    width: 500,
                    maxWidth: '100%',
                    pt: 2
                }}
                component="form"
                onSubmit={handleSubmit}
            >
                <Box variant='div' mb={2}>
                    <Typography variant="subtitle1" pb={1}>User Name</Typography>
                    <TextField
                        fullWidth
                        label="Enter the user Name"
                        type="text"
                        id="fullWidth"
                        name="userName"
                        value={formData.userName}
                        onChange={handleChange}
                    />
                </Box>
                <Box variant='div' mb={2}>
                    <Typography variant="subtitle1" pb={1}>Organization Name</Typography>
                    <TextField
                        fullWidth
                        label="Enter the Organization Name"
                        type="text"
                        id="fullWidth"
                        name="organizationName"
                        value={formData.organizationName}
                        onChange={handleChange}
                    />
                </Box>
                <Box variant='div' mb={2}>
                    <Typography variant="subtitle1" pb={1}>Designation</Typography>
                    <TextField
                        fullWidth
                        label="Enter the Designation"
                        type="text"
                        id="fullWidth"
                        name="designation"
                        value={formData.designation}
                        onChange={handleChange}
                    />
                </Box>
                <Box variant='div' mb={2}>
                    <Typography variant="subtitle1" pb={1}>Email-id</Typography>
                    <TextField
                        fullWidth
                        label="name@company.com"
                        type="email"
                        id="fullWidth"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </Box>
                <Box variant='div' mb={2}>
                    <Grid item xs={12} md={6}>
                <Typography variant="subtitle1" pb={1}>Mobile No</Typography>
                <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="country-code-label">+91</InputLabel>
                  <Select
                    labelId="country-code-label"
                    label="+91"
                    name="countryCode"
                    value="countryCode"
                    onChange={handleChange}
                  >
                    <MenuItem value="+91">+91</MenuItem>
                    <MenuItem value="+001">+001</MenuItem>
                    <MenuItem value="+002">+002</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  // name="mobileNumber"
                  // value="mobileNumber"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
                
                             </Grid>
                </Box>
                <Typography variant="subtitle1" pb={1}>Location</Typography>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Select Services</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="services"
                        value={formData.services}
                        onChange={handleChange}
                    >
                        <MenuItem value={'option1'}>Select Services 1</MenuItem>
                        <MenuItem value={'option2'}>Select Services 2</MenuItem>
                        <MenuItem value={'option3'}>Select Services 3</MenuItem>
                    </Select>
                </FormControl>
                <FormGroup pb={1}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.readyToRelocate}
                                name="readyToRelocate"
                                onChange={handleChange}
                            />
                        }
                        label="Are you ready for relocate"
                    />
                </FormGroup>
                <Box variant='div' mb={2}>
                    <Typography variant="subtitle1" pb={1}>Website</Typography>
                    <TextField
                        fullWidth
                        label="Enter the website Name"
                        type="text"
                        id="fullWidth"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                    />
                </Box>
                <Typography variant="subtitle1" pb={1}>Your Message</Typography>
                <TextField
                    id="outlined-textarea"
                    label="Multiline Placeholder"
                    placeholder="Placeholder"
                    multiline
                    fullWidth
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                />
                <Box sx={{ pt: 3 }}>
                    <Button variant="contained" size="large" color='primary' type='submit' fullWidth>Submit</Button>
                </Box>
            </Box>
        </>
    );
};

export default Partner;
