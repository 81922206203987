// import React, { useContext } from 'react';
// import { Divider, Typography, Box, Button } from '@mui/material';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormGroup from '@mui/material/FormGroup';
// import Checkbox from '@mui/material/Checkbox';
// import SidebarContext from '../SidebarContext';

// const Register = () => {
//     const { setActive } = useContext(SidebarContext);
//     const signinHandler = () =>{
//      setActive("Login");
//     }

//     return (

//         <>
//             <Typography variant="h4" component="h4" pt={2} sx={{ color: '#0D629A' }}>
//                 Welcome To Brand Liaison
//             </Typography>
//             <Typography variant="caption" >Enter the user detail here</Typography>
//             <Divider className='divider-component' sx={{ borderBottomWidth: 'medium', backgroundColor: '#1C7CBC', my: 1 }} />
//             <Box
//                 sx={{
//                     width: 500,
//                     maxWidth: '100%',
//                     pt: 2
//                 }}>
//                 <Typography variant="subtitle1">User Name</Typography>
//                 <TextField fullWidth label="Enter the user name" id="fullWidth" sx={{marginBottom:2}} />

//                 <Typography variant="subtitle1" >Email-id</Typography>
//                 <TextField fullWidth label="name@company.com" type="email" id="fullWidth"  sx={{marginBottom:2}} />

//                 <Typography variant="subtitle1" >Mobile No</Typography>
//                 <TextField fullWidth label="Enter Your Mobile No." type="number" id="fullWidth" sx={{marginBottom:2}} />

//                 <Typography variant="subtitle1" >Password</Typography>
//                 <TextField fullWidth label="***********" type="password" autoComplete="current-password" id="fullWidth" sx={{marginBottom:2}} />
//                 <Typography variant="subtitle1" >Repeat Password</Typography>
//                 <TextField fullWidth label="***********" type="password" autoComplete="current-password" id="fullWidth" sx={{marginBottom:2}} />
//                 <FormGroup sx={{marginBottom:1}} >
//                     <FormControlLabel control={<Checkbox defaultChecked />} label="I agree with all the terms & conditions" />
//                 </FormGroup>
//                 <Box sx={{ pt: 1 }}>
//                     <Button variant="contained" size="large" color='primary' fullWidth >Sign Up</Button>
//                     <Typography variant="subtitle2" sx={{ mt: 1 }} > Already have an Account? {'\u00A0'} <span className='font-bold cursor-pointer' onClick={signinHandler}>  Sign In</span> </Typography>
//                 </Box>

//             </Box>
//         </>
//     )
// }

// export default Register

// import React, { useState, useContext } from 'react';
// import { Divider, Typography, Box, Button, Grid, MenuItem, InputLabel, FormControl, Select } from '@mui/material';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormGroup from '@mui/material/FormGroup';
// import Checkbox from '@mui/material/Checkbox';
// import SidebarContext from '../SidebarContext';
// import useFetchData from '../../api/UserFetchData';
// // import useFetchData from '..useFetchData'; // Adjust the import path as needed

// const Register = () => {
//     const { setActive } = useContext(SidebarContext);
//     const [formData, setFormData] = useState({
//         userName: '',
//         email: '',
//         mobileNo: '',
//         password: '',
//         repeatPassword: '',
//         termsAccepted: true
//     });

//     const { loading, error, fetchData } = useFetchData();

//     const handleChange = (e) => {
//         const { name, value, type, checked } = e.target;
//         setFormData({
//             ...formData,
//             [name]: type === 'checkbox' ? checked : value
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (formData.password !== formData.repeatPassword) {
//             alert("Passwords do not match!");
//             return;
//         }

//         const body = {
//             name: formData.userName,
//             email: formData.email,
//             phone: formData.mobileNo,
//             password: formData.password
//         };

//         await fetchData('/api/backend/v1/register', 'POST', body);

//         if (!loading && !error) {
//             alert('Registration successful!');
//             // You can redirect or perform other actions here
//         } else if (error) {
//             alert(`Registration failed: ${error}`);
//             console.log("user registration ",error);
//         }
//     };

//     const signinHandler = () => {
//         setActive("Login");
//     };

//     return (
//         <>
//             <Typography variant="h4" component="h4" pt={2} sx={{ color: '#0D629A' }}>
//                 Welcome To Brand Liaison
//             </Typography>
//             <Typography variant="caption">Enter the user detail here</Typography>
//             <Divider className='divider-component' sx={{ borderBottomWidth: 'medium', backgroundColor: '#1C7CBC', my: 1 }} />
//             <Box
//                 component="form"
//                 onSubmit={handleSubmit}
//                 sx={{
//                     width: 500,
//                     maxWidth: '100%',
//                     pt: 2
//                 }}>
//                 <Typography variant="subtitle1" marginBottom={1}>User Name</Typography>
//                 <TextField
//                     fullWidth
//                     label="Enter the user name"
//                     name="userName"
//                     value={formData.userName}
//                     onChange={handleChange}
//                     sx={{ marginBottom: 2 }}
//                 />

//                 <Typography variant="subtitle1" marginBottom={1}>Email-id</Typography>
//                 <TextField
//                     fullWidth
//                     label="name@company.com"
//                     type="email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     sx={{ marginBottom: 2 }}
//                 />

//                 <Grid item xs={12} md={6}>
//                 <Typography variant="subtitle1" pb={1}>Mobile No</Typography>
//                 <Grid container spacing={1}>
//               <Grid item xs={12} md={3}>
//                 <FormControl fullWidth>
//                   <InputLabel id="country-code-label">+91</InputLabel>
//                   <Select
//                     labelId="country-code-label"
//                     label="+91"
//                     name="countryCode"
//                     value="countryCode"
//                     onChange={handleChange}
//                   >
//                     <MenuItem value="+91">+91</MenuItem>
//                     <MenuItem value="+001">+001</MenuItem>
//                     <MenuItem value="+002">+002</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} md={9}>
//                 <TextField
//                   fullWidth
//                   label="Mobile Number"
//                   // name="mobileNumber"
//                   // value="mobileNumber"
//                   onChange={handleChange}
//                 />
//               </Grid>
//             </Grid>

//                              </Grid>

//                 <Typography variant="subtitle1" marginBottom={1}>Password</Typography>
//                 <TextField
//                     fullWidth
//                     label="***********"
//                     type="password"
//                     name="password"
//                     value={formData.password}
//                     onChange={handleChange}
//                     autoComplete="current-password"
//                     sx={{ marginBottom: 2 }}
//                 />
//                 <Typography variant="subtitle1" marginBottom={1}>Repeat Password</Typography>
//                 <TextField
//                     fullWidth
//                     label="***********"
//                     type="password"
//                     name="repeatPassword"
//                     value={formData.repeatPassword}
//                     onChange={handleChange}
//                     autoComplete="current-password"
//                     sx={{ marginBottom: 2 }}
//                 />
//                 <FormGroup sx={{ marginBottom: 1 }}>
//                     <FormControlLabel
//                         control={<Checkbox
//                             name="termsAccepted"
//                             checked={formData.termsAccepted}
//                             onChange={handleChange}
//                         />}
//                         label="I agree with all the terms & conditions"
//                     />
//                 </FormGroup>
//                 <Box sx={{ pt: 1 }}>
//                     <Button variant="contained" size="large" color='primary' fullWidth type="submit">
//                         Sign Up
//                     </Button>
//                     <Typography variant="subtitle2" sx={{ mt: 1 }}>
//                         Already have an Account? {'\u00A0'} <span className='font-bold cursor-pointer' onClick={signinHandler}> Sign In</span>
//                     </Typography>
//                 </Box>
//             </Box>
//         </>
//     );
// };

// export default Register;

import React, { useState, useEffect, useContext } from "react";
import {
  Divider,
  Typography,
  Box,
  Button,
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";
import SidebarContext from "../SidebarContext";
import useFetchData from "../../api/UserFetchData";
const countryCode = [
    {
        countryName: "INDIA (+91)",
        countryData: '["INDIA",91]',
      },
  {
    countryName: "AFGHANISTAN (+93)",
    countryData: '["AFGHANISTAN",93]',
  },
  {
    countryName: "ALBANIA (+355)",
    countryData: '["ALBANIA",355]',
  },
  {
    countryName: "ALGERIA (+213)",
    countryData: '["ALGERIA",213]',
  },
  {
    countryName: "AMERICAN SAMOA (+1684)",
    countryData: '["AMERICAN SAMOA",1684]',
  },
  {
    countryName: "ANDORRA (+376)",
    countryData: '["ANDORRA",376]',
  },
  {
    countryName: "ANGOLA (+244)",
    countryData: '["ANGOLA",244]',
  },
  {
    countryName: "ANGUILLA (+1264)",
    countryData: '["ANGUILLA",1264]',
  },
];

const Register = () => {
  const { setActive } = useContext(SidebarContext);
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    mobileNo: "",
    password: "",
    repeatPassword: "",
    termsAccepted: true,
    countryCode: "+91",
  });
  const { loading, error, data: countries, fetchData } = useFetchData();
  const { fetchData: registerUser } = useFetchData(); // another instance for post request

  // useEffect(() => {
  //     fetchData('/api/frontend/v1/brochures/country');
  // }, [fetchData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.repeatPassword) {
      alert("Passwords do not match!");
      return;
    }

    const body = {
      name: formData.userName,
      email: formData.email,
      phone: formData.mobileNo,
      password: formData.password,
    };

    await registerUser(
      "https://api.bl-india.com/api/backend/v1/register",
      "POST",
      body
    );

    if (!loading && !error) {
      alert("Registration successful!");
      // You can redirect or perform other actions here
    } else if (error) {
      alert(`Registration failed: ${error}`);
    }
  };

  const signinHandler = () => {
    setActive("Login");
  };

  return (
    <>
      <Typography variant="h4" component="h4" pt={2} sx={{ color: "#0D629A" }}>
        Welcome To Brand Liaison
      </Typography>
      <Typography variant="caption">Enter the user detail here</Typography>
      <Divider
        className="divider-component"
        sx={{ borderBottomWidth: "medium", backgroundColor: "#1C7CBC", my: 1 }}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          width: 500,
          maxWidth: "100%",
          pt: 2,
        }}
      >
        <Typography variant="subtitle1" marginBottom={1}>
          User Name
        </Typography>
        <TextField
          fullWidth
          label="Enter the user name"
          name="userName"
          value={formData.userName}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />

        <Typography variant="subtitle1" marginBottom={1}>
          Email-id
        </Typography>
        <TextField
          fullWidth
          label="name@company.com"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          sx={{ marginBottom: 2 }}
        />

        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" pb={1}>
            Mobile No
          </Typography>
          <Grid container spacing={1}
                    sx={{ marginBottom: 2 }}

          >
            <Grid item xs={12} md={5}>
              <FormControl fullWidth>
                <InputLabel id="country-code-label">Country Code</InputLabel>
                <Select
                  labelId="country-code-label"
                  label="Country Code"
                  name="countryCode"
                  // value={formData.countryCode}
                  onChange={handleChange}
                >
                  {/* {countries && countries.map((country) => (
                                        <MenuItem key={country.code} value={country.code}>{country.code}</MenuItem>
                                    ))} */}
                  {
                    countryCode.map((country) => (
                      <MenuItem key={country.countryData} value={country.countryData}>
                        {country.countryName}  

                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                fullWidth
                label="Mobile Number"
                name="mobileNo"
                value={formData.mobileNo}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </Grid>

        <Typography variant="subtitle1" marginBottom={1}>
          Password
        </Typography>
        <TextField
          fullWidth
          label="***********"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          autoComplete="current-password"
          sx={{ marginBottom: 2 }}
        />
        <Typography variant="subtitle1" marginBottom={1}>
          Repeat Password
        </Typography>
        <TextField
          fullWidth
          label="***********"
          type="password"
          name="repeatPassword"
          value={formData.repeatPassword}
          onChange={handleChange}
          autoComplete="current-password"
          sx={{ marginBottom: 2 }}
        />
        <FormGroup sx={{ marginBottom: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleChange}
              />
            }
            label="I agree with all the terms & conditions"
          />
        </FormGroup>
        <Box sx={{ pt: 1 }}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            type="submit"
            
          >
            Sign Up
          </Button>
          <Typography variant="subtitle2" sx={{ mt: 2 ,mb:4}}>
            Already have an Account? {"\u00A0"}{" "}
            <span className="font-bold cursor-pointer" onClick={signinHandler}>
              {" "}
              Sign In
            </span>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Register;
