import React, { Suspense } from 'react'
// import HeroSection from '../Components/HeroSection/HeroSection'
// import Service from '../Components/Services/Service'
// import AboutUs from '../Components/AboutUs'
// import DownloadBrochure from '../Components/DownloadBrochure'
// import ConnectUs from '../Components/ConnectUs'
// import Workflowliaison from '../Components/WorkflowLiaison/Workflowliaison'
// import BlogPost from '../Components/BlogPost/BlogPost'
// import Testimonials from '../Components/Testimonials/Testimonials'
// import OurAssociates from '../Components/OurAssociates/OurAssociates'
const HeroSection= React.lazy(()=> import("../Components/HeroSection/HeroSection"));
const Service= React.lazy(()=> import("../Components/Services/Service"));
const AboutUs= React.lazy(()=> import("../Components/AboutUs"));
const DownloadBrochure= React.lazy(()=> import("../Components/DownloadBrochure"));
const ConnectUs= React.lazy(()=> import("../Components/ConnectUs"));
const Workflowliaison= React.lazy(()=> import("../Components/WorkflowLiaison/Workflowliaison"));
const BlogPost= React.lazy(()=> import("../Components/BlogPost/BlogPost"));
const Testimonials= React.lazy(()=> import("../Components/Testimonials/Testimonials"));
const OurAssociates= React.lazy(()=> import("../Components/OurAssociates/OurAssociates"));

const Home = () => {
  return (
    <>
      <Suspense fallback="..loading">
      <HeroSection />
      </Suspense>
      <Suspense fallback="..loading">
      <Service />
      </Suspense>
      <Suspense fallback="..loading">
      <AboutUs/>
      </Suspense>
      <Suspense fallback="..loading">
      <DownloadBrochure />
      </Suspense>
      <Suspense fallback="..loading">
      <ConnectUs />
      </Suspense>
      <Suspense fallback="..loading">
      <Workflowliaison />
      </Suspense>
      <Suspense fallback="..loading">
      <BlogPost />
      </Suspense>
      <Suspense fallback="..loading">
      <Testimonials title="Testimonials" showSection={true} url='/api/frontend/v1/home/section/testimonials' />
      </Suspense>
      <Suspense fallback="..loading">
      <OurAssociates title=" Our Associates"/>
      </Suspense>
    </>

  )
}

export default Home