import { Stack, Typography } from "@mui/material";
import React from "react";
import errorimg from "../Assets/Images/404.svg";

const Error = () => {
  return (
    <>
      <Stack>
        <img src={errorimg} alt="Error page " className={`Err404`} />
        <Typography
          variant="h3"
          color="primary"
          sx={{ margin: "0 auto", marginBottom: 5 }}
        >
          It’s look like you have lost
        </Typography>
      </Stack>
    </>
  );
};

export default Error;
