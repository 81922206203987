import React, { useState } from "react";
import "./ServicesPage.scss";
import { Box, CircularProgress, Grid, Typography, useMediaQuery } from "@mui/material";
import BrochureService from "./BrochureService";
import CallBackRequestService from "./CallBackRequestService";
import ServicesCards from "./ServicesCards";
import ServiceCategory from "./ServiceCategory";
// import ServiceCategoryDetailsPages from "./ServiceCategoryDetailsPages";
import useFetchData from "../../api/UserFetchData";
import RelatedServiceCards from "./RelatedServiceCards";

const ServicesPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const isMobile = useMediaQuery("(max-width:1024px)");
  const { data: categoryDetails, loading, error } = useFetchData(
    selectedCategory ? `/api/frontend/v1/service/service-detail/${selectedCategory}` : null
  );
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };
 
   return (
    <section>
      <Box className="AboutUsPage ServicesPage">
        <Typography
          variant="h4"
          className="AboutUsPage__title ServicesPage__title"
          textAlign={"center"}
          position={"relative"}
          marginBlock={{ sm: 2, md: 3, lg: 5 }}
        >
          Our Service
        </Typography>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            textAlign={"left"}
            className="ServicesPage__left"
          >
            {isMobile ? (
              <>
                <Box marginBlock={2}>
                  <ServiceCategory />
                </Box>
              </>
            ) : (
              <>
              <ServiceCategory onCategoryChange={handleCategoryChange} />
               <Box>
                  <BrochureService />
                  <CallBackRequestService />
                </Box>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            className="ServicesPage__cards"
            margin={isMobile ? 0 : "2"}
          >
            {/* <ServicesCards /> */}
            {selectedCategory ? (
              <ServicesCards category={selectedCategory} />
            ) : (
              <ServicesCards />
            )}
          </Grid>
          {isMobile ? (
            <>
              <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <BrochureService />
                <CallBackRequestService />
              </Box>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </section>
  );
};

export default ServicesPage;
