

import React, { useState, useEffect, useCallback } from "react";
import { MenuItem, TextField } from "@mui/material";
import "./ServicesPage.scss";
import {
  Box,
  Typography,
  Stack,
  Paper,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ServicesCards from "./ServicesCards";
import useFetchData from "../../api/UserFetchData";
import { debounce } from 'lodash';

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "80%",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "left",
  border: "1px solid #0D629A",
  borderRadius: "25px",
}));

const ServiceCategory = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [selectedOption, setSelectedOption] = useState("");
  const [serviceCategoryData, setServiceCategoryData] = useState(null);
  const [options, setOptions] = useState([]);
  const { loading, fetchData } = useFetchData();

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const fetchCategories = useCallback(
    debounce(() => {
      fetchData("api/frontend/v1/service/service-category")
        .then((data) => {
          if (data) {
            setOptions(data);
            console.log(data,"data");
          } else {
            setOptions([]); // Ensure options array is set to empty if no data
          }
        })
        .catch((error) => {
          console.error("Error fetching service categories:", error);
          setOptions([]); // Set options array to empty in case of error
        });
    }, 6000
  ), []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    if (selectedOption) {
      fetchData(`/api/frontend/v1/service/service-category/${selectedOption}`)
        .then((data) => {
          setServiceCategoryData(data);
        })
        .catch((error) => {
          console.error("Error fetching service category data:", error);
        });
    }
  }, [selectedOption, fetchData]);

  return (
    <>
      {isMobile ? (
        <>
          <TextField
            id="outlined-select-currency"
            select
            label="Categories"
            variant="outlined"
            value={selectedOption}
            onChange={handleChange}
            fullWidth
          >
            {options && options.length > 0 ? (
              options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No categories available</MenuItem>
            )}
          </TextField>
          {serviceCategoryData && <ServicesCards data={serviceCategoryData} />}
        </>
      ) : (
        <>
          <Stack
            className="ServicesPage__category cursor-pointer"
            marginBottom={{ xs: 1, sm: 1, md: 2 }}
            direction={{ xs: "column", sm: "row", md: "row" }}
          >
            <DemoPaper square={true}>
              <Typography variant="h6" className="" textAlign="left">
                Service Categories
                {options && options.length > 0 ? (
                  options.map((option) => (
                    <React.Fragment key={option.value}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingBlock: "10px",
                        }}
                        onClick={() => setSelectedOption(option.value)}
                      >
                        <Typography variant="caption">{option.label}</Typography>
                        <Typography>(02)</Typography>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  ))
                ) : (
                  <Typography>No categories available</Typography>
                )}
              </Typography>
            </DemoPaper>
          </Stack>
          {serviceCategoryData && <ServicesCards data={serviceCategoryData} />}
        </>
      )}
    </>
  );
};

export default ServiceCategory;
