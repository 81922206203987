
import React from 'react';
import { Chip, Stack, Typography, Grid, Button, Paper, Box, Rating, CircularProgress } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import testimonialimg from '../../Assets/Images/testimonials.webp';
import backward from "../../Assets/Images/icons/backward.svg";
import forward from "../../Assets/Images/icons/forward.svg";
import StarIcon from '@mui/icons-material/Star';
import UserFetchData from "../../api/UserFetchData"
import '../Testimonials/Testimonials.scss';

const Testimonials = ({ title, showSection,url }) => {
 
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const { data, loading, error } = UserFetchData(url);
    if (loading) {
      return <CircularProgress/>;
    }
    if (error) {
      return <div>Error:{error}</div>;
    }
    const maxSteps = data.length;

    return (
        <Stack className="section-full" direction={'column'}>
            <Box>
                <Chip label="kNOW ABOUT CLIENT" variant="outlined" />
                <Typography variant='h3'  >
                    {title}</Typography>
            </Box>
            <Stack  >
                <Grid container spacing={3} sx={{ justifyContent: 'space-between' }} >
                    <Grid item xs={12} sm={12} md={5} className="">
                        <img src={testimonialimg} alt="Testimonials" loading="lazy" className='testimonials-img' />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} sx={{ pt: 0 }}>
                        <Box sx={{ maxWidth: 400, flexGrow: 1 }}  className='testomonial-data'>
                            <Paper
                                square
                                elevation={0}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: 50,
                                    pl: 2,
                                    bgcolor: 'background.default',
                                }}
                            >
                            </Paper>
                            {showSection && (
                                <Box
                                    sx={{
                                        width: 200,
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Rating
                                        name="text-feedback"
                                        style={{paddingLeft:'2rem'}}
                                        value= {data[activeStep]?.testimonial_rating}
                                        // value={data.testimonial_rating}
                                        readOnly
                                        precision={0.5}
                                        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                                    />
                                </Box>
                            )}
                            <Typography sx={{ maxWidth: 400, width: '100%' }} paddingLeft={{sm: 2 ,md: 3}} paddingTop={{sm:1 ,md:2}} variant="subtitle2">
                                {/* {steps[activeStep].description} */}
                                {data[activeStep]?.testimonial_content}
                            </Typography>
                            <Typography sx={{ pt: 2 }} paddingLeft={{sm:2,md:3}} variant="h5">
                                {/* {steps[activeStep].label} */}
                                {data[activeStep]?.testimonial_name}
                                </Typography>
                            {/* <Typography sx={{ pt: 2 }} paddingLeft={{sm:2,md:3}} variant="h5">
                                {data[activeStep]?.testimonial_designation}
                                </Typography> */}
                            <MobileStepper
                            
                                variant="text"
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    <Button
                                        size="small"
                                        onClick={handleNext}
                                        disabled={activeStep === maxSteps - 1}
                                    >


                                        {theme.direction === 'rtl' ? (
                                            <img src={backward} alt="forward" loading='lazy' width={50}/>

                                        ) : (
                                            <img src={forward} alt="forward" loading='lazy' width={50} />

                                        )}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        {theme.direction === 'rtl' ? (
                                            <img src={forward} alt="forward" loading='lazy' width={50} />
                                        ) : (
                                            <img src={backward} alt="forward" loading='lazy' width={50}/>
                                        )}

                                    </Button>
                                }
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Stack>

        </Stack>
    )
}

export default Testimonials