import { React, useState } from "react";
import "./ServicesPage";
import "./ServicesDetailpage.scss";
import {
  Box,
  // CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import serviceimage from "../../Assets/Images/bis-crs.webp";
import BrochureService from "./BrochureService";
import CallBackRequestService from "./CallBackRequestService";
import ServicesDetailsVerticalTab from "./ServiceDetailsVerticalTab";
import { CheckCircleOutline } from "@mui/icons-material";
import RelatedServiceCards from "./RelatedServiceCards";
// import UserFetchData from "../../api/UserFetchData";

const ServicesDetailpage = () => {

  const isMobile = useMediaQuery("(max-width:767px)");

  const [value, setValue] = useState(0);
  const handletabchange = (newvalue) => {
    setValue(newvalue);
  };
  const [tabValue, setTabValue] = useState(0);
  const [tabContent, setTabContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    // const { data, loading, error } = UserFetchData('api/frontend/v1/home/service/2');
    // if (loading) {
    //   return <CircularProgress />;
    // }
    // if (error) {
    //   return <div>Error: {error}</div>;
    // }

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };
  return (
    <section>
      <Box className="AboutUsPage ServicesDetailpage">
        <img src={serviceimage} alt="" className="serviceimg" loading="lazy" />
        <Typography
          variant="h4"
          className="AboutUsPage__title ServicesDetailpage__title"
          textAlign={"center"}
          position={"relative"}
          marginBottom={{ sm: 1, md: 2, lg: 3 }}
        >
          Service Name
          {/* {data.title} */}
        </Typography>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            textAlign={"left"}
            className="ServicesDetailpage__category"
          >
            {/* <TabsComponent activeTab={activeTab} handleTabChange={handleTabChange} handleTabClick={handleTabClick} /> */}
            <ServicesDetailsVerticalTab
              handletabchange={handletabchange}
              value={value}
            />
            {isMobile ? (
              <></>
            ) : (
              <>
                <BrochureService />
                <CallBackRequestService />
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            className="ServicesDetailpage__cards"
          >
            {/* <Box p={0}>
              <TabPanel value={value} index={0}>
                <Typography variant="h5">Introduction</Typography>
                <Typography>
                  Bureau of Indian Standards (BIS)serves as India's national
                  standards body. It operates under the authority of the Indian
                  government and is responsible for developing and promoting
                  technical standards across various industries. In 2012,
                  the Compulsory Registration Scheme (CRS) was introduced as a
                  significant component under BIS. CRS mandates the
                  certification of specific product categories, ensuring their
                  adherence to predefined safety and quality standards. This
                  scheme is a pivotal initiative aimed at safeguarding
                  consumers' interests by regulating products that have the
                  potential to impact safety and well-being.
                </Typography>
                <Typography>
                  Bureau of Indian Standards (BIS)serves as India's national
                  standards body. It operates under the authority of the Indian
                  government and is responsible for developing and promoting
                  technical standards across various industries. In 2012,
                  the Compulsory Registration Scheme (CRS) was introduced as a
                  significant component under BIS. CRS mandates the
                  certification of specific product categories, ensuring their
                  adherence to predefined safety and quality standards. This
                  scheme is a pivotal initiative aimed at safeguarding
                  consumers' interests by regulating products that have the
                  potential to impact safety and well-being.
                </Typography>
                <Typography variant="h6">
                  Importance of BIS/CRS Registration
                </Typography>
                <Typography>
                  Bureau of Indian Standards (BIS)serves as India's national
                  standards body. It operates under the authority of the Indian
                  government and is responsible for developing and promoting
                  technical standards across various industries. In 2012,
                  the Compulsory Registration Scheme (CRS) was introduced as a
                  significant component under BIS. CRS mandates the
                  certification of specific product categories, ensuring their
                  adherence to predefined safety and quality standards. This
                  scheme is a pivotal initiative aimed at safeguarding
                  consumers' interests by regulating products that have the
                  potential to impact safety and well-being.
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <nav aria-label="main mailbox folders">
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText>
                            BIS/CRS Registration is mandatory for foreign
                            manufacturers, ensuring legal compliance and
                            preventing entry barriers into the Indian market.
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText>
                            Compliance reduces the risk of product recalls,
                            safety issues and legal consequences, safeguarding a
                            manufacturer's reputation and financial interests.
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText>
                            Compliance reduces the risk of product recalls,
                            safety issues and legal consequences, safeguarding a
                            manufacturer's reputation and financial interests.
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText>
                            Compliance reduces the risk of product recalls,
                            safety issues and legal consequences, safeguarding a
                            manufacturer's reputation and financial interests.
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CheckCircleOutline />
                          </ListItemIcon>
                          <ListItemText>
                            Compliance reduces the risk of product recalls,
                            safety issues and legal consequences, safeguarding a
                            manufacturer's reputation and financial interests.
                          </ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </nav>
                </Box>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Typography variant="h5">Content for Favorites Tab</Typography>
                <Typography>
                  Sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.
                </Typography>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Typography variant="h5">Content for Profile Tab</Typography>
                <Typography>
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
              </TabPanel>
            </Box> */}
            <RelatedServiceCards />

            {isMobile ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <BrochureService />
                <CallBackRequestService />
              </Box>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>
    </section>
  );
};

export default ServicesDetailpage;

// import React, { useState } from "react";
// import {
//   Box,
//   Grid,
//   Typography,
//   useMediaQuery,
//   CircularProgress,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
// } from "@mui/material";
// import serviceimage from "../../Assets/Images/bis-crs.webp";
// import BrochureService from "./BrochureService";
// import CallBackRequestService from "./CallBackRequestService";
// import ServicesDetailsVerticalTab from "./ServiceDetailsVerticalTab";
// import RelatedServiceCards from "./RelatedServiceCards";
// import useFetchData from "../../api/UserFetchData";
// import "./ServicesDetailpage.scss";
// import { useNavigate } from "react-router-dom";

// const ServicesDetailpage = () => {
//   const isMobile = useMediaQuery("(max-width:767px)");
//   const [value, setValue] = useState(0);
//   const [selectedRow, setSelectedRow] = useState(null); // State to track selected row
//   const navigate = useNavigate();

//   const handletabchange = (newvalue) => {
//     setValue(newvalue);
//   };

//   const handleRowClick = (row) => {
//     setSelectedRow(row);
//     navigate(`/productlist`);

//   };

//   const TabPanel = (props) => {
//     const { children, value, index, apiEndpoint, ...other } = props;
//     const { data, loading, error } = useFetchData(apiEndpoint);

//     if (loading) {
//       return <CircularProgress />;
//     }
//     if (error) {
//       return <div>Error: {error}</div>;
//     }

//     return (
//       <div
//         role="tabpanel"
//         hidden={value !== index}
//         id={`vertical-tabpanel-${index}`}
//         aria-labelledby={`vertical-tab-${index}`}
//         {...other}
//       >
//         {value === index && <Box>{children(data)}</Box>}
//       </div>
//     );
//   };

//   return (
//     <section>
//       <Box className="AboutUsPage ServicesDetailpage">
//         <img src={serviceimage} alt="" className="serviceimg" loading="lazy" />
//         <Typography
//           variant="h4"
//           className="AboutUsPage__title ServicesDetailpage__title"
//           textAlign={"center"}
//           position={"relative"}
//           marginBottom={{ sm: 1, md: 2, lg: 3 }}
//         >
//           Service Name
//         </Typography>
//         <Grid container>
//           <Grid
//             item
//             xs={12}
//             sm={4}
//             md={4}
//             textAlign={"left"}
//             className="ServicesDetailpage__category"
//           >
//             <ServicesDetailsVerticalTab
//               handletabchange={handletabchange}
//               value={value}
//             />
//             {!isMobile && (
//               <>
//                 <BrochureService />
//                 <CallBackRequestService />
//               </>
//             )}
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             sm={8}
//             md={8}
//             className="ServicesDetailpage__cards"
//           >
           
//             <Box p={0}>
//               <TabPanel
//                 value={value}
//                 index={0}
//                 apiEndpoint="/api/frontend/v1/service/1/service-section/introduction"
//               >
//                 {(data) => (
//                   <>
//                     {/* <Typography ></Typography> */}
//                     <Typography variant="h5">
//                       {data.service_section_name}
//                     </Typography>
//                     {/* <Typography variant="h6">Importance of BIS/CRS Registration</Typography> */}
//                     <Typography>{data.service_section_content}</Typography>
//                     <Box sx={{ width: "100%" }}>
//                       {/* <nav aria-label="main mailbox folders">
//                         <List>
//                           {data.points.map((point, index) => (
//                             <ListItem disablePadding key={index}>
//                               <ListItemButton>
//                                 <ListItemIcon>
//                                   <CheckCircleOutline />
//                                 </ListItemIcon>
//                                 <ListItemText>{point}</ListItemText>
//                               </ListItemButton>
//                             </ListItem>
//                           ))}
//                         </List>
//                       </nav> */}
//                     </Box>
//                   </>
//                 )}
//               </TabPanel>
//               <TabPanel
//                 value={value}
//                 index={1}
//                 apiEndpoint="/api/frontend/v1/service/1/service-section/required-documents"
//               >
//                 {(data) => (
//                   <>
//                     <Typography variant="h5">{data.service_section_name}</Typography>
//                     <Typography>{data.service_section_content}</Typography>
//                   </>
//                 )}
//               </TabPanel>
//               <TabPanel
//                 value={value}
//                 index={2}
//                 apiEndpoint="/api/frontend/v1/service/1/service-section/standard-costing-and-timeline"
//               >
//                 {(data) => (
//                   <>
//                   <Typography variant="h5">{data.service_section_name}</Typography>
//                   <Typography>{data.service_section_content}</Typography>
//                   </>
//                 )}
//               </TabPanel>
            
             
//               <TabPanel
//                 value={value}
//                 index={3}
//                 apiEndpoint="/api/frontend/v1/service/1/service-section/registration-process"
//               >
//                 {(data) => (
//                   <>
//                   <Typography variant="h5">{data.service_section_name}</Typography>
//                   <Typography>{data.service_section_content}</Typography>
//                   </>
//                 )}
//               </TabPanel>
//               <TabPanel
//                 value={value}
//                 index={4}
//                 apiEndpoint="/api/frontend/v1/service/1/service-section/validity-renewal"
//               >
//                 {(data) => (
//                   <>
//                   {console.log(data, "validity-renewal")}
//                   <Typography variant="h5">{data.service_section_name}</Typography>
//                   <Typography>{data.service_section_content}</Typography>
//                   </>
//                 )}
//               </TabPanel>
//               <TabPanel
//                 value={value}
//                 index={5}
//                 apiEndpoint="/api/frontend/v1/service/1/service-section/validity-renewal"
//               >
//                 {(data) => (
//                   <>
//                   <Typography variant="h5">{data.service_section_name}</Typography>
//                   <Typography>{data.service_section_content}</Typography>
//                   </>
//                 )}
//               </TabPanel>
//               <TabPanel
//                 value={value}
//                 index={6}
//                 apiEndpoint="/api/frontend/v1/service/2/service-section/mandatory-product-list"
//               >
//                 {(data) => (
//                   <>
//                     <Typography variant="h5" marginBottom={3}>
//                       Mandatory Product list
//                     </Typography>
//                     <TableContainer component={Paper}>
//                       <Table sx={{ minWidth: 650 }} aria-label="simple table">
//                         <TableHead>
//                           <TableRow>
//                             <TableCell>Sno.</TableCell>
//                             <TableCell align="right">Product Name</TableCell>
//                             <TableCell align="right">Product Category</TableCell>
//                           </TableRow>
//                         </TableHead>
//                         <TableBody>
//                           {data.map((row) => (
//                             <TableRow
//                               key={row.name}
//                               sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
//                               onClick={() => handleRowClick(row)} // Handle row click
//                               style={{ cursor: 'pointer' }} // Change cursor style to pointer
//                             >
//                               <TableCell component="th" scope="row">
//                                 {row.product_order}
//                               </TableCell>
//                               <TableCell align="right">{row.product_name}</TableCell>
//                               <TableCell align="right">{row.product_technical_name}</TableCell>
//                             </TableRow>
//                           ))}
//                         </TableBody>
//                       </Table>
//                     </TableContainer>
//                     {selectedRow && (
//                       <Box mt={3}>
//                         <Typography variant="h6">Selected Product Details</Typography>
//                         <Typography>Product Name: {selectedRow.product_name}</Typography>
//                         <Typography>Product Category: {selectedRow.product_technical_name}</Typography>
//                         {/* Render additional component or details here */}
//                       </Box>
//                     )}
//                   </>
//                 )}
//               </TabPanel>
//             </Box>
//             <RelatedServiceCards />
//             {isMobile && (
//               <Box sx={{ display: "flex", flexDirection: "column" }}>
//                 <BrochureService />
//                 <CallBackRequestService />
//               </Box>
//             )}
//           </Grid>
//         </Grid>
//       </Box>
//     </section>
//   );
// };

// export default ServicesDetailpage;

