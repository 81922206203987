import { React, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SidebarContext from "../../Components/SidebarContext";
import Partner from "../../Components/Sidebar/Partner";
import Register from "../../Components/Sidebar/Register";
import Callback from "../../Components/Sidebar/Callback";
import DownloadBrochure from "../../Components/Sidebar/DownloadBrochure";
import "./Sidebar.scss";
import Login from "../../Components/Sidebar/Login";
import JobApplication  from "../Sidebar/JobApplication";


function Sidebar({ isOpen, toggleSidebar, children }) {
  const { activeSection, setActive } = useContext(SidebarContext);
  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleSidebar}
        className={`Siderbar__Component`}
      >
        <div className="Siderbar__Component--close">
          <CancelOutlinedIcon
            onClick={toggleSidebar}
            className="close-icon"
            color="primary"
            fontSize="medium"
          />
        </div>
        <Box sx={{ pt: 5, px: 3 }}>
              {/* Render the appropriate section based on activeSection */}
          {activeSection === "Login" && <Login />}
          {activeSection === "Register" && <Register />}
          {activeSection === "Partner" && <Partner />}
          {activeSection === "Callback" && <Callback />}
          {activeSection === "DownloadBrochure" && <DownloadBrochure />}
          {activeSection === "JobApplication" && <JobApplication />}

        </Box>
      </Drawer>
    </>
  );
}

export default Sidebar;
