import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React from "react";
import careers_bg from "../Assets/Images/careers_bg.jpg";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import "./Careers.scss";
// import carrow from "../Assets/Images/careers-arrow.svg"
import ConnectUs from "../Components/ConnectUs";
import { Link } from "react-router-dom";

const Careers = () => {
  // const [selectedValue, setSelectedValue] = useState("");

  const [age, setAge] = React.useState("");
  const [department, setDepartment] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChange1 = (event) => {
    setDepartment(event.target.value);
  };

  return (
    <>
      <Box className="AboutUsPage CareersPage">
        <Box className="header-banner"><Typography
          variant="h4"
          className="AboutUsPage__title CareersPage__title"
          textAlign={"center"}
          position={"relative"}
        >
          Careers
        </Typography></Box>

        {/* <img src={careers_bg} alt="background" className="careers_background" /> */}
        <Stack position={"relative"} className="section-inline">
          <Grid
            container
            spacing={1}
            className="CareersPage__body"
          >
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Job Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="JobType"
                    onChange={handleChange}
                    fullWidth
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Department
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={department}
                    label="Department"
                    onChange={handleChange1}
                    fullWidth
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Stack>
                {" "}
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <SearchIcon sx={{ color: "action.active", mr: 1 }} />
                    ),
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack className="section-inline">
          <Typography variant="h4" paddingBlock={1}>Current Opening</Typography>
          <Grid container spacing={3} className="CareersPage__body">
            <Grid item xs={12} sm={6} md={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="h6">
                    Senior Web Developer
                  </Typography>
                  <Typography color="textSecondary">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Consequatur ducimus exercitationem laborum enim consequuntur
                    labore, perferendis voluptates reprehenderit, numquam
                    dolorem, commodi amet sed voluptatum. Pariatur aut explicabo
                    error odit assumenda.
                  </Typography>
                  <Link to="/careersdetails">
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "15px", marginTop: "15px" }}
                  >
                    Learn More
                  </Button></Link>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="h6">
                    Senior Web Developer
                  </Typography>
                  <Typography color="textSecondary">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Consequatur ducimus exercitationem laborum enim consequuntur
                    labore, perferendis voluptates reprehenderit, numquam
                    dolorem, commodi amet sed voluptatum. Pariatur aut explicabo
                    error odit assumenda.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "15px", marginTop: "15px" }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="h6">
                    Senior Web Developer
                  </Typography>
                  <Typography color="textSecondary">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Consequatur ducimus exercitationem laborum enim consequuntur
                    labore, perferendis voluptates reprehenderit, numquam
                    dolorem, commodi amet sed voluptatum. Pariatur aut explicabo
                    error odit assumenda.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "15px", marginTop: "15px" }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="h6">
                    Senior Web Developer
                  </Typography>
                  <Typography color="textSecondary">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Consequatur ducimus exercitationem laborum enim consequuntur
                    labore, perferendis voluptates reprehenderit, numquam
                    dolorem, commodi amet sed voluptatum. Pariatur aut explicabo
                    error odit assumenda.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "15px", marginTop: "15px" }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="CareersPage__body" mt={2}>
            <Grid item xs={12} sm={6} md={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="h6">
                    Senior Web Developer
                  </Typography>
                  <Typography color="textSecondary">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Consequatur ducimus exercitationem laborum enim consequuntur
                    labore, perferendis voluptates reprehenderit, numquam
                    dolorem, commodi amet sed voluptatum. Pariatur aut explicabo
                    error odit assumenda.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "15px", marginTop: "15px" }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="h6">
                    Senior Web Developer
                  </Typography>
                  <Typography color="textSecondary">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Consequatur ducimus exercitationem laborum enim consequuntur
                    labore, perferendis voluptates reprehenderit, numquam
                    dolorem, commodi amet sed voluptatum. Pariatur aut explicabo
                    error odit assumenda.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "15px", marginTop: "15px" }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="h6">
                    Senior Web Developer
                  </Typography>
                  <Typography color="textSecondary">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Consequatur ducimus exercitationem laborum enim consequuntur
                    labore, perferendis voluptates reprehenderit, numquam
                    dolorem, commodi amet sed voluptatum. Pariatur aut explicabo
                    error odit assumenda.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "15px", marginTop: "15px" }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" component="h6">
                    Senior Web Developer
                  </Typography>
                  <Typography color="textSecondary">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Consequatur ducimus exercitationem laborum enim consequuntur
                    labore, perferendis voluptates reprehenderit, numquam
                    dolorem, commodi amet sed voluptatum. Pariatur aut explicabo
                    error odit assumenda.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: "15px", marginTop: "15px" }}
                  >
                    Learn More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Box
            mt={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{ marginRight: "15px", borderRadius: "15px" }}
            >
              Join Us
            </Button>
            <Button variant="contained" sx={{ borderRadius: "15px" }}>
              View All Job Opening
            </Button>
          </Box>
        </Stack>
        <Box className="section-inline">
          <Typography variant="h4">Learn our Recruitment process</Typography>
          <Box 
           marginTop={{sm:2,md:3}}
            flexDirection={{ xs:'column',sm:'column', md: "row" }}
            sx={{
              display: "flex",
              // justifyContent: 'center',
              // alignItems: "center",
              position:"relative"
            }}
          >
            <Button variant="contained" className="process_box">
              CV Submission
            </Button>
            {/* <img src={carrow}  className="careers_arrow1" alt="process-arrow"/> */}
            <Button variant="contained" className="process_box">
              Phone screening
            </Button>
            {/* <img src={carrow}  className="careers_arrow2" alt="process-arrow"/> */}
            <Button variant="contained" className="process_box">
              Technical Round
            </Button>
          </Box>
        </Box>
      </Box>
      <ConnectUs />
    </>
  );
};

export default Careers;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import {
//   Box,
//   FormControl,
//   Grid,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
//   Typography,
//   Stack,
//   Card,
//   CardContent,
//   Button,
// } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import { Link } from 'react-router-dom';
// import careers_bg from '../Assets/Images/careers_bg.png';
// import ConnectUs from '../Components/ConnectUs';
// import './Careers.scss';

// const Careers = () => {
//   const [jobType, setJobType] = useState('');
//   const [department, setDepartment] = useState('');
//   const [jobs, setJobs] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   const handleChangeJobType = (event) => {
//     setJobType(event.target.value);
//   };

//   const handleChangeDepartment = (event) => {
//     setDepartment(event.target.value);
//   };

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value);
//   };

//   useEffect(() => {
//     const fetchJobs = async () => {
//       try {
//         const response = await axios.get('/api/frontend/v1/careers');
//         setJobs(response.data);
//       } catch (error) {
//         console.error('Error fetching jobs:', error);
//       }
//     };
    
//     fetchJobs();
//   }, []);

//   return (
//     <>
//       <Box className="AboutUsPage CareersPage">
//         <Typography
//           variant="h4"
//           className="AboutUsPage__title CareersPage__title"
//           textAlign={"center"}
//           position={"relative"}
//         >
//           Careers
//         </Typography>

//         <img src={careers_bg} alt="background" className="careers_background" />
//         <Stack position={"relative"} className="section-inline">
//           <Grid container spacing={1} className="CareersPage__body">
//             <Grid item xs={12} sm={6} md={3}>
//               <Box sx={{ minWidth: 120 }}>
//                 <FormControl fullWidth>
//                   <InputLabel id="job-type-label">Job Type</InputLabel>
//                   <Select
//                     labelId="job-type-label"
//                     id="job-type-select"
//                     value={jobType}
//                     label="JobType"
//                     onChange={handleChangeJobType}
//                     fullWidth
//                   >
//                     <MenuItem value="full-time">Full-time</MenuItem>
//                     <MenuItem value="part-time">Part-time</MenuItem>
//                     <MenuItem value="internship">Internship</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Box>
//             </Grid>
//             <Grid item xs={12} sm={6} md={3}>
//               <Box sx={{ minWidth: 120 }}>
//                 <FormControl fullWidth>
//                   <InputLabel id="department-label">Department</InputLabel>
//                   <Select
//                     labelId="department-label"
//                     id="department-select"
//                     value={department}
//                     label="Department"
//                     onChange={handleChangeDepartment}
//                     fullWidth
//                   >
//                     <MenuItem value="engineering">Engineering</MenuItem>
//                     <MenuItem value="marketing">Marketing</MenuItem>
//                     <MenuItem value="sales">Sales</MenuItem>
//                   </Select>
//                 </FormControl>
//               </Box>
//             </Grid>
//             <Grid item xs={12} sm={6} md={6}>
//               <Stack>
//                 <TextField
//                   fullWidth
//                   variant="outlined"
//                   placeholder="Search"
//                   value={searchTerm}
//                   onChange={handleSearchChange}
//                   InputProps={{
//                     startAdornment: (
//                       <SearchIcon sx={{ color: 'action.active', mr: 1 }} />
//                     ),
//                   }}
//                 />
//               </Stack>
//             </Grid>
//           </Grid>
//         </Stack>
//         <Stack className="section-inline">
//           <Typography variant="h4" paddingBlock={1}>Current Openings</Typography>
//           <Grid container spacing={3} className="CareersPage__body">
//             {jobs.map((job) => (
//               <Grid item xs={12} sm={6} md={3} key={job.id}>
//                 <Card variant="outlined">
//                   <CardContent>
//                     <Typography variant="h6" component="h6">
//                       {job.title}
//                     </Typography>
//                     <Typography color="textSecondary">
//                       {job.description}
//                     </Typography>
//                     <Link to={`/careersdetails/${job.id}`}>
//                       <Button
//                         variant="contained"
//                         color="primary"
//                         sx={{ borderRadius: '15px', marginTop: '15px' }}
//                       >
//                         Learn More
//                       </Button>
//                     </Link>
//                   </CardContent>
//                 </Card>
//               </Grid>
//             ))}
//           </Grid>
//           <Box
//             mt={2}
//             sx={{
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//             }}
//           >
//             <Button
//               variant="outlined"
//               color="primary"
//               sx={{ marginRight: '15px', borderRadius: '15px' }}
//             >
//               Join Us
//             </Button>
//             <Button variant="contained" sx={{ borderRadius: '15px' }}>
//               View All Job Openings
//             </Button>
//           </Box>
//         </Stack>
//         <Box className="section-inline">
//           <Typography variant="h4">Learn our Recruitment process</Typography>
//           <Box 
//             marginTop={{sm:2,md:3}}
//             flexDirection={{ xs:'column',sm:'column', md: "row" }}
//             sx={{
//               display: "flex",
//               position:"relative"
//             }}
//           >
//             <Button variant="contained" className="process_box">
//               CV Submission
//             </Button>
//             <Button variant="contained" className="process_box">
//               Phone screening
//             </Button>
//             <Button variant="contained" className="process_box">
//               Technical Round
//             </Button>
//           </Box>
//         </Box>
//       </Box>
//       <ConnectUs />
//     </>
//   );
// };

// export default Careers;
