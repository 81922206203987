import {
    Box,
    Typography,
    Stack,
    Paper,
  } from "@mui/material";import React from 'react';
import { styled } from "@mui/material/styles";
import Chip from '@mui/material/Chip';



const DemoPaper = styled(Paper)(({ theme }) => ({
    width: "80%",
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: "left",
    border: "1px solid #0D629A",
    borderRadius: "25px",
  }));
  

const RecentTag = () => {
  return (
<>
<Stack
        className="ServicesPage__category"
        marginBottom={{ xs: 1, sm: 1, md: 2 }}
        direction={{ xs: "column", sm: "row" }}
        // spacing={{ xs: 1, sm: 1, md: 2 }}
      >
        <DemoPaper square={true}>
          <Typography variant="h5" className="" textAlign="left">
          Tags          
            <Box
             
            >
               <Stack direction="row" spacing={1}  sx={{
                display: "flex",
                alignItems: "center",
                paddingBlock: "20px",
                flexWrap:"wrap",
                gap:1
              }}>
      <Chip label="Laptop" component="a" href="#basic-chip" color="primary"
clickable />
      <Chip
        label="Laptop"
        component="a"
        href="#basic-chip"
        variant="Contained"
        color="primary"

        clickable
      />
       <Chip label="Laptop" component="a" href="#basic-chip"         color="primary"
 clickable />
      <Chip
        label="Computer"
        component="a"
        href="#basic-chip"
        variant="Contained"
        color="primary"
        clickable
      />
       <Chip label="Laptop" component="a" href="#basic-chip"         color="primary"
 clickable />
      <Chip
        label="Food"
        component="a"
        href="#basic-chip"
        variant="Contained"
        color="primary"

        clickable
      />
    </Stack>
            </Box>
         
          </Typography>
        </DemoPaper>
      </Stack>
</>  )
}

export default RecentTag