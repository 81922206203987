import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import ServiceCategory from "./ServicePages/ServiceCategory";
import BrochureService from "./ServicePages/BrochureService";
import CallBackRequestService from "./ServicePages/CallBackRequestService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Download, Share, Visibility } from "@mui/icons-material";
import "./Notification.scss";

const NotificationDetails = () => {
  const isMobile = useMediaQuery("(max-width:767px)");

  return (
    <>
      <Box className="AboutUsPage Notification NotificationDetails">
        <Typography
          variant="h4"
          className="AboutUsPage__title Notification__title"
          textAlign={"center"}
          position={"relative"}
          m={3}
        >
          Notifications Details
        </Typography>
        <Grid
          container
          spacing={{ xs: 1, sm: 2, md: 3 }}
          padding={{ xs: 2, sm: 2, md: 3 }}
        >
          {isMobile ? (
            <Grid item xs={12} marginBlock={1}>
              <ServiceCategory />
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              textAlign={"left"}
              className="Notification__left"
            >
              <ServiceCategory />
              <BrochureService />
              <CallBackRequestService />
            </Grid>
          )}

          <Grid item xs={12} sm={8} md={8} className="">
            <div className="Notification__accordians">
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="subtitle1">
                    Abeyance of Market Surveillance of Products Covered Under
                    Compulsory Registration Schemet
                    <Typography variant="body1">
                      (Abeyance of Market Surveillance of Products Covered Under
                      Compulsory Registration Scheme)
                    </Typography>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="h6">
                    Industrial Notifications for BIS / CRS Registration
                    <Typography>
                      In reference to our circular dated April 28, 2022,
                      addressing the subject in question. We would like to
                      inform you that MeitY has communicated their intent to
                      continue routine surveillance of products falling under
                      the CRS (Compulsory Registration Scheme). They have also
                      recommended that BIS temporarily halt its surveillance
                      activities. In line with MeitY's guidance, the
                      surveillance of products within their purview has been
                      temporarily suspended. Please note that BIS had already
                      commenced the process of collecting product samples before
                      this suspension took effect. Consequently, samples from
                      selected consumer items have been obtained and are
                      currently undergoing testing in laboratories recognized by
                      BIS.For more details, refer the document:
                    </Typography>
                    <Typography>
                      In reference to our circular dated April 28, 2022,
                      addressing the subject in question. We would like to
                      inform you that MeitY has communicated their intent to
                      continue routine surveillance of products falling under
                      the CRS (Compulsory Registration Scheme). They have also
                      recommended that BIS temporarily halt its surveillance
                      activities. In line with MeitY's guidance, the
                      surveillance of products within their purview has been
                      temporarily suspended. Please note that BIS had already
                      commenced the process of collecting product samples before
                      this suspension took effect. Consequently, samples from
                      selected consumer items have been obtained and are
                      currently undergoing testing in laboratories recognized by
                      BIS.For more details, refer the document:
                    </Typography>
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Visibility sx={{ marginRight: "20px" }} />
                    <Download sx={{ marginRight: "20px" }} />
                    <Share sx={{ marginRight: "20px" }} />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>
           <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "40px",
              }}
            >
            </Box>
            {isMobile ? (
              <Box marginBlock={3}>
                <Grid item xs={12}>
                  <BrochureService />
                  <CallBackRequestService />
                </Grid>
              </Box>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NotificationDetails;





// import React from "react";
// import { Box, Grid, Typography, useMediaQuery, CircularProgress, Alert } from "@mui/material";
// import ServiceCategory from "./ServicePages/ServiceCategory";
// import BrochureService from "./ServicePages/BrochureService";
// import CallBackRequestService from "./ServicePages/CallBackRequestService";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Download, Share, Visibility } from "@mui/icons-material";
// import "./Notification.scss";
// import useFetchData from '../api/UserFetchData';
// const NotificationDetails = () => {
//   const isMobile = useMediaQuery("(max-width:767px)");
//   const { data, loading, error } = useFetchData('/api/frontend/v1/notification/category/2');

//   if (loading) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
//         <CircularProgress />
//       </Box>
//     );
//   }

//   if (error) {
//     return (
//       <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
//         <Alert severity="error">Failed to load notifications: {error.message}</Alert>
//       </Box>
//     );
//   }

//   return (
//     <Box className="AboutUsPage Notification NotificationDetails">
//       <Typography
//         variant="h4"
//         className="AboutUsPage__title Notification__title"
//         textAlign={"center"}
//         position={"relative"}
//         m={3}
//       >
//         Notifications Details
//       </Typography>
//       <Grid
//         container
//         spacing={{ xs: 1, sm: 2, md: 3 }}
//         padding={{ xs: 2, sm: 2, md: 3 }}
//       >
//         {isMobile ? (
//           <Grid item xs={12} marginBlock={1}>
//             <ServiceCategory />
//           </Grid>
//         ) : (
//           <Grid
//             item
//             xs={12}
//             sm={4}
//             md={4}
//             textAlign={"left"}
//             className="Notification__left"
//           >
//             <ServiceCategory />
//             <BrochureService />
//             <CallBackRequestService />
//           </Grid>
//         )}

//         <Grid item xs={12} sm={8} md={8} className="">
//           <div className="Notification__accordians">
//             {data.map((notification) => (
//               <Accordion key={notification.id} defaultExpanded>
//                 <AccordionSummary
//                   expandIcon={<ExpandMoreIcon />}
//                   aria-controls={`panel${notification.id}-content`}
//                   id={`panel${notification.id}-header`}
//                 >
//                   <Typography variant="subtitle1">
//                     {notification.title}
//                     <Typography variant="body1">
//                       {notification.subtitle}
//                     </Typography>
//                   </Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                   <Typography variant="h6">
//                     {notification.contentTitle}
//                     <Typography>
//                       {notification.content}
//                     </Typography>
//                   </Typography>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "flex-end",
//                       alignItems: "center",
//                     }}
//                   >
//                     <Visibility sx={{ marginRight: "20px" }} />
//                     <Download sx={{ marginRight: "20px" }} />
//                     <Share sx={{ marginRight: "20px" }} />
//                   </Box>
//                 </AccordionDetails>
//               </Accordion>
//             ))}
//           </div>
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "flex-end",
//               alignItems: "center",
//               marginTop: "40px",
//             }}
//           >
//           </Box>
//           {isMobile ? (
//             <Box marginBlock={3}>
//               <Grid item xs={12}>
//                 <BrochureService />
//                 <CallBackRequestService />
//               </Grid>
//             </Box>
//           ) : (
//             <></>
//           )}
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default NotificationDetails;

