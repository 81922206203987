import React, { useContext, useState } from "react";
import "./ServicesPage.scss";
import { Box, Typography, Stack, Paper, Button, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import SidebarContext from "../../Components/SidebarContext";
import Sidebar from "../../Components/Sidebar/Sidebar";

const BrochurePaper = styled(Paper)(({ theme }) => ({
  width: "80%",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "left",
  borderRadius: "15px",
  backgroundColor: "#F8FAFF",
}));

const CallBackRequestService = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const { setActive } = useContext(SidebarContext); 
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setActive("Callback");

  };

  return (
    <>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
       </Sidebar> 
      <Stack
        className="ServicesPage__CallbackRequest"
        marginBottom={{ xs: 1, sm: 1, md: 2 }}
        direction={{ xs: "column", sm: "row" }}
        spacing={{ xs: 1, sm: 1, md: 2 }}
      >
         {isMobile ? (
      <Button variant="contained" fullWidth> Call Back Request</Button>
    ) : 
    (
      <BrochurePaper square={true}>
      <Typography variant="body2" className="" textAlign="left">
      Call Back Request
      </Typography>
      <Typography variant="caption">When An Unknown Printer Took Ga Lley Offer Typey Anddey.</Typography>
      <Box paddingBlock={2}>
        <Button variant="contained" color="primary" size="medium" onClick={toggleSidebar}>
          Connect Now
        </Button>
      </Box>
    </BrochurePaper>
    )}

       
      </Stack>
    </>
  );
};

export default CallBackRequestService;
