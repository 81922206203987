import "./App.scss";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Header from "./Components/Header/Header.jsx";
import Topbar from "./Components/Header/Topbar.jsx";
import Footer from "./Components/Footer/Footer.jsx";
import Careers from "./Pages/Careers.jsx";
import Media from "./Pages/Media.jsx";
import AboutUsPage from "./Pages/AboutUsPage.jsx";
import ContactUsPage from "./Pages/ContactUsPage.jsx";
import ServicesPage from "./Pages/ServicePages/ServicesPage.jsx";
import ProductDownload from "./Pages/ProductDownload.jsx";
import Notification from "./Pages/Notification.jsx";
import KnowledgeBase from "./Pages/KnowledgeBase.jsx";
import Blog from "./Pages/Blog.jsx";
import Calendar from "./Components/Calendar.jsx";
import ServicesDetailPage from "./Pages/ServicePages/ServicesDetailpage.jsx";
import BackToTopButton from "./Components/BackToTopButton.jsx";
import NotificationDetails from "./Pages/NotificationDetails.jsx";
import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { SidebarProvider } from "./Components/SidebarContext.jsx";
import Errorp from "./Pages/Error.jsx";
import Partner from "./Components/Sidebar/Partner.jsx";
import CareersDetails from "./Pages/CareersDetails.jsx"
import "./Assets/Scss/Style.scss";
import PrivacyPolicy from "./Pages/PrivacyPolicy.jsx";
import TermsPolicy from "./Pages/TermsPolicy.jsx";
import ProductList from "./Pages/ProductList.jsx";



function App() {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>

    <SidebarProvider>
      <Box
        sx={{ flexGrow: 1, boxShadow: 3 }}
        position={isSticky && isMobile ? "fixed" : ""}      
        style={{
          top: isSticky && isMobile ? "0" : "auto",
          right: isSticky && isMobile ? "0" : "auto",
          left: isSticky && isMobile ? "0" : "auto",
          zIndex: isSticky && isMobile ? "1199" : "auto",
          backgroundColor: isSticky && isMobile ? "#C3E7FF" : "#fff",
        }}
      >
        <Topbar />
        <Header />
      </Box>

      <Routes>
        {["/home", "/"].map((path) => (
          <Route path={path} element={<Home />} />
        ))}
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<ContactUsPage />} />
        <Route path="/download" element={<ProductDownload />} />
        <Route path="/gallery" element={<Media />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/servicedetail" element={<ServicesDetailPage />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/notificationdetails" element={<NotificationDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/knowledgebase" element={<KnowledgeBase />} />
        <Route path="/calendar" element={<Calendar />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/careersdetails" element={<CareersDetails />} />
        <Route path="/privacypolicy" element={< PrivacyPolicy/>}/>
        <Route path="/termspolicy" element={< TermsPolicy/>}/>
        <Route path="/productlist" element={<ProductList/>}/>
        <Route path="*" element={<Errorp/>} />
      </Routes>
      <BackToTopButton />
      <Footer />
      </SidebarProvider>
    </>
  );
}

export default App;
