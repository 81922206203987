import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, { useContext, useState } from "react";
import careers_bg from "../Assets/Images/careers_bg.jpg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import "./Careers.scss";
import ConnectUs from "../Components/ConnectUs";
import { Stop } from "@mui/icons-material";
import SidebarContext from "../Components/SidebarContext";
import Sidebar from "../Components/Sidebar/Sidebar";

const CareersDetails = () => {
  const { setActive } = useContext(SidebarContext);
const [isSidebarOpen, setIsSidebarOpen] = useState(false);

const toggleSidebar = () => {
  setActive("JobApplication");
  setIsSidebarOpen(!isSidebarOpen);
};

  // const [selectedValue, setSelectedValue] = useState("");

  // const [age, setAge] = React.useState("");
  // const [department, setDepartment] = React.useState("");

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  // const handleChange1 = (event) => {
  //   setDepartment(event.target.value);
  // };

  return (
    <>
     <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}>
      </Sidebar>
      <Box className="AboutUsPage CareersPage">
        <Typography
          variant="h4"
          className="AboutUsPage__title CareersPage__title"
          textAlign={"center"}
          position={"relative"}
        >
          Careers Details
        </Typography>
        <img src={careers_bg} alt="background" className="careers_background" />
        <Stack position={"relative"} marginBlock={3}>
          <Grid
            container
            spacing={3}
            className="CareersPage__body"
            px={3}
            mt={3}
          >
            <Grid item xs={12} sm={6} md={8}>
              <Paper elevation={3} />
              <Typography variant="h4">Senior Web Developer </Typography>
              <Typography variant="body1">
                {" "}
                As senior Azure / .NET developer, you will join a team who is
                responsible for building and maintaining an innovative mobile
                application in a cloud environment on Azure.
              </Typography>
              <Typography variant="h6">Responsibilities:</Typography>
              <List sx={{ paddingInline: 0 }}>
                <ListItem>
                  <Stop fontSize="small" sx={{ marginRight: 2 }} />
                  <ListItemText>
                    As part of a scrum team, you’ll contribute to the continuous
                    development of the product features that are in the care of
                    your team
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <Stop fontSize="small" sx={{ marginRight: 2 }} />
                  <ListItemText>
                    Design and develop new user stories, create & review pull
                    requests, document your work,  perform research, communicate
                    clearly about your progress
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <Stop fontSize="small" sx={{ marginRight: 2 }} />
                  <ListItemText>
                    Troubleshoot issues and improve performance
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <Stop fontSize="small" sx={{ marginRight: 2 }} />
                  <ListItemText>
                    Together with your colleagues, you’ll make sure the product
                    features meets all functio nal and non-functional
                    requirement
                  </ListItemText>
                </ListItem>
              </List>

              <Typography variant="h6">Requirements :</Typography>
              <List>
                <ListItem>
                  <Stop fontSize="small" sx={{ marginRight: 2 }} />
                  <ListItemText>
                    As part of a scrum team, you’ll contribute to the continuous
                    development of the product features that are in the care of
                    your team
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <Stop fontSize="small" sx={{ marginRight: 2 }} />
                  <ListItemText>
                    Design and develop new user stories, create & review pull
                    requests, document your work,  perform research, communicate
                    clearly about your progress
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <Stop fontSize="small" sx={{ marginRight: 2 }} />
                  <ListItemText>
                    Troubleshoot issues and improve performance
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <Stop fontSize="small" sx={{ marginRight: 2 }} />
                  <ListItemText>
                    Together with your colleagues, you’ll make sure the product
                    features meets all functio nal and non-functional
                    requirement
                  </ListItemText>
                </ListItem>
              </List>
              <Typography></Typography>
              <Box>
                <Typography>Job Function : TECHNOLOGY</Typography>
                <Typography>Role : Developer</Typography>
                <Typography>Job Id : 302807</Typography>
                <Typography>Desired Skills :ASP.NET | Azure</Typography>
              </Box>
              <Grid marginTop={3}>
                <Button  variant="contained"  onClick={toggleSidebar}>Apply</Button>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ bgcolor: "lightgray" }}
              padding={3}
            >
              <Stack gap={3}>
                <Typography>Related Job Vacany</Typography>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" component="h6">
                      Senior Web Developer
                    </Typography>
                    <Typography color="textSecondary">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Consequatur ducimus exercitationem laborum enim
                      consequuntur labore, perferendis voluptates reprehenderit,
                      numquam dolorem, commodi amet sed voluptatum. Pariatur aut
                      explicabo error odit assumenda.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ borderRadius: "15px", marginTop: "15px" }}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" component="h6">
                      Senior Web Developer
                    </Typography>
                    <Typography color="textSecondary">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Consequatur ducimus exercitationem laborum enim
                      consequuntur labore, perferendis voluptates reprehenderit,
                      numquam dolorem, commodi amet sed voluptatum. Pariatur aut
                      explicabo error odit assumenda.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ borderRadius: "15px", marginTop: "15px" }}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" component="h6">
                      Senior Web Developer
                    </Typography>
                    <Typography color="textSecondary">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Consequatur ducimus exercitationem laborum enim
                      consequuntur labore, perferendis voluptates reprehenderit,
                      numquam dolorem, commodi amet sed voluptatum. Pariatur aut
                      explicabo error odit assumenda.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ borderRadius: "15px", marginTop: "15px" }}
                    >
                      Learn More
                    </Button>
                  </CardContent>
                </Card>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <ConnectUs />
    </>
  );
};

export default CareersDetails;
