// import React, { useContext } from "react";
// import { Divider, Typography, Box, Button } from "@mui/material";
// import TextField from "@mui/material/TextField";
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import FormControl from "@mui/material/FormControl";
// import FormGroup from "@mui/material/FormGroup";
// import Checkbox from "@mui/material/Checkbox";
// import SidebarContext from "../SidebarContext";


// const Login = () => {

//   const { setActive } = useContext(SidebarContext); 
//    const signupHandler = () =>{
//     setActive("Register");
//    }
//    const [value, setValue] = React.useState('otp');

//   const handleChange = (event) => {
//     setValue(event.target.value);
//   };



//   return (
//     <>
//       <Typography variant="h4" component="h4" pt={2} sx={{ color: "#0D629A" }}>
//         Welcome To Brand Liaison
//       </Typography>
//       <Typography variant="caption">Enter the user detail her</Typography>
//       <Divider
//         className="divider-component"
//         sx={{ borderBottomWidth: "medium", backgroundColor: "#1C7CBC", my: 1 }}
//       />
//       <Box
//         sx={{
//           width: 500,
//           maxWidth: "100%",
//           pt: 2,
//         }}
//       >
//         {" "}
//         <Typography variant="subtitle1" pb={1}>
//           Email-id
//         </Typography>
//         <TextField
//           fullWidth
//           label="name@company.com"
//           type="email"
//           id="fullWidth"
//         />
//         <FormControl sx={{ py: 1 }}>
//           <RadioGroup
//             row
//             aria-labelledby="demo-row-radio-buttons-group-label"
//             name="row-radio-buttons-group"
//             value={value}
//             onChange={handleChange}
//             defaultValue="otp"
//           >
//             <FormControlLabel 
//               value="otp"
//               control={<Radio />}
//               label="Via OTP"
//             />
//             <FormControlLabel
//               value="password"
//               control={<Radio />}
//               label="Via Password"
//             />
//           </RadioGroup>
//         </FormControl>
//         <Typography variant="subtitle1" pb={1}>
//           Password
//         </Typography>
//         <TextField
//           fullWidth
//           label="***********"
//           type="password"
//           autoComplete="current-password"
//           id="fullWidth"
//         />
//         <FormGroup pb={1}>
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <FormControlLabel
//               control={<Checkbox defaultChecked />}
//               label="Remember me"
//             />
//             <Typography label="Forget Password">Forget Password </Typography>
//           </Box>
//         </FormGroup>
//         <Box sx={{ pt: 3 }}>
//           <Button variant="contained" size="large" color="primary" fullWidth>
//             Sign In
//           </Button>
//           <Typography variant="subtitle2" sx={{ mt: 1 }}>
//             {" "}
//             Don't have an Account? {"\u00A0"}{" "}
//             <Button
//               className="font-bold cursor-pointer"
//               onClick={signupHandler}
//             >
//               Sign Up
//             </Button>{" "}
//           </Typography>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default Login;


//*********************** API INtegrated ******************************* */

import React, { useContext, useState } from "react";
import { Divider, Typography, Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import SidebarContext from "../SidebarContext";
import useFetchData from "../../api/UserFetchData"; // Assuming useFetchData is in this path

const Login = () => {
  const { setActive } = useContext(SidebarContext); 
  const signupHandler = () => {
    setActive("Register");
  }

  const [value, setValue] = useState('otp');
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);

  const { data, error, isLoading, fetchData } = useFetchData();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleLogin = async () => {
    const payload = {
      email,
      password: value === 'password' ? password : undefined,
      otp: value === 'otp' ? otp : undefined,
      method: value, // 'otp' or 'password'
      rememberMe,
    };

    try {
      await fetchData('/api/backend/v1/login', 'POST', payload);
      // Handle success (e.g., redirect to dashboard)
    } catch (error) {
      // Handle error (e.g., show error message)
    }
  };

  return (
    <>
      <Typography variant="h4" component="h4" pt={2} sx={{ color: "#0D629A" }}>
        Welcome To Brand Liaison
      </Typography>
      <Typography variant="caption">Enter the user detail her</Typography>
      <Divider
        className="divider-component"
        sx={{ borderBottomWidth: "medium", backgroundColor: "#1C7CBC", my: 1 }}
      />
      <Box
        sx={{
          width: 500,
          maxWidth: "100%",
          pt: 2,
        }}
      >
        <Typography variant="subtitle1" pb={1}>
          Email-id
        </Typography>
        <TextField
          fullWidth
          label="name@company.com"
          type="email"
          value={email}
          onChange={handleEmailChange}
        />
        <FormControl sx={{ py: 1 }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={value}
            onChange={handleChange}
            defaultValue="otp"
          >
            <FormControlLabel 
              value="otp"
              control={<Radio />}
              label="Via OTP"
            />
            <FormControlLabel
              value="password"
              control={<Radio />}
              label="Via Password"
            />
          </RadioGroup>
        </FormControl>
        
        {value === 'otp' ? (
          <>
            <Typography variant="subtitle1" pb={1}>
              OTP
            </Typography>
            <TextField
              fullWidth
              label="Enter OTP"
              type="text"
              value={otp}
              onChange={handleOtpChange}
            />
          </>
        ) : (
          <>
            <Typography variant="subtitle1" pb={1}>
              Password
            </Typography>
            <TextField
              fullWidth
              label="***********"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="current-password"
            />
          </>
        )}
        
        <FormGroup pb={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={<Checkbox checked={rememberMe} onChange={handleRememberMeChange} />}
              label="Remember me"
            />
            <Typography>Forget Password</Typography>
          </Box>
        </FormGroup>
        <Box sx={{ pt: 3 }}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            onClick={handleLogin}
            disabled={isLoading}
          >
            Sign In
          </Button>
          {error && (
            <Typography color="error" variant="body2" sx={{ mt: 1 }}>
              {error.message}
            </Typography>
          )}
          <Typography variant="subtitle2" sx={{ mt: 1 }}>
            Don't have an Account? {"\u00A0"}{" "}
            <Button
              className="font-bold cursor-pointer"
              onClick={signupHandler}
            >
              Sign Up
            </Button>{" "}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Login;

