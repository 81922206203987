import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React from "react";
import careers_bg from "../Assets/Images/careers_bg.jpg";
import SearchIcon from "@mui/icons-material/Search";
import "./Careers.scss";
import "./KnowledgeBase.scss";
import ConnectUs from "../Components/ConnectUs";

const myArray = [
  {
    number: 1,
    title: "BIS / CRS Registration",
    image: "bis-crs",
  },
  {
    number: 2,
    title: "BIS / CRS Registration",
    image: "bis-isi",
  },
  {
    number: 3,
    title: "BIS / CRS Registration",
    image: "bis-isi",
  },
  {
    number: 4,
    title: "BIS / CRS Registration",
    image: "bis-crs",
  },
  {
    number: 5,
    title: "BIS / CRS Registration",
    image: "bis-crs",
  },
  {
    number: 6,
    title: "BIS / CRS Registration",
    image: "bis-isi",
  },
  {
    number: 7,
    title: "BIS / CRS Registration",
    image: "bis-isi",
  },
  {
    number: 8,
    title: "BIS / CRS Registration",
    image: "bis-crs",
  },
];
const linklistdata = [
  {
    title: "Online Marketing",
    desclink1: "Search Engine Visibility",
    desclink2: "Digital Marketing Suite",
  },
  {
    title: "Productivity",
    desclink1: "Search Engine Visibility",
    desclink2: "Digital Marketing Suite",
  },
  {
    title: "Partner Programs",
    desclink1: "Search Engine Visibility",
    desclink2: "Digital Marketing Suite",
  },
  {
    title: "Websites & Security",
    desclink1: "Search Engine Visibility",
    desclink2: "Digital Marketing Suite",
  },
];

const KnowledgeBase = () => {
  return (
    <>
      <Box className="AboutUsPage CareersPage KnowledgeBase">
        <Typography
          variant="h4"
          className="AboutUsPage__title CareersPage__title"
          textAlign={"center"}
          position={"relative"}
        >
          Our <br />
          Knowledgebase
        </Typography>
        <img
          src={careers_bg}
          alt="background"
          className="careers_background KnowledgeBase__background"
        />

        <Grid
          container
          spacing={3}
          className="KnowledgeBase__container"
          px={3}
          mt={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={6} md={10}>
            <Stack>
              <TextField
                variant="outlined"
                placeholder="Search here for Approvals"
                InputProps={{
                  endAdornment: (
                    <SearchIcon sx={{ color: "action.active", mr: 1 }} />
                  ),
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Stack direction="row" px={3} mt={3}>
        <Grid
          container
          spacing={3}
          className="KnowledgeBase__container"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {myArray.map((item, index) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box key={index}>
                <img
                  src={require("../Assets/Images/" + item.image + ".webp")}
                  className="KnowledgeBase-img"
                  alt={item.title}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
                <Typography marginTop={1} textAlign={"center"} variant="body2">
                  {item.title}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Stack>
      <Divider sx={{ marginBlock: 4 }} />
      <Stack direction="row" px={3} marginBlock={1}>
        <Grid
          container
          spacing={{ sm: 1, md: 3 }}
          className="KnowledgeBase__container"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {linklistdata.map((item, index) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box key={index}>
                <Typography marginTop={1} textAlign={"center"} variant="h5">
                  {item.title}
                </Typography>
                <List>
                  <ListItem>
                    <ListItemText variant="body2" primary={item.desclink1} />
                  </ListItem>
                  <ListItem>
                    <ListItemText variant="body2" primary={item.desclink2} />
                  </ListItem>
                  <ListItem>
                    <ListItemText variant="body2" primary={item.desclink2} />
                  </ListItem>
                </List>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Stack>
      <ConnectUs />
    </>
  );
};

export default KnowledgeBase;
