// import React, { useState, useEffect } from "react";
// import { MenuItem, TextField } from "@mui/material";
// // import "./ServicesPage.scss";
// import {
//   Box,
//   Typography,
//   Stack,
//   Paper,
//   Divider,
//   useMediaQuery,
// } from "@mui/material";
// import { styled } from "@mui/material/styles";
// // import ServicesCards from "./ServicesCards";
// // import useFetchData from "../../api/UserFetchData"; // assuming you have a custom hook for fetching data

// const DemoPaper = styled(Paper)(({ theme }) => ({
//   width: "80%",
//   padding: theme.spacing(2),
//   ...theme.typography.body2,
//   textAlign: "left",
//   border: "1px solid #0D629A",
//   borderRadius: "25px",
// }));

// const ProductCategory = () => {
//   const isMobile = useMediaQuery("(max-width:767px)");
//   const [selectedOption, setSelectedOption] = useState("");
//   const [productCategoryData, setProductCategoryData] = useState(null);
// //   const { loading, fetchData } = useFetchData();

//   const handleChange = (event) => {
//     setSelectedOption(event.target.value);
//   };

//   const options = [
//     { value: "Categories1", label: "Product 1" },
//     { value: "Categories2", label: "Product 2" },
//     { value: "Categories3", label: "Product 3" },
//   ];

// //   useEffect(() => {
// //     if (selectedOption) {
// //       fetchData(`/api/frontend/v1/service/service-category/${selectedOption}`)
// //         .then((data) => {
// //           setProductCategoryData(data);
// //         })
// //         .catch((error) => {
// //           console.error("Error fetching service category data:", error);
// //         });
// //     }
// //   }, [selectedOption, fetchData]);

//   return (
//     <>
//       {isMobile ? (
//         <>
//           <TextField
//             id="outlined-select-currency"
//             select
//             label="Categories"
//             variant="outlined"
//             value={selectedOption}
//             onChange={handleChange}
//             fullWidth
//           >
//             {options.map((option) => (
//               <MenuItem key={option.value} value={option.value}>
//                 {option.label}
//               </MenuItem>
//             ))}
//           </TextField>
//           {/* {productCategoryData && <ServicesCards data={productCategoryData} />} */}
//         </>
//       ) : (
//         <>
//           <Stack
//             className="ServicesPage__category cursor-pointer"
//             marginBottom={{ xs: 1, sm: 1, md: 2 }}
//             direction={{ xs: "column", sm: "row" }}
//           >
//             <DemoPaper square={true}>
//               <Typography variant="h6" className="" textAlign="left">
//                 Product Categories
//                 {options.map((option) => (
//                   <React.Fragment key={option.value}>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         alignItems: "center",
//                         paddingBlock: "10px",
//                       }}
//                       onClick={() => setSelectedOption(option.value)}
//                     >
//                       <Typography variant="caption">{option.label}</Typography>
//                       <Typography>(02)</Typography>
//                     </Box>
//                     <Divider />
//                   </React.Fragment>
//                 ))}
//               </Typography>
//             </DemoPaper>
//           </Stack>
//           {/* {productCategoryData && <ServicesCards data={productCategoryData} />} */}
//         </>
//       )}
//     </>
//   );
// };

// export default ProductCategory;

import React, { useEffect, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import {
  Box,
  Typography,
  Stack,
  Paper,
  Divider,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useFetchData from "../api/UserFetchData";

const DemoPaper = styled(Paper)(({ theme }) => ({
  width: "80%",
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: "left",
  border: "1px solid #0D629A",
  borderRadius: "25px",
}));

const ProductCategory = () => {
  const isMobile = useMediaQuery("(max-width:767px)");
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [productList, setProductList] = useState([]);
  const {
    data: productCategoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useFetchData("/api/frontend/v1/product/product-category");
  const {
    data: productListData,
    loading: productListLoading,
    error: productListError,
    fetchData: fetchProductList,
  } = useFetchData(null, "get", null, []);

  const handleChange = (event) => {
    const categoryId = event.target.value;
    setSelectedOption(categoryId);
    fetchProductList(
      `/api/frontend/v1/service/${categoryId}/service-section/productlist`
    );
  };

  useEffect(() => {
    if (productCategoryData) {
      setOptions(
        productCategoryData.map((item) => ({
          value: item.product_category_id, // Assuming item has a 'product_category_id' field
          label: item.product_category_name, // Assuming item has a 'product_category_name' field
        }))
      );
    }
  }, [productCategoryData]);

  useEffect(() => {
    if (productListData) {
      setProductList(productListData);
    }
  }, [productListData]);

  return (
    <>
      {isMobile ? (
        <>
          <TextField
            id="outlined-select-currency"
            select
            label="Categories"
            variant="outlined"
            value={selectedOption}
            onChange={handleChange}
            fullWidth
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </>
      ) : (
        <>
          <Stack
            className="ServicesPage__category cursor-pointer"
            marginBottom={{ xs: 1, sm: 1, md: 2 }}
            direction={{ xs: "column", sm: "row" }}
          >
            <DemoPaper square={true}>
              <Typography variant="h6" textAlign="left">
                Product Categories
                {options.map((option) => (
                  <React.Fragment key={option.value}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBlock: "10px",
                      }}
                      onClick={() =>
                        handleChange({ target: { value: option.value } })
                      }
                    >
                      <Typography variant="caption">{option.label}</Typography>
                      <Typography>(02)</Typography>
                    </Box>
                    <Divider />
                  </React.Fragment>
                ))}
              </Typography>
            </DemoPaper>
          </Stack>
        </>
      )}
      {/* Display loading spinner or error message */}
      {categoryLoading && <div>Loading categories...</div>}
      {categoryError && <div>Error: {categoryError}</div>}
      {productListLoading && <div>Loading products...</div>}
      {productListError && <div>Error: {productListError}</div>}

      {/* Right pane with product list */}
      <Box>
        <Typography variant="h6">Product List</Typography>
        {productList.map((product) => (
          <Box
            key={product.id}
            sx={{ padding: "10px", borderBottom: "1px solid #ddd" }}
          >
            <Typography variant="body1">{product.product_name}</Typography>
            <Typography variant="body1">{product.product_technical_name}</Typography>
            <Typography variant="body1">{product.product_content}</Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ProductCategory;
