// SidebarContext.js
import React, { createContext, useState } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [activeSection, setActiveSection] = useState(null);

  const setActive = (section) => {
    setActiveSection(section);
  };

  return (
    <SidebarContext.Provider value={{ activeSection, setActive }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default SidebarContext;
